import React, {useRef} from "react";
import {colorBookingPaid, GLOBAL_BORDER_RADIUS} from "../../../costants";
import MainButton from "../../commons/mainButton";
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from "moment/moment";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CustomToolbar from "./myPremiumCalendarToolbar";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";
import MyNormalText from "../../commons/myNormalText";

moment.locale('it', {week: {dow: 1}})
export const localizer = momentLocalizer(moment)
export const formatsCalendar = {
    timeGutterFormat: 'H:mm',
    eventTimeRangeFormat: ({start, end}, culture, localizer) =>
        `${localizer.format(start, 'H:mm', culture)} - ${localizer.format(end, 'H:mm', culture)}`
}

const INCREASE_CALENDAR_HEIGHT_VALUE = 80

function MyPremiumCalendar({
                               zoomCalendar,
                               premiumPalette,
                               setZoomCalendar,
                               calendarEvents,
                               selectedDay,
                               handleSelectEvent,
                               handleNavigate,
                               handleOpenBookingCreator,
                               refreshMyPremiumCalendarComponent,
                               calendarHeight,
                               setCalendarHeight
                           }) {

    const fontColor = premiumPalette.isDarkMode ? 'white' : undefined
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative
    const buttonHeight = 35

    const timerRef = useRef(null)

    useMyEscapeListener(() => setZoomCalendar(false))

    const eventPropGetter = (event, start, end, isSelected) => {
        return {
            style: {backgroundColor: event.backgroundColor, borderRadius: 15}
        }
    }

    function changeCalendarHeight(increase) {
        if (!increase && calendarHeight <= 500) {
            return
        }

        const increment = increase ? INCREASE_CALENDAR_HEIGHT_VALUE : -INCREASE_CALENDAR_HEIGHT_VALUE
        setCalendarHeight(calendarHeight + increment)
        AsyncStorage.setItem('premium-calendar-height', JSON.stringify(calendarHeight))
            .then(() => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current)
                }

                timerRef.current = setTimeout(() => {
                    refreshMyPremiumCalendarComponent()
                }, 200)
            })
    }

    function getDecreaseCalendarHeight() {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{width: 10, height: 2, backgroundColor: premiumPalette.isDarkMode ? 'white' : 'black'}}/>
            </div>
        )
    }

    function getButtons() {
        return (
            <div style={pageStyle.mainContainerButtons}>
                <div>
                    <MainButton text={'Inserisci ordine'}
                                action={() => handleOpenBookingCreator()}
                                height={buttonHeight}
                                backgroundColor={premiumPalette.primaryColor}/>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
                    <div style={pageStyle.containerButtonsHeight}>
                        <MainButton injectMainBody={getDecreaseCalendarHeight()}
                                    action={() => changeCalendarHeight(false)}
                                    textBold={true}
                                    height={buttonHeight}
                                    paddingHorizontal={20}
                                    backgroundColor={premiumPalette.primaryColor}/>
                        <MainButton text={'+'}
                                    action={() => changeCalendarHeight(true)}
                                    textBold={true}
                                    height={buttonHeight}
                                    paddingHorizontal={20}
                                    backgroundColor={premiumPalette.primaryColor}/>
                    </div>
                    <div>
                        <MainButton
                            image={zoomCalendar ? require("../../../imgs/actions/dezoom.png") : require("../../../imgs/actions/zoom.png")}
                            action={() => setZoomCalendar(!zoomCalendar)}
                            height={buttonHeight}
                            backgroundColor={premiumPalette.primaryColor}/>
                    </div>
                </div>
            </div>
        )
    }

    const EventComponent = ({event}) => {
        const color = event.isPaid ? colorBookingPaid.YES : colorBookingPaid.NO
        return (
            <div style={{...pageStyle.dotIsPaidContainer, backgroundColor: event.backgroundColor}}
                 title={event.itemNames}>
                <MyNormalText text={event.title}
                              textOverflow={true}
                              color={'white'}/>
                <div style={{...pageStyle.dotIsPaid, backgroundColor: color}}/>
            </div>
        )
    }

    function getCalendar(style) {
        return <Calendar localizer={localizer}
                         formats={formatsCalendar}
                         eventPropGetter={eventPropGetter}
                         components={{
                             toolbar: (props) => <CustomToolbar {...props}
                                                                premiumPalette={premiumPalette}
                                                                buttonHeight={buttonHeight}/>,
                             event: EventComponent
                         }}
                         events={calendarEvents}
                         date={selectedDay}
                         defaultView={Views.MONTH}
                         views={[Views.MONTH, Views.DAY]}
                         style={style}
                         onSelectEvent={handleSelectEvent}
                         onNavigate={handleNavigate}/>
    }

    if (zoomCalendar) {
        return (
            <div style={{
                ...pageStyle.innerContainerFullPage,
                backgroundColor: primaryColorFirstAlternative,
                color: fontColor
            }}>
                {getButtons()}
                {getCalendar({...pageStyle.calendarStyleFullPage, height: calendarHeight})}
            </div>
        )
    }

    return (
        <div style={{color: fontColor}}>
            {getButtons()}
            {getCalendar({...pageStyle.calendarStyle, height: calendarHeight})}
        </div>
    )
}

const pageStyle = {
    innerContainerFullPage: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        padding: 20,
        zIndex: 1
    },
    mainContainerButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10
    },
    containerButtonsHeight: {
        display: 'flex',
        alignItems: 'center',
        gap: 5
    },
    calendarStyle: {
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    calendarStyleFullPage: {
        width: '100%',
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    dotIsPaidContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    dotIsPaid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 20,
        height: 20,
        borderRadius: '50%',
        border: '1px solid white'
    }
}

export default MyPremiumCalendar
