import React from "react";
import {globalPageStyle} from "../../../global/style/globalPageStyle";
import MyNormalText from "../../commons/myNormalText";

function LocemTermsOfServiceSpecific() {

    return (
        <div style={globalPageStyle.pageBackground}>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true}
                                  text={'LOCEM APP'}
                                  fontSize={30}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'CONDIZIONI PARTICOLARI DEI TERMINI D’USO PER L’UTENTE PRIVATO'}
                                  fontSize={30}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true} text={'(ARTT. 1341 E 1342 CODICE CIVILE ITALIANO)'}
                                  fontSize={30}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'L’Utente Privato dichiara di aver preso attenta visione, compreso e integralmente accettato le disposizioni\n' +
                            'previste ai seguenti punti del Termini d’Uso (' + process.env.REACT_APP_WEBSITE_URL + '/app/locem/legal-terms):'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'- PUNTO 5 (Durata, recesso, limitazione/sospensione/cessazione servizi, disattivazione account e\n' +
                            'cancellazione)'}/>
                    <br/>
                    <MyNormalText text={'- PUNTO 7 (Esonero da responsabilità)'}/>
                    <br/>
                    <MyNormalText text={'- PUNTO 8 (Manleva e indennizzo)'}/>
                    <br/>
                    <MyNormalText text={'- PUNTO 9 (Modifiche ai Termini d’Uso)'}/>
                    <br/>
                    <MyNormalText text={'- PUNTO 10 (Legge applicabile, giurisdizione e foro competente). '}/>
                </div>
            </main>
        </div>
    );
}

export default LocemTermsOfServiceSpecific;
