import React from "react";
import {globalPageStyle} from "../../../global/style/globalPageStyle";
import MyNormalText from "../../commons/myNormalText";

function LocemTermsOfService() {

    return (
        <div style={globalPageStyle.pageBackground}>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText text={'PIATTAFORMA "LOCEM"'}
                                  bold={true}
                                  fontSize={30}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'TERMINI D’USO PER L’UTENTE PRIVATO'}
                                  bold={true}
                                  fontSize={30}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'Versione in vigore a partire da 16/03/2024'}
                                  fontSize={24}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText text={'1. LOCEM SOLUTIONS. LA NOSTRA MISSIONE'}
                                  bold={true}
                                  fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'LoceM Solution s.r.l.s. (di seguito, il Fornitore) è una start-up innovativa che si occupa\n' +
                            'dello sviluppo, la produzione e la commercializzazione di prodotti e servizi innovativi\n' +
                            'ad alto valore tecnologico, con particolare riferimento alla digitalizzazione e\n' +
                            'automazione di processi di prenotazione e noleggio di beni e servizi.\n' +
                            'Nell’ambito della propria attività d’impresa il Fornitore ha ideato una piattaforma,\n' +
                            'denominata “LoceM” (di seguito la Piattaforma) per la gestione on-line di servizi\n' +
                            'relativi al noleggio di mezzi e attrezzature relativi ad attività del tempo libero, con lo\n' +
                            'scopo di valorizzare il patrimonio culturale e paesaggistico del territorio.\n' +
                            'La Piattaforma si pone quale marketplace digitale con il proposito di agevolare la\n' +
                            'stipulazione a distanza del contratto di noleggio tra le imprese che erogano il servizio di\n' +
                            'noleggio (gli Utenti Commerciali) e i soggetti che intendono prenotare i mezzi e le\n' +
                            'attrezzature offerte in noleggio (gli Utenti Privati), nonché la gestione delle attività\n' +
                            'connesse alla stipulazione e funzionali all’esecuzione del contratto di noleggio\n' +
                            'medesimo.\n' +
                            'La Piattaforma è accessibile dagli Utenti Privati mediante l’applicazione per smartphone\n' +
                            'LoceM App, compatibile con sistemi Android e IOS.\n' +
                            'Attraverso LoceM App gli Utenti Privati avranno la possibilità di visitare le vetrine\n' +
                            'digitali degli Utenti Commerciali iscritti alla Piattaforma, selezionare gli articoli di\n' +
                            'interesse, e procedere alle operazioni di trasmissione dell’ordine di noleggio e\n' +
                            'pagamento del corrispettivo, nonché alla gestione successiva dell’ordine (annullamento,\n' +
                            'rimborso, ecc.).'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText bold={true}
                                  text={'2. TERMINI D’USO E DEFINIZIONI'}
                                  fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'I presenti Termini d’Uso costituiscono un accordo giuridicamente vincolante tra Utente Privato e Fornitore (di seguito il Contratto), e regolamentano le modalità di utilizzodella Piattaforma e della LoceM App da parte dell’Utente Privato.Accettando i presenti Termini d’Uso l’Utente Privato si impegna a rispettare le seguentidisposizioni, con i conseguenti diritti, obblighi e responsabilità.'}/>
                    <MyNormalText
                        text={'Ai fini del presente Contratto le espressioni di seguito elencate, al singolare o al plurale,\n' +
                            'hanno il significato indicato a fianco di ciascuna di esse:'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Fornitore: indica LoceM Solutions società a responsabilità limitata semplificata, con sede in Via Roma n. 56, 37063 – Isola della Scala (VR) – Italia, Cod. Fiscale,partita iva e n. Iscrizione registro imprese: 04967430234, Numero R.E.A.: VR –460035.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Piattaforma: indica il marketplace digitale LoceM, ideato e sviluppato dal Fornitore\n' +
                            'per la gestione on-line di servizi relativi al noleggio di mezzi e attrezzature relativi\n' +
                            'ad attività del tempo libero, con lo scopo di valorizzare il patrimonio culturale e\n' +
                            'paesaggistico del territorio.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Utente Commerciale: indica un’impresa di noleggio di mezzi e attrezzature affiliata alla Piattaforma.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Utente Privato: indica il soggetto consumatore che intende prendere a noleggio i\n' +
                            'mezzi e le attrezzature rese disponibili dagli Utenti Commerciali all’interno della\n' +
                            'Piattaforma.'}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'● Contratto: indica i presenti Termini d’Uso.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Personal Account: indica il profilo utente dell’Utente Privato, attivato mediante l’iscrizione alla Piattaforma.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Posta: indica il sistema di messaggistica integrato di cui è dotata la Piattaforma,\n' +
                            'volto a favorire la comunicazione tra Utente Commerciale e Utente Privato, in\n' +
                            'relazione ad un determinato ordine di noleggio confermato.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Codice del Consumo: indica il Decreto Legislativo 6 settembre 2005 n. 206.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Ordine di Noleggio: indica l’ordine di noleggio inoltrato dall’Utente Privato\n' +
                            'mediante l’applicazione LoceM App. L’Ordine di Noleggio costituisce una proposta\n' +
                            'contrattuale formulata dall’Utente Privato all’Utente Commerciale.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Conferma d’Ordine: indica la conferma dell’Ordine di Noleggio da parte dell’Utente Commerciale. La Conferma equivale all’accettazione della proposta contrattuale.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Rifiuto Ordine: indica un rifiuto dell’Ordine di Noleggio da parte dell’Utente\n' +
                            'Commerciale.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Payment Gateway: indica il sistema di pagamento on-line adottato dalla Piattaforma per la gestione dei pagamenti relativi agli Ordini di Noleggio confermati.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● Sito Web: indica il sito internet di proprietà di LoceM, raggiungibile all’indirizzo\n' +
                            'https://locemsolutions.com/'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● LoceM App: indica l’applicazione, ideata e sviluppata da LoceM, per l’accesso alla Piattaforma e l’utilizzo dei relativi servizi da parte dell’Utente Privato.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'● LoceM Business App: indica l’applicazione, ideata e sviluppata da LoceM, per\n' +
                            'l’accesso alla Piattaforma e l’utilizzo dei relativi servizi da parte dell’Utente\n' +
                            'Privato.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText bold={true}
                                  text={'3. REGOLE FUNZIONAMENTO PIATTAFORMA E DELLA LOCEM APP'}
                                  fontSize={24}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.1. Download Applicazione – Iscrizione – Attivazione Personal Account'}
                                  fontSize={18}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'Per utilizzare la Piattaforma e i propri servizi l’Utente Privato deve scaricare la LoceM App dal sito web https://locemsolutions.com/ (il Sito Web) oppure direttamente dalle piattaforme Google Play (versione Android) e App Store (versione IOS). Per la fruizione di alcune funzionalità della LoceM App all’Utente Privato è richiesta l’iscrizione alla Piattaforma (pulsante sign-up), oppure, se già iscritto, l’inserimento delle proprie credenziali d’accesso (pulsante log-in).'}/>
                    <br/>
                    <MyNormalText
                        text={'Selezionando il pulsante sign-up l’Utente Privato viene indirizzato alla pagina di\n' +
                            'iscrizione, all’interno della quale viene richiesta la compilazione di tutti i campi ivi\n' +
                            'presenti e la conferma dei dati inseriti, mediante click sull’apposito pulsante.'}/>
                    <br/>
                    <MyNormalText
                        text={'Al termine della procedura di iscrizione l’Utente Privato riceverà una notifica\n' +
                            'all’indirizzo e-mail indicato nella pagina di iscrizione.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’iscrizione alla Piattaforma da parte dell’Utente Privato comporterà l’attivazione di un\n' +
                            'Personal Account, tramite il quale l’Utente Privato può fruire dei servizi digitali della\n' +
                            'Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Personal Account è accessibile dall’Utente Privato mediante inserimento delle proprie\n' +
                            'credenziali nell’apposita area di log-in all’interno della LoceM App.'}/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'L’iscrizione è ammessa per soggetti (persone fisiche) che rivestano la qualità di\n' +
                                      '“consumatori” secondo le disposizioni normative e regolamentari vigenti e che\n' +
                                      'abbiano un’età non inferiore a 18 anni.'}/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'Il Fornitore si riserva di respingere la richiesta di iscrizione da parte di Utenti Privati che non risultano in possesso dei requisiti sopra indicati, oppure in relazione ai quali sia stata in precedenza disposta una delle misure di\n' +
                                      'limitazione/sospensione/cancellazione dei servizi di cui al successivo punto 5.3.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.2. Funzionalità della LoceM App'}/>
                    <br/>
                    <MyNormalText text={'All’interno della LoceM App sono presenti le seguenti funzionalità:'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.2.1. Homepage'}/>
                    <br/>
                    <MyNormalText
                        text={'presso la Homepage sono inseriti tutti i negozi di noleggio affiliati alla Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’Utente Privato ha la possibilità di ricercare nella Homepage beni e attrezzature\n' +
                            'disponibili per il noleggio, utilizzando parametri quali la tipologia di articolo, il numero\n' +
                            'di articoli, la città, le date di interesse, ecc.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il posizionamento dei risultati di ricerca avviene in via principale sulla base della\n' +
                            'prossimità dei punti di noleggio rispetto alla posizione geografica dell’Utente Privato,\n' +
                            'tenendo altresì conto delle valutazioni rese dall’Utente Privato in relazione a precedenti\n' +
                            'ordini di noleggio.'}/>
                    <br/>
                    <MyNormalText
                        text={'È altresì possibile che il posizionamento sia influenzato dal pagamento di un corrispettivo\n' +
                            'da parte dell’Utente Commerciale (in tal caso il risultato sarà contrassegnato dalla dicitura\n' +
                            '“sponsorizzato”).'}/>
                    <br/>
                    <MyNormalText
                        text={'Tramite la Homepage l’Utente Privato ha inoltre la possibilità di effettuare ordini di noleggio degli articoli ricercati (v. punto 3.3.1), e salvare i risultati di ricerca all’interno della sezione “Preferiti” (v. punto 3.2.6).'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.2.2. Navigazione GPS'}/>
                    <br/>
                    <MyNormalText
                        text={'Si tratta di un tool di interazione con app di terze parti per la navigazione GPS verso i\n' +
                            'punti noleggio degli Utenti Commerciali.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il servizio è attualmente gestito, a seconda delle impostazioni e della tipologia di smartphone utilizzato, da Google Maps [https://www.google.com/intl /it/help/terms_maps/] e da Apple Mappe [https://www.apple.com/legal /internet-services/maps/terms-it.html]'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.2.3. Archivio Ordini'}/>
                    <br/>
                    <MyNormalText
                        text={'Questa sezione contiene la cronologia degli ordini effettuati e delle relative conferme,\n' +
                            'rifiuti e cancellazioni d’ordine (v. punto 3.3).'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.2.4. Posta'}/>
                    <br/>
                    <MyNormalText
                        text={'Si tratta di un servizio integrato di messaggistica per la comunicazione tra Utenti\n' +
                            'Commerciali e Utenti Privati in relazione ad uno specifico ordine di noleggio confermato,\n' +
                            'attivabile a discrezione dell’Utente Commerciale presso cui si è effettuato l’ordine,'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.2.5. Account'}/>
                    <br/>
                    <MyNormalText
                        text={'All’interno della sezione Account l’Utente Privato ha a disposizione un’Area Personale,\n' +
                            'tramite la quale è possibile iscriversi alla Piattaforma (sign-up), oppure, se già iscritti,\n' +
                            'inserire le credenziali d’accesso (log-in) e gestire alcune impostazioni dell’applicazione e\n' +
                            'nonché altre funzionalità (ad es., gestione dei metodi di pagamento, richiesta supporto\n' +
                            'tecnico, ecc.).'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.2.6. Ulteriori servizi e funzionalità'}/>
                    <br/>
                    <MyNormalText text={'La LoceM App è infine dotata dei seguenti servizi e funzionalità:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) Un servizio integrato per la notifica (e-mail e in-app) agli Utenti Commerciali (per\n' +
                            'conto degli Utenti Privati) o agli Utenti Privati (per conto degli Utenti Commerciali)\n' +
                            'di eventi correlati all’ordine di noleggio (trasmissione/ricezione ordine,\n' +
                            'trasmissione/ricezione accettazione ordine, ecc.);'}/>
                    <br/>
                    <MyNormalText
                        text={'b) Un sistema protetto per la gestione del pagamento del prezzo di noleggio degli\n' +
                            'articoli selezionati dall’Utente Privato (v. punto 3.4);'}/>
                    <br/>
                    <MyNormalText
                        text={'c) Una funzione di valutazione della LoceM App e dell’esperienza con l’Utente Commerciale prescelto, al termine del noleggio.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.3. Noleggio'}/>
                    <br/>
                    <MyNormalText
                        text={'Il contratto di noleggio tra Utente Commerciale e Utente Privato viene stipulato a\n' +
                            'distanza mediante scambio di proposta (Ordine di Noleggio) e accettazione (Conferma\n' +
                            'd’Ordine).'}/>
                    <br/>
                    <MyNormalText bold={true}

                                  text={'Il Fornitore non è parte del contratto di noleggio, né può ritenersi in alcun modo\n' +
                                      'vincolato agli obblighi del contratto medesimo, se non entro i limiti degli impegni\n' +
                                      'assunti con i presenti Termini d’Uso e con il Contratto sottoscritto con l’Utente\n' +
                                      'Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Fornitore non è agente dell’Utente Commerciale, né è soggetto ad alcun vincolo di\n' +
                            'subordinazione o esclusiva, nei confronti dell’Utente Commerciale, né detiene alcun\n' +
                            'potere di contrattazione per conto dell’Utente Commerciale o, più in generale, di\n' +
                            'rappresentanza, con la sola eccezione del mandato di cui al successivo punto 3.4 in tema\n' +
                            'di gestione di pagamenti e rimborsi, e nei limiti delle disposizioni medesime.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.3.1. Ordine di Noleggio'}/>
                    <br/>
                    <MyNormalText
                        text={'Ciascun Utente Privato ha la possibilità di selezionare il prodotto di proprio interesse\n' +
                            'all’interno dei risultati di ricerca della Homepage, se risulta disponibile per le date di ritiro / riconsegna prescelte dagli Utenti Privati nell’apposita sezione presente nella\n' +
                            'LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'Relativamente a ciascun risultato di ricerca sono consultabili le seguenti informazioni\n' +
                            'inserite a cura e sotto l’esclusiva responsabilità dell’Utente Commerciale presso il quale è\n' +
                            'noleggiabile l’articolo ricercato:'}/>
                    <br/>
                    <MyNormalText text={'- Indicazione della tipologia del prodotto offerto in noleggio;'}/>
                    <br/>
                    <MyNormalText text={'- Corrispettivo orario/giornaliero di ciascun prodotto;'}/>
                    <br/>
                    <MyNormalText
                        text={'- Eventuale richiesta di cauzione al momento del ritiro (in caso affermativo, si invita\n' +
                            'sin d’ora a consultare per i dettagli le condizioni generali dell’Utente Commerciale).'}/>
                    <br/>
                    <MyNormalText
                        text={'- Indicazione termini per annullamento gratuito (che in ogni caso deve assicurare un\n' +
                            'termine non inferiore a 14 giorni dalla conferma dell’ordine);'}/>
                    <br/>
                    <MyNormalText text={'- Penali per annullamento ordine oltre i termini;'}/>
                    <br/>
                    <MyNormalText
                        text={'- Ulteriori informazioni richieste ex artt. 49 d.lgs. 206/2005 (Codice del Consumo)'}/>
                    <br/>
                    <MyNormalText text={'- Condizioni generali di contratto applicate dall’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'Una volta selezionato l’articolo l’Utente Privato è indirizzato ad una pagina di riepilogo,\n' +
                            'nella quale sono indicati i dati essenziali della prenotazione prescelta, conformemente a\n' +
                            'quanto previsto all’art. 49 del Codice del Consumo, nonché il link alla pagina delle\n' +
                            'condizioni generali di contratto applicate dall’Utente Commerciale e dal Fornitore.'}/>
                    <br/>
                    <MyNormalText
                        text={'Tra i dati essenziali della prenotazione prescelta sono, in particolare, indicati:'}/>
                    <br/>
                    <MyNormalText text={'- Generalità e indirizzo dell’Utente Commerciale'}/>
                    <br/>
                    <MyNormalText text={'- tipologia del prodotto e, compreso e di eventuali accessori addizionali;'}/>
                    <br/>
                    <MyNormalText
                        text={'- prezzo complessivo, eventualmente dettagliato di costi di servizio o commissioni calcolati sul singolo Ordine di Noleggio, dei quali il Fornitore si riserva il diritto di modifica in qualsiasi momento e senza preavviso, ma sempre e comunque espressamente indicandoli ed applicandoli anticipatamente all’invio dell’Ordine di Noleggio;'}/>
                    <br/>
                    <MyNormalText text={'- data ritiro / riconsegna;'}/>
                    <br/>
                    <MyNormalText
                        text={'- eventuale richiesta di cauzione al momento del ritiro (in caso affermativo, si invita sin\n' +
                            'd’ora a consultare per i dettagli le condizioni generali dell’Utente Commerciale)'}/>
                    <br/>
                    <MyNormalText
                        text={'- politica di annullamento ordini applicata (indicazione dei termini per annullamento\n' +
                            'gratuito; penali per annullamento ordine oltre i termini – v. punto 3.3.3)'}/>
                    <br/>
                    <MyNormalText
                        text={'Completato il suddetto iter l’Utente Privato può inviare l’ordine “con obbligo di\n' +
                            'pagamento” mediante apposito pulsante all’interno della LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'Una volta effettuato l’invio dell’Ordine di Noleggio, il sistema informatico della\n' +
                            'Piattaforma procede alla verifica della sussistenza della provvista, attraverso il payment\n' +
                            'gateway di cui al successivo punto 3.4, relativamente al metodo di pagamento prescelto\n' +
                            'dall’Utente Privato.'}/>
                    <br/>
                    <MyNormalText
                        text={'In difetto di provvista, l’ordine non sarà preso in carico dal sistema e l’Utente Privato sarà\n' +
                            'immediatamente avvisato della mancata transazione mediante messaggio pop-up\n' +
                            'all’interno della LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'In caso di esito positivo del controllo di provvista, il sistema informatico della Piattaforma effettua le seguenti operazioni in via contestuale:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) invio comunicazione all’Utente Privato (a mezzo e-mail), con il riepilogo\n' +
                            'dell’ordine inviato e le condizioni generali applicate dall’Utente Commerciale;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) registrazione dell’Ordine di Noleggio nell’archivio “Ordini” della LoceM App e\n' +
                            'avviso mediante notifica in-app;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) invio avviso ricezione ordine all’Utente Commerciale (a mezzo e-mail) e avviso\n' +
                            'mediante notifica in-app.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.3.1. Ordine di Noleggio'}/>
                    <br/>
                    <MyNormalText
                        text={'Gli Ordini di Noleggio possono essere confermati dall’Utente Commerciale entro 24 ore\n' +
                            'dalla notifica dell’avviso di ricezione ordine.'}/>
                    <br/>
                    <MyNormalText
                        text={'Nell’ipotesi di ordini che prevedano l’inizio del noleggio per un periodo inferiore alle\n' +
                            'successive 24 ore, non è assicurata la conferma da parte dell’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'Con riferimento a ciascun Ordine di Noleggio ricevuto l’Utente Commerciale può confermare (Conferma d’Ordine) o rifiutare (Rifiuto Ordine) l’Ordine di Noleggio.'}/>
                    <br/>
                    <MyNormalText
                        text={'In caso di Conferma d’Ordine il sistema informatico della Piattaforma effettua le seguenti\n' +
                            'operazioni in via contestuale:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) invio comunicazione all’Utente Privato (a mezzo e-mail), con la Conferma\n' +
                            'd’Ordine;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) registrazione della Conferma d’Ordine nel repository “Ordini” della LoceM App\n' +
                            'e avviso mediante notifica in-app;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) invio comunicazione all’Utente Commerciale (a mezzo e-mail), con la Conferma\n' +
                            'd’Ordine;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) indicazione del prodotto prenotato come “non disponibile” nella Vetrina Digitale\n' +
                            'per il periodo interessato dall’ordine confermato.'}/>
                    <br/>
                    <MyNormalText text={'Nella Conferma d’Ordine sono indicate le seguenti informazioni:'}/>
                    <br/>
                    <MyNormalText text={'- avviso che l’Ordine di Noleggio è stato accettato;'}/>
                    <br/>
                    <MyNormalText text={'- generalità, indirizzo e contatti dell’Utente Commerciale'}/>
                    <br/>
                    <MyNormalText
                        text={'- tipologia del prodotto e , compreso  e di eventuali accessori addizionali;'}/>
                    <br/>
                    <MyNormalText
                        text={'- prezzo complessivo, eventualmente dettagliato di costi di servizio o commissioni calcolati sul singolo Ordine di Noleggio, dei quali il Fornitore si riserva il diritto di modifica in qualsiasi momento e senza preavviso, ma sempre e comunque espressamente indicandoli ed applicandoli anticipatamente all’invio dell’Ordine di Noleggio;'}/>
                    <br/>
                    <MyNormalText text={'- data ritiro / riconsegna;'}/>
                    <br/>
                    <MyNormalText
                        text={'- eventuale richiesta di cauzione al momento del ritiro (in caso affermativo, si invita sin\n' +
                            'd’ora a consultare per i dettagli le condizioni generali dell’Utente Commerciale);'}/>
                    <br/>
                    <MyNormalText
                        text={'- politica di annullamento ordini applicata (indicazione dei termini per annullamento\n' +
                            'gratuito; penali per annullamento ordine oltre i termini);'}/>
                    <br/>
                    <MyNormalText text={'- eventuali ulteriori informazioni di cui all’art. 49 Codice del Consumo;'}/>
                    <br/>
                    <MyNormalText text={'- condizioni generali applicate dall’Utente Commerciale'}/>
                    <br/>
                    <MyNormalText
                        text={'Una volta eseguite le operazioni di cui alle precedenti lettere a) – d), il contratto di\n' +
                            'noleggio tra Utente Commerciale e Utente Privato deve intendersi perfezionato.\n' +
                            'In caso di rifiuto dell’Ordine di Noleggio l’Utente Privato sarà avvisato mediante e-\n' +
                            'mail e notifica in-app.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’Ordine di Noleggio deve intendersi comunque privo di efficacia in caso di mancata\n' +
                            'conferma da parte dell’Utente Commerciale entro 24 ore dalla notifica della ricezione\n' +
                            'dell’Ordine di Noleggio.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.3.3. Annullamento ordine'}/>
                    <br/>
                    <MyNormalText
                        text={'Prima della Conferma d’Ordine, l’Utente Privato ha sempre la possibilità di annullare\n' +
                            'gratuitamente l’Ordine di Noleggio, selezionando l’apposito pulsante all’interno\n' +
                            'dell’archivio “Ordini” della LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'Successivamente alla Conferma d’Ordine, l’annullamento dell’Ordine di Noleggio è\n' +
                            'possibile in conformità alla politica di annullamento applicata da ciascun Utente\n' +
                            'Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'In base alla politica applicata dal singolo Utente Commerciale l’annullamento può essere\n' +
                            'gratuito, oppure prevedere il pagamento di una penale, anche in relazione alle tempistiche\n' +
                            'entro cui viene richiesto.'}/>
                    <br/>
                    <MyNormalText
                        text={'ATTENZIONE: ai sensi dell’art. 59, comma 1, lett. n) del Codice del Consumo Italiano,\n' +
                            'il diritto di recesso gratuito previsto dall’art. 52 del Codice del Consumo Italiano per i\n' +
                            'contratti stipulati a distanza dal consumatore NON è applicabile ai servizi riguardanti le\n' +
                            'attività del tempo libero, qualora il contratto preveda una data o un periodo di esecuzione\n' +
                            'specifici.'}/>
                    <br/>
                    <MyNormalText
                        text={'Considerata la tipologia di servizi offerti dalla Piattaforma, è dunque possibile che il\n' +
                            'contratto di noleggio stipulato dall’Utente Privato rientri nel regime di esenzione al diritto\n' +
                            'di recesso.'}/>
                    <br/>
                    <MyNormalText
                        text={'Ciascun Utente Commerciale applica la propria politica di annullamento degli ordini\n' +
                            'confermati, in conformità e nel pieno rispetto dei diritti riservati al consumatore dalla\n' +
                            'normativa del Codice del Consumo Italiano, anche in riferimento al diritto di recesso\n' +
                            'sopra indicato.'}/>
                    <br/>
                    <MyNormalText
                        text={'La politica di annullamento adottata da ciascun Utente Commerciale è indicata nella\n' +
                            'pagina di riepilogo dell’ordine (v. punto 3.3.1) e nelle comunicazioni di riepilogo\n' +
                            'dell’ordine (v. punto 3.3.1) e di Conferma d’Ordine (v. punto 3.3.2).'}/>
                    <br/>
                    <MyNormalText
                        text={'L’Utente Privato può procedere all’annullamento mediante l’apposito pulsante\n' +
                            'nell’archivio “Ordini” della LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'In caso di annullamento da parte dell’Utente Privato, il sistema informatico della Piattaforma effettua le seguenti operazioni in via contestuale:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) invio della conferma dell’annullamento dell’ordine all’Utente Privato (a mezzo e-\n' +
                            'mail);'}/>
                    <br/>
                    <MyNormalText
                        text={'b) registrazione dell’annullamento dell’ordine all’interno dell’archivio “Ordini”\n' +
                            'della LoceM App e avviso mediante notifica in-app;'}/>
                    <br/>
                    <MyNormalText
                        text={'L’annullamento dell’ordine comporta il rimborso del pagamento effettuato dall’Utente Privato, al netto dell’eventuale penale applicata dall’Utente Commerciale, secondo le modalità di cui al successivo punto 3.4.2 (Gestione dei Rimborsi).'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.3.4. Ritiro degli articoli noleggiati.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il ritiro degli articoli noleggiati dovrà essere effettuato a cura dell’Utente Privato presso il\n' +
                            'punto di noleggio prescelto, nel giorno e nell’ora indicati nell’Ordine di Noleggio, e\n' +
                            'previa esibizione all’Utente Commerciale della Conferma d’Ordine.'}/>
                    <br/>
                    <MyNormalText
                        text={'Eventuali modifiche dell’orario di ritiro dovranno essere concordate direttamente con\n' +
                            'l’Utente Commerciale, ai contatti indicati nella Conferma d’Ordine, prima dell’orario del\n' +
                            'ritiro indicato nell’Ordine di Noleggio.'}/>
                    <br/>
                    <MyNormalText
                        text={'In ogni caso, in assenza di comunicazioni da parte dell’Utente Privato, l’ordine dovrà\n' +
                            'ritenersi annullato se l’articolo non viene ritirato entro il tempo massimo di 60 minuti\n' +
                            'dall’orario indicato per il ritiro. In tal caso l’Utente Privato non avrà diritto al rimborso\n' +
                            'del prezzo pagato (penale pari al 100%).'}/>
                    <br/>
                    <MyNormalText
                        text={'L’articolo dovrà poi essere riconsegnato nel giorno e nell’ora indicati nell’Ordine di\n' +
                            'Noleggio e in conformità con le condizioni generali applicate dal singolo Utente\n' +
                            'Commerciale.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.4. Pagamenti e Rimborsi'}/>
                    <br/>
                    <MyNormalText
                        text={'Le operazioni di pagamento e rimborso sono interamente gestite, in forza di mandato\n' +
                            'conferito dall’Utente Commerciale al Fornitore con il Contratto, mediante apposito tool\n' +
                            'all’interno della Piattaforma, integrato con il payment gateway di terze parti a ciò abilitate\n' +
                            '(attualmente Stripe.com - https://stripe.com/it-us/legal/consumer).'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.4.1. Procedimento di pagamento'}/>
                    <br/>
                    <MyNormalText text={'Il procedimento è così articolato:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) all’interno della procedura d’ordine di noleggio l’Utente Privato deve inserire i\n' +
                            'dati relativi al metodo di pagamento prescelto;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) al termine della procedura l’Utente Privato viene indirizzato alla pagina di\n' +
                            'riepilogo di cui al precedente punto 3.3.1, con la richiesta di procedere all’invio\n' +
                            'dell’ordine “con obbligo di pagamento”;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) una volta inviato l’ordine, il sistema informatico della Piattaforma provvederà a\n' +
                            'pre-autorizzare il pagamento, per il tramite del payment gateway;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) in caso di conferma dell’ordine da parte dell’Utente Commerciale, l’importo pre-\n' +
                            'autorizzato sarà effettivamente incassato dal Fornitore, per il tramite del payment\n' +
                            'gateway;'}/>
                    <br/>
                    <MyNormalText
                        text={'e) in caso di rifiuto dell’ordine da parte dell’Utente Commerciale, oppure di\n' +
                            'mancata conferma dell’ordine entro il termine di 24 ore dalla notifica di ricezione\n' +
                            'dell’ordine, l’importo pre-autorizzato sarà automaticamente svincolato e restituito\n' +
                            'all’Utente Privato nei termini previsti gestore del payment gateway.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.4.2. Procedimento di rimborso'}/>
                    <br/>
                    <MyNormalText text={'Il procedimento di rimborso è così articolato:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) A seguito dell’annullamento di un ordine da parte dell’Utente Privato, la LoceM\n' +
                            'App rende disponibile mediante apposito tasto la richiesta di rimborso del prezzo\n' +
                            'versato (al netto dell’eventuale penale applicata in base alla politica di\n' +
                            'annullamento adottata dall’Utente Commerciale), se ne ricorrono i presupposti;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) Una volta trasmessa la richiesta di rimborso, il sistema informatico della\n' +
                            'Piattaforma invia al payment gateway l’ordine di rimborso sul metodo di\n' +
                            'pagamento prescelto dall’Utente Privato al momento dell’invio dell’Ordine di\n' +
                            'Pagamento. In tale ipotesi l’importo sarà effettivamente accreditato all’Utente\n' +
                            'Privato nei termini previsti dal gestore del payment gateway e dall’istituto\n' +
                            'bancario di appoggio dell’Utente Privato;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) Nel caso in cui il payment gateway rilevi errori nel procedimento di rimborso (ad\n' +
                            'es. scadenza della carta di credito utilizzata dall’Utente Privato per il pagamento\n' +
                            'del noleggio), l’Utente Privato riceverà una notifica (e-mail e in-app), con l’invito\n' +
                            'a contattare il supporto tecnico del Fornitore per l’aggiornamento del metodo di\n' +
                            'rimborso.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.4.3. Memorizzazione metodi di pagamento'}/>
                    <br/>
                    <MyNormalText
                        text={'All’interno della sezione Area Personale (v. punto 3.2.5.) l’Utente Privato iscritto alla\n' +
                            'Piattaforma ha la possibilità di salvare i dati relativi al metodo di pagamento solitamente\n' +
                            'utilizzato per le transazioni all’interno della LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'Quando l\'Utente salva un Metodo di pagamento, il Fornitore può memorizzare\n' +
                            'informazioni relative al Metodo di pagamento salvato (ad es. il nome e l\'indirizzo di\n' +
                            'fatturazione dell\'utente).'}/>
                    <br/>
                    <MyNormalText
                        text={'I dati suddetti non sono e non saranno soggetti a trasferimento a Utenti Commerciali o parti terze, ad eccezione di quelle informazioni strettamente necessarie al gestore del payment gateway per l’erogazione del servizio di transazione on-line.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'3.5. Sistema di messaggistica integrato (“Posta”)'}/>
                    <br/>
                    <MyNormalText
                        text={'La Piattaforma è dotata di un sistema di messaggistica integrato (Posta) per il tramite\n' +
                            'della quale l’Utente Privato ha la possibilità di interfacciarsi direttamente con l’Utente\n' +
                            'Commerciale (e viceversa) per eventuali comunicazioni inerenti agli Ordini di Noleggio\n' +
                            'confermati.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il servizio di Posta è attivabile a discrezione dell’Utente Commerciale e accessibile\n' +
                            'dall’Utente Privato da un’apposita sezione presente nella LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'In particolare il servizio di Posta è attivabile dall’Utente Commerciale successivamente\n' +
                            'alla conferma di un determinato ordine e rimarrà attivo sino alle ore 23.59 dell’ultimo\n' +
                            'giorno di noleggio. Il servizio di Posta si disattiva automaticamente a partire dalle ore\n' +
                            '0.00 del primo giorno successivo al termine del noleggio e in caso di eventuale\n' +
                            'annullamento dell’ordine.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il servizio di Posta deve essere utilizzato al solo scopo di ricevere e fornire informazioni\n' +
                            'in merito agli Ordini di Noleggio confermati.'}/>
                    <br/>
                    <MyNormalText text={'E’ pertanto vietata qualsiasi condotta volta a:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) indurre gli Utenti Privati ad annullare l’Ordine di Noleggio già confermato e/o\n' +
                            'effettuare altri ordini mediante modalità esterne alla Piattaforma, anche verso la\n' +
                            'promessa di sconti o condizioni economiche più favorevoli;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) trasmettere o chiedere dati personali ulteriori rispetto a quanto normalmente\n' +
                            'richiesto dalla Piattaforma per la gestione degli Ordini di Noleggio;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) chiedere agli Utenti Privati o promettere agli Utenti Commerciali recensioni o\n' +
                            'commenti positivi, anche mediante canali esterni alla Piattaforma, anche verso la\n' +
                            'promessa o la richiesta di sconti o altre pratiche volte a falsare il giudizio\n' +
                            'dell’utente;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) utilizzare espressioni offensive o in qualunque modo discriminatore nei confronti\n' +
                            'degli Utenti Privati'}/>
                    <br/>
                    <MyNormalText
                        text={'e) utilizzare il servizio di Posta per scopi estranei alla gestione dell’Ordine di\n' +
                            'Noleggio per il quale è stato attivato.'}/>
                    <br/>
                    <MyNormalText
                        text={'Al fine di garantire la sicurezza e il rispetto delle disposizioni normative e regolamentari vigenti, nonché l’osservanza dei Termini d’Uso della Piattaforma da parte degli Utenti Commerciali e degli Utenti Privati, il Fornitore si riserva la facoltà di effettuare controlli,\n' +
                            'diretti (su segnalazione dell’Utente o della Pubblica Autorità), ovvero a campione, sullo\n' +
                            'scambio di comunicazioni all’interno del servizio di Posta, nel pieno rispetto della\n' +
                            'normativa sui trattamento dei dati personali e degli obblighi di riservatezza\n' +
                            'contrattualmente assunti con l’Utente Commerciale.'}/>
                    <br/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText bold={true}
                                  text={'4. PROPRIETA’ INTELLETTUALE. LICENZA D’USO. TERZE PARTI'}
                                  fontSize={24}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'4.1. Proprietà intellettuale.'}/>
                    <br/>
                    <MyNormalText
                        text={'La Piattaforma, il Sito Web e il relativo dominio, nonché la LoceM Business App e la\n' +
                            'LoceM App sono di esclusiva proprietà del Fornitore, unico soggetto che può\n' +
                            'legittimamente disporne e concederne a terzi di relativi Diritti di utilizzazione economica.'}/>
                    <br/>
                    <MyNormalText
                        text={'La documentazione, le immagini, i caratteri, la grafica e il layout, il software, nonché la\n' +
                            'presentazione e l’elaborazione degli stessi, il codice sorgente, gli altri codici e format\n' +
                            'scripts di implementazione, e, più in generale, tutte le altre componenti informatiche e\n' +
                            'altri contenuti della Piattaforma, del Sito Web, della LoceM Business App e della LoceM\n' +
                            'App, sono di proprietà del Fornitore, oppure sono stati a questo concessi in utilizzo dai\n' +
                            'rispettivi legittimi proprietari.'}/>
                    <br/>
                    <MyNormalText
                        text={'I contenuti di cui al precedente paragrafo sono tutelati e protetti dalle disposizioni vigenti\n' +
                            'in materia di proprietà intellettuale e industriale.'}/>
                    <br/>
                    <MyNormalText
                        text={'Tutti i diritti in questione (compresi, senza limitazione, diritti d’autore, brevetti, know-\n' +
                            'how, marchi e segni distintivi) appartengono al Fornitore oppure sono stati a questo\n' +
                            'concessi in utilizzo da parte dei rispettivi e legittimi proprietari.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’accesso alla Piattaforma e/o la licenza d’uso della LoceM App non forniscono\n' +
                            'all’Utente Privato il diritto di cedere o sublicenziare a terzi le relative credenziali per\n' +
                            'l’utilizzo e/o la licenza d’uso, né di riprodurre, di modificare, di distribuire, di\n' +
                            'ripubblicare, in alcuna forma anche parziale i suddetti contenuti, senza l’espressa\n' +
                            'autorizzazione scritta da parte del titolare dei relativi diritti di sfruttamento, fatte salve le\n' +
                            'ipotesi tassative legittimamente riconosciute ai sensi degli artt. 64bis e ss. L.d.A..'}/>
                    <br/>
                    <MyNormalText
                        text={'La violazione di uno dei diritti di privativa spettanti al Fornitore o a questi concessi in\n' +
                            'utilizzo dai rispettivi legittimi titolari costituisce causa di responsabilità per l’Utente\n' +
                            'Privato.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'4.2. Licenza d’uso della LoceM App'}/>
                    <br/>
                    <MyNormalText
                        text={'La LoceM App è un’applicazione software di esclusiva proprietà di LoceM, compatibile con qualsiasi device Android (versioni AndroidOS 11 e successive) e IOS (versione IOS 13.7 e successive).'}/>
                    <br/>
                    <MyNormalText
                        text={'La LoceM App è fornita all’Utente Privato in licenza d’uso, non esclusiva, secondo le\n' +
                            'condizioni e per tutto il periodo di vigenza del Contratto.'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'4.3. Applicazioni e siti web di terze parti.'}/>
                    <br/>
                    <MyNormalText
                        text={'All’interno della Piattaforma potrebbero essere presenti tool di interazione con\n' +
                            'applicazioni di terze parti, oppure link a siti web gestiti da soggetti diversi dal Fornitore.'}/>
                    <br/>
                    <MyNormalText
                        text={'I presenti Termini d’uso non si applicano all’utilizzo delle applicazioni o siti web di terzi\n' +
                            'a cui si potrebbe accedere tramite la Piattaforma.'}/>
                    <br/>
                    <MyNormalText
                        text={'L\'utilizzo di qualsiasi applicazione o sito web di terze parti potrebbe essere soggetto a\n' +
                            'termini e condizioni aggiuntivi.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Fornitore non opera alcun controllo in merito alle informazioni, ai prodotti, ai servizi\n' +
                            'eventualmente offerti o alle politiche privacy e cookie adottate da tali applicazioni o siti\n' +
                            'web di terze parti.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’Utente Privato è quindi invitato a prendere autonomamente visione delle policy e dei\n' +
                            'termini d’uso delle suddette applicazioni e siti web di terze parti.'}/>
                    <br/>
                    <MyNormalText
                        text={'In ogni caso a fronte di eventuali comunicazioni da parte degli Utenti Privati sul\n' +
                            'malfunzionamento o non affidabilità di tali applicazioni e siti web di terze parti, il\n' +
                            'Fornitore potrà, a proprio insindacabile giudizio, valutare di sospenderne il collegamento.'}/>
                    <br/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText bold={true}
                                  text={'5. DURATA'}
                                  fontSize={24}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'5.1. Durata del Contratto'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Contratto tra Utente Privato e Fornitore rimane valido ed efficace sino allo\n' +
                            'scioglimento per libera decisione dell’Utente Privato ai sensi del successivo punto 5.2.\n' +
                            '(Recesso dell’Utente), oppure in caso di cessazione dei servizi da parte del Fornitore ai\n' +
                            'sensi del successivo punto 5.3, oppure per libera decisione del Fornitore ai sensi del\n' +
                            'successivo punto 5.4. (Recesso del Fornitore).'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'5.2. Recesso dell’Utente'}/>
                    <br/>
                    <MyNormalText
                        text={'L’Utente Privato può decidere in qualsiasi momento di recedere dal Contratto con il\n' +
                            'Fornitore, senza dover giustificare al Fornitore la propria scelta.'}/>
                    <br/>
                    <MyNormalText
                        text={'Per recedere dal Contratto è sufficiente richiedere la cancellazione del Personal Account\n' +
                            'nell’apposita sezione della LoceM App.'}/>
                    <br/>
                    <MyNormalText
                        text={'Gli eventuali ordini effettuati dall’Utente Privato e ancora pendenti al momento della\n' +
                            'cancellazione, saranno automaticamente cancellati e l’Utente Privato non avrà diritto al\n' +
                            'rimborso dei pagamenti effettuati in relazione a tali ordini.'}/>
                    <br/>
                    <MyNormalText
                        text={'In caso di recesso dell’Utente Privato si applica il successivo punto 5.5. (Disattivazione\n' +
                            'Account. Cancellazione)'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'5.3. Limitazione, sospensione e cessazione dei servizi'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Fornitore si riserva la facoltà di sospendere, limitare o cessare completamente l’accesso\n' +
                            'al Personal Account e/o la fornitura dei servizi della Piattaforma all’Utente Privato, per:'}/>
                    <br/>
                    <MyNormalText
                        text={'- comportamenti fraudolenti o abusivi da parte dell’Utente Privato, con riferimento\n' +
                            'alle informazioni fornite ai fini dell’iscrizione, alle modalità di utilizzo della\n' +
                            'Piattaforma e della LoceM App, ovvero dei prodotti/servizi offerti dall’Utente\n' +
                            'Commerciale;'}/>
                    <br/>
                    <MyNormalText text={'- motivi di sicurezza;'}/>
                    <br/>
                    <MyNormalText
                        text={'- osservanza di provvedimenti legislativi, regolamentari od ordini della Pubblica\n' +
                            'Autorità;'}/>
                    <br/>
                    <MyNormalText
                        text={'Con l’adozione di una delle misure di cui al precedente punto 5.1 il Fornitore si riserva la facoltà di cancellare gli eventuali ordini pendenti effettuati dall’Utente Privato e, nel caso in cui la causa dell’adozione delle misure sia dovuto a comportamenti dell’Utente Privato, quest’ultimo non avrà diritto al rimborso dei pagamenti effettuati in relazione a tali ordini. Il Fornitore provvederà a comunicare (mediante notifica e-mail) le motivazioni dell’adozione una delle misure descritte ai precedenti punti, prima dell’adozione della misura, o al più tardi entro le 24 ore successive al momento della sospensione/limitazione/cessazione, salvo che tale comunicazione: '}/>
                    <br/>
                    <MyNormalText
                        text={'- non violi disposizioni legislative o regolamentari od ordini della pubblica\n' +
                            'autorità, oppure'}/>
                    <br/>
                    <MyNormalText
                        text={'- non impedisca od ostacoli l’individuazione o la prevenzione di condotte illecite.\n' +
                            'Nel preavviso saranno indicate dal Fornitore le motivazioni della decisione\n' +
                            'adottata, con l’invito a fornire eventuali controdeduzioni (all’indirizzo PEC …)\n' +
                            'entro e non oltre il termine di 15 giorni dalla notifica del preavviso. Nei 15 giorni\n' +
                            'successivi alla ricezione delle controdeduzioni sarà notificata all’Utente\n' +
                            'Commerciale la decisione sulle controdeduzioni. In caso di rigetto delle\n' +
                            'controdeduzioni sarà adottata la misura preannunciata nel preavviso.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'Per eventuali contestazioni in relazione alle misure adottate ai sensi dei punti precedenti l’Utente Privato può contattare il servizio clienti all’indirizzo help@locemsolutions.com. In caso di completa cessazione dei servizi si applica il successivo punto 5.5. (Disattivazione Account. Cancellazione).'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'5.4. Recesso del Fornitore'}/>
                    <br/>
                    <MyNormalText
                        text={'Al di fuori delle misure di cui al precedente punto 5.3 il Fornitore può decidere in\n' +
                            'qualsiasi momento di recedere dal Contratto con l’Utente Privato, senza dover giustificare\n' +
                            'la propria scelta.'}/>
                    <br/>
                    <MyNormalText
                        text={'La decisione di recedere dal Contratto sarà comunicata (a mezzo e-mail ) con un\n' +
                            'preavviso di almeno 15 giorni. Decorso il termine di preavviso il Personal Account\n' +
                            'dell’Utente Privato sarà disattivato.'}/>
                    <br/>
                    <MyNormalText
                        text={'Gli eventuali ordini ancora pendenti, che prevedano inizio esecuzione tra la data di\n' +
                            'ricezione del preavviso di recesso e la data di disattivazione del Personal Account\n' +
                            'saranno comunque evasi dall’Utente Commerciale.'}/>
                    <br/>
                    <MyNormalText
                        text={'Gli eventuali ordini ancora pendenti, che prevedano inizio esecuzione a data successiva a\n' +
                            'quella di disattivazione del Personal Account, saranno automaticamente cancellati e i\n' +
                            'pagamenti effettuati dall’Utente Privato saranno integralmente rimborsati.'}/>
                    <br/>
                    <MyNormalText
                        text={'In caso di recesso dell’Utente Privato si applica il successivo punto 5.5. (Disattivazione\n' +
                            'Account. Cancellazione)'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'5.5. Disattivazione Account. Cancellazione'}/>
                    <br/>
                    <MyNormalText
                        text={'Per tutte le ipotesi di scioglimento del Contratto previste ai precedenti punti, il Personal\n' +
                            'Account dell’Utente Privato sarà disattivato.'}/>
                    <br/>
                    <MyNormalText
                        text={'Una volta disattivato il Personal Account, i dati e le informazioni personali saranno\n' +
                            'definitivamente eliminati, fatta eccezione per quei dati e quelle informazioni che il\n' +
                            'Fornitore è tenuto a conservare per legge.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText bold={true}
                                  text={'6. GARANZIE. MANUTENZIONE. AGGIORNAMENTO. SUPPORTO TECNICO.'}
                                  fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'6.1. Il Fornitore garantisce esclusivamente l’efficienza della LoceM App e delle relative\n' +
                            'funzionalità.'}/>
                    <br/>
                    <MyNormalText
                        text={'6.2. La garanzia di cui al precedente punto 6.1. è, peraltro, condizionata al corretto\n' +
                            'funzionamento dei devices e dei relativi software di sistema utilizzati dall’Utente\n' +
                            'Privato ed al corretto uso degli stessi.'}/>
                    <br/>
                    <MyNormalText
                        text={'6.3. Il Fornitore ha adottato e costantemente adotta tutte le misure e i presidi idonei al\n' +
                            'mantenimento in sicurezza della Piattaforma e degli Account dei propri iscritti, nei\n' +
                            'limiti dell’ordinaria diligenza.'}/>
                    <br/>
                    <MyNormalText
                        text={'6.4. Per qualsiasi segnalazione, richiesta di informazioni o supporto tecnico in relazione a comportamenti di Utenti Commerciali, modalità o problemi tecnici di funzionamento della Piattaforma, l’Utente Privato è invitato a inviare una richiesta di supporto secondo le seguenti modalità:'}/>
                    <br/>
                    <MyNormalText text={'a) tramite l’apposita sezione presente all’interno della LoceM App, ovvero'}/>
                    <br/>
                    <MyNormalText text={'b) inviando una e-mail all’indirizzo help@locemsolutions.com'}/>
                    <br/>
                    <MyNormalText
                        text={'c) contattando il Fornitore agli eventuali ulteriori recapiti da questi indicati.'}/>
                    <br/>
                    <MyNormalText
                        text={'La richiesta di supporto sarà presa in carico entro 24 ore dalla ricezione da parte del\n' +
                            'Fornitore ed evasa nel più breve tempo possibile, anche in ragione della natura e\n' +
                            'dell’urgenza dell’intervento richiesto.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText bold={true}
                                  text={'7. ESONERO RESPONSABILITA’'}
                                  fontSize={24}/>
                    <br/>
                    <MyNormalText text={'7.1. Il Fornitore declina ogni responsabilità in relazione a:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) adempimento delle obbligazioni assunte dall’Utente Commerciale nei confronti\n' +
                            'dell’Utente Privato in virtù del contratto di noleggio stipulato con quest’ultimo\n' +
                            'per il tramite della Piattaforma;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) sicurezza e conformità dei beni e delle attrezzature offerte in noleggio;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) contestazioni da parte della Pubblica Autorità in relazione alla messa a\n' +
                            'noleggio di beni e attrezzature non sicure o non conformi;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) danni o pregiudizi arrecati a Utenti Privati o a terzi dall’Utente Commerciale,\n' +
                            'e/o dai beni e dalle attrezzature da questi concessi in noleggio;'}/>
                    <br/>
                    <MyNormalText text={'e) inadempimenti contrattuali, condotte lesive o abusive, danni provocati\n' +
                        'all’Utente Commerciale o a terzi, da parte degli Utenti Privati, durante\n' +
                        'l’esecuzione del contratto di noleggio;'}/>
                    <br/>
                    <MyNormalText
                        text={'f) malfunzionamenti dei tool di interazione con applicazioni di terze parti\n' +
                            'integrate con il sistema informatico della Piattaforma (es. navigazione GPS),\n' +
                            'ovvero danni o pregiudizi subiti dall’utilizzo di tool di interazione con\n' +
                            'applicazioni di terze parti o degli eventuali link a siti web di terze parti;'}/>
                    <br/>
                    <MyNormalText
                        text={'g) malfunzionamenti del payment gateway e tempistiche di rimborso applicate\n' +
                            'dagli istituti di credito sui quali poggiano i metodi di pagamento utilizzati dagli\n' +
                            'Utenti Privati.'}/>
                    <br/>
                    <br/>
                    <MyNormalText
                        text={'7.2. Il Fornitore declina ogni responsabilità in relazione alle informazioni e alla\n' +
                            'documentazione pubblicata dall’Utente Commerciale e/o fornita dall’Utente\n' +
                            'Commerciale al Fornitore per la pubblicazione nella Vetrina Digitale e per qualsiasi\n' +
                            'ulteriore danno o pregiudizio derivante a terzi dalla pubblicazione delle suddette\n' +
                            'informazioni / documentazione.'}/>
                    <br/>
                    <MyNormalText
                        text={'7.3. Il Fornitore declina ogni responsabilità in ordine al contenuto, ai dati, alle\n' +
                            'informazioni e alle espressioni, nonché alle condotte fraudolente o scorrette in\n' +
                            'qualsiasi forma veicolate dall’Utente Commerciale verso l’Utente Privato, e\n' +
                            'viceversa, anche mediante il servizio di Posta.'}/>
                    <br/>
                    <MyNormalText
                        text={'7.4. Il Fornitore declina ogni responsabilità per qualsiasi danno o pregiudizio derivante\n' +
                            'dall’utilizzo della LoceM App, non imputabile a dolo o colpa grave del Fornitore\n' +
                            'medesimo, a titolo esemplificativo e non esaustivo:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) incompatibilità della LoceM App con i devices utilizzati dall’Utente Privato;'}/>
                    <br/>
                    <MyNormalText
                        text={'b) danni o malfunzionamenti ai devices utilizzati dall’Utente Privato per l’accesso\n' +
                            'alla Piattaforma;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) problemi tecnici o malfunzionamenti in genere, imprevisti o non prevedibili\n' +
                            'con l’ordinaria diligenza, della Piattaforma;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) attacchi subiti dall’Utente durante l’utilizzo della LoceM App (a titolo\n' +
                            'esemplificativo virus, malware, attacchi hacker, ecc.).'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText bold={true}
                                  text={'8. MANLEVA E INDENNIZZO'}
                                  fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'8.1. Nella misura massima consentita dalla legge applicabile, l’Utente Privato accetta di\n' +
                            'liberare, manlevare e tenere indenne il Fornitore da qualsiasi rivendicazione,\n' +
                            'responsabilità, danno, perdite e spese, derivanti da o in qualsiasi modo correlati a:'}/>
                    <br/>
                    <MyNormalText text={'- violazioni dei presenti Termini d’Uso;'}/>
                    <br/>
                    <MyNormalText
                        text={'- utilizzo improprio della Piattaforma, anche con riferimento ai diritti di privativa di cui\n' +
                            'al precedente punto 4;'}/>
                    <br/>
                    <MyNormalText text={'- inadempimenti contrattuali nei confronti dell’Utente Commerciale;'}/>
                    <br/>
                    <MyNormalText
                        text={'- danni o pregiudizi derivanti all’Utente Commerciale o ai beni da questi offerti in\n' +
                            'noleggio, oppure, per effetto di comportamenti dell’Utente Privato;'}/>
                    <br/>
                    <MyNormalText
                        text={'- informazioni false, mendaci fornite all’Utente Commerciale per il tramite della\n' +
                            'Piattaforma, oppure comportamenti fraudolenti o scorretti nei confronti dell’Utente\n' +
                            'Commerciale;'}/>
                    <br/>
                    <MyNormalText
                        text={'- espressioni denigratorie, discriminatorie o offensive nei confronti dell’Utente\n' +
                            'Commerciale, anche per il tramite del servizio di Posta e/o all’interno delle\n' +
                            'recensioni.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText bold={true}
                                  text={'9. MODIFICHE AI TERMINI D’USO'}
                                  fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'9.1. In caso di modifica dei termini di cui al presente Contratto, i Termini modificati saranno\n' +
                            'pubblicati sulla Piattaforma e sarà indicata la data di ultimo aggiornamento indicata\n' +
                            'all’inizio del documento (“versione in vigore dal …”).'}/>
                    <br/>
                    <MyNormalText
                        text={'9.2. In caso di modifiche che incidano sui diritti e i doveri degli Utenti Privati, verrà richiesto\n' +
                            'di accettare esplicitamente i Termini modificati. La mancata accettazione equivarrà al\n' +
                            'recesso dell’Utente Privato ai sensi del precedente punto 5, con la conseguente\n' +
                            'disattivazione del Personal Account. L’Utente Privato sarà informato delle modifiche\n' +
                            'tramite e-mail e notifica in-app, con un preavviso di almeno 30 giorni prima della data di\n' +
                            'entrata in vigore delle stesse.'}/>
                    <br/>
                    <MyNormalText
                        text={'9.3. In caso di modifiche che non incidono su diritti/doveri dell’Utente Privato, oppure siano\n' +
                            'imposte in adempimento di disposizioni di legge, regolamenti, ovvero ordini di pubblica\n' +
                            'autorità, l’Utente Privato sarà informato solo mediante notifica in-app. In tal caso, l’utilizzo continuato della Piattaforma da parte dell’Utente Privato dopo la data di entrata\n' +
                            'in vigore delle modifiche proposte costituirà accettazione dei Termini modificati.'}/>
                </div>
                <div style={globalPageStyle.mainSectionLegal}>
                    <MyNormalText bold={true}
                                  text={'10. LEGGE APPLICABILE. GIURISDIZIONE E FORO COMPETENTE'}
                                  fontSize={24}/>
                    <br/>
                    <MyNormalText
                        text={'10.1. Entro i limiti consentiti dalla normativa a tutela del consumatore del Paese di residenza\n' +
                            'di ciascun Utente Privato, i presenti Termini d’Uso sono governati dalla Legge e dalla\n' +
                            'Giurisdizione Italiana e devolute alla competenza esclusiva del Tribunale di Verona.'}/>
                    <br/>
                    <MyNormalText
                        text={'10.2. Se l’Utente Privato riveste la qualità di consumatore residente in Unione Europea, può\n' +
                            'accedere alla piattaforma per la risoluzione online delle controversie della Commissione\n' +
                            'europea dalla pagina: https://ec.europa.eu/consumers/odr. Il Fornitore non si impegna\n' +
                            'né è tenuto a utilizzare un organismo di risoluzione alternativa delle controversie al fine\n' +
                            'di risolvere le controversie con i consumatori.'}/>
                </div>
            </main>
        </div>
    );
}

export default LocemTermsOfService;
