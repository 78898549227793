export const PRIMARY_COLOR = '#82E0AA'
export const SECONDARY_COLOR = '#82affd'
export const PRIMARY_COLOR_FIRST_ALTERNATIVE = '#9df5c0'
export const PRIMARY_COLOR_SECOND_ALTERNATIVE = '#b4f5cc'
export const PRIMARY_COLOR_THIRD_ALTERNATIVE = '#dcf5e3'

export const PRIMARY_COLOR_SECOND_PALETTE = '#A3B18A'
export const SECONDARY_COLOR_SECOND_PALETTE = '#78a8a8'
export const PRIMARY_COLOR_FIRST_ALTERNATIVE_SECOND_PALETTE = '#c2d2ac'
export const PRIMARY_COLOR_SECOND_ALTERNATIVE_SECOND_PALETTE = '#dfeeca'
export const PRIMARY_COLOR_THIRD_ALTERNATIVE_SECOND_PALETTE = '#f7ffe9'

export const PRIMARY_COLOR_THIRD_PALETTE = '#15202B'
export const SECONDARY_COLOR_THIRD_PALETTE = '#77c0c0'
export const PRIMARY_COLOR_FIRST_ALTERNATIVE_THIRD_PALETTE = '#1d2c3a'
export const PRIMARY_COLOR_SECOND_ALTERNATIVE_THIRD_PALETTE = '#2e3d49'
export const PRIMARY_COLOR_THIRD_ALTERNATIVE_THIRD_PALETTE = '#8c98a4'

export const HIGHLIGHT_COLOR = '#FF8936'
export const ARCHIVED_COLOR = '#ffb691'
export const SELLABLE_COLOR = '#ae6eec'
export const DISABLED = 'lightgray'
export const MAX_DIV_WIDTH_HOME = 1100
export const GLOBAL_SHADOW = '0px 2px 5px rgba(0, 0, 0, 0.1)'
export const GLOBAL_SHADOW_MEDIUM_ACCENTUATED = '4px 4px 4px 4px rgba(0, 0, 0, 0.6)'
export const GLOBAL_SHADOW_SUPER_ACCENTUATED = '8px 8px 8px 8px rgba(0, 0, 0, 0.6)'
export const GLOBAL_BORDER_RADIUS = 5
export const GLOBAL_BORDER_RADIUS_ACCENTUATED = 25
export const GLOBAL_BACKGROUND_TRANSPARENT = 'rgba(0,0,0,0.1)'
export const GLOBAL_BACKGROUND_POPUP_ABSOLUTE = 'rgba(0,0,0,0.7)'

export const HOME_TRANSPARENT_BACKGROUND = 'rgba(255,255,255,0.6)'
export const HOME_SIMULATED_INFASTRUCTUR_SHADOW = '0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3), inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35)'

export const SIZES = {"KIDS": 0, "XS": 1, "S": 2, "M": 3, "L": 4, "XL": 5}

export const backgroundPalette = {
    firstPalette: {
        primaryColor: PRIMARY_COLOR,
        secondaryColor: SECONDARY_COLOR,
        primaryColorFirstAlternative: PRIMARY_COLOR_FIRST_ALTERNATIVE,
        primaryColorSecondAlternative: PRIMARY_COLOR_SECOND_ALTERNATIVE,
        primaryColorFourthAlternative: PRIMARY_COLOR_THIRD_ALTERNATIVE,
        isDarkMode: false
    },
    secondPalette: {
        primaryColor: PRIMARY_COLOR_SECOND_PALETTE,
        secondaryColor: SECONDARY_COLOR_SECOND_PALETTE,
        primaryColorFirstAlternative: PRIMARY_COLOR_FIRST_ALTERNATIVE_SECOND_PALETTE,
        primaryColorSecondAlternative: PRIMARY_COLOR_SECOND_ALTERNATIVE_SECOND_PALETTE,
        primaryColorFourthAlternative: PRIMARY_COLOR_THIRD_ALTERNATIVE_SECOND_PALETTE,
        isDarkMode: false
    },
    thirdPalette: {
        primaryColor: PRIMARY_COLOR_THIRD_PALETTE,
        secondaryColor: SECONDARY_COLOR_THIRD_PALETTE,
        primaryColorFirstAlternative: PRIMARY_COLOR_FIRST_ALTERNATIVE_THIRD_PALETTE,
        primaryColorSecondAlternative: PRIMARY_COLOR_SECOND_ALTERNATIVE_THIRD_PALETTE,
        primaryColorFourthAlternative: PRIMARY_COLOR_THIRD_ALTERNATIVE_THIRD_PALETTE,
        isDarkMode: true
    }
}

export const MINIMUM_WIDTH_SUPPORTED = 1200

export const dashboardSectionNames = {
    NEW_BOOKINGS: 'Ordini in arrivo',
    HISTORY: 'Storico',
    SEARCH: 'Cerca ordine',
    MY_ITEMS: 'I miei articoli',
    PROFILE: 'Profilo',
    PREMIUM: 'Premium',
    SUPER_USER_ADMIN: 'Super User Admin'
}

export const websitePages = {
    HOME: '/',
    LOGIN: '/login',
    VALUES: '/values',
    CONTACT_US: '/contact-us',
    DASHBOARD: '/locem-business/dashboard'
}

export const dashboardSectionLinks = {
    NEW_BOOKINGS: 'new-bookings',
    HISTORY: 'history',
    MY_ITEMS: 'my-items',
    PROFILE: 'profile',
    PREMIUM: 'premium',
    SUPER_USER_ADMIN: 'super-user-admin'
}

export const dashboardPremiumLinks = {
    PREMIUM_CALENDAR: 'calendar',
    CREATE_NEW_BOOKING: 'create-new-booking',
    PREMIUM_DATA: 'statistics'
}

export const dashboardHistoryLinks = {
    ARCHIVE: 'archive',
    SEARCH: 'search'
}

export const dashboardMyItemsLinks = {
    VETRINA: 'list',
    ARCHIVE: 'archived'
}

export const dashboardSuperUserAdminLinks = {
    CREATE_COMMUNICATION: 'create-communication',
    IMPERSONIFICA: 'rents',
    CREATE_RENT: 'create-rent',
    CREATE_ITEMS: 'create-items'
}

export const orderStatus = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    REFUNDED: 'REFUNDED',
    CANCELED_FROM_SYSTEM: 'CANCELED FROM SYSTEM'
}

export const colorStatus = {
    PENDING: '#d5b941',
    ACCEPTED: '#98e098',
    REJECTED: '#fc8657',
    REFUNDED: '#94a5bd',
    CANCELED_FROM_SYSTEM: '#a47070'
}

export const colorStatusOverlay = {
    PENDING: '#e5c95f',
    ACCEPTED: '#bceebc',
    REJECTED: '#ffa17e',
    REFUNDED: '#aeb9cb',
    CANCELED_FROM_SYSTEM: '#b99494'
}

export const colorBookingPaid = {
    NO: '#ff4545',
    YES: '#7cff6c'
}

export const singleRentType = {
    FIRST_HALF_MORNING: 'FIRST_HALF_MORNING',
    SECOND_HALF_MORNING: 'SECOND_HALF_MORNING',
    FIRST_HALF_EVENING: 'FIRST_HALF_EVENING',
    SECOND_HALF_EVENING: 'SECOND_HALF_EVENING',
    HALF_DAY_MORNING: 'HALF_DAY_MORNING',
    HALF_DAY_EVENING: 'HALF_DAY_EVENING',
    FULL_DAY: 'FULL_DAY'
}

export const operationOnRentItemType = {
    BLOCKED: 'BLOCKED',
    REPLACEMENT: 'REPLACEMENT',
    ARCHIVE: 'ARCHIVE',
    UPDATE_GENERIC_INFO: 'UPDATE_GENERIC_INFO',
    UPDATE_SELLABLE_INFOS: 'UPDATE_SELLABLE_INFOS'
}

export const bookingType = {
    SYSTEM_AUTOMATIC: 'SYSTEM AUTOMATIC',
    RENT_SELF: 'RENT SELF'
}

export const subscriptionType = {
    NORMAL: 'NORMAL',
    PREMIUM: 'PREMIUM',
    SUPER_USER_ADMIN: 'SUPER USER ADMIN'
}

export const premiumDataType = {
    MANUAL_ACCEPTED_BOOKING: 'MANUAL_ACCEPTED_BOOKING',
    APP_LOCEM_ACCEPTED_BOOKING: 'APP_LOCEM_ACCEPTED_BOOKING',
    MANUAL_INSERT_COST: 'MANUAL_INSERT_COST'
}

export const communicationMessagesLevel = {
    INFO: 'INFO',
    WARNING: 'WARNING',
    ALERT: 'ALERT'
}

export const communicationMessagesLevelColors = {
    INFO: '#81cfff',
    WARNING: '#ffcc00',
    ALERT: '#ff4800'
}

export const EURO_VALUE = '€'
