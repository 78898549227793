import {useNavigate} from 'react-router-dom';
import React, {useState} from "react";
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from "../../../firebase";
import MainButton from "../../commons/mainButton";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import {dashboardSectionLinks, SECONDARY_COLOR, websitePages} from "../../../costants";
import MyNormalText from "../../commons/myNormalText";
import Loader from "../../commons/loader";

function Login({languageState, loadingUser}) {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const onLogin = (e) => {
        e.preventDefault()
        setLoading(true)
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setLoading(false)
                navigate(websitePages.DASHBOARD + '/' + dashboardSectionLinks.NEW_BOOKINGS)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    return (
        <div style={pageStyle.container}>
            {loadingUser || loading ?
                <Loader absoluteFullPage={true}/> :
                <></>}
            <div style={pageStyle.section}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <MyNormalText bold={true}
                                  textWithTranslation={'login.page.are.you.our.partner'}
                                  language={languageState.language}/>
                    <MyNormalText textWithTranslation={'login.page.are.you.our.partner.sentence'}
                                  language={languageState.language}/>
                </div>
                <input id={'email-address'}
                       style={globalElementInputStyle.customInput}
                       name={'email'}
                       type={'email'}
                       required
                       placeholder={'Email'}
                       onChange={(e) => setEmail(e.target.value)}/>
                <input id={'password'}
                       style={globalElementInputStyle.customInput}
                       name={'password'}
                       type={'password'}
                       required
                       placeholder={'Password'}
                       onChange={(e) => setPassword(e.target.value)}/>
                <MainButton text={'Login'}
                            action={onLogin}
                            backgroundColor={SECONDARY_COLOR}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                <div style={pageStyle.line}/>
                <MyNormalText textWithTranslation={'otherwise'}
                              language={languageState.language}/>
                <div style={pageStyle.line}/>
            </div>
            <div style={pageStyle.section}>
                <MyNormalText textWithTranslation={'login.page.contact.us'}
                              language={languageState.language}/>
                <MainButton text={'contact.us'}
                            action={() => navigate(websitePages.CONTACT_US)}
                            secondaryImage={require('../../../imgs/actions/arrow-right.png')}
                            secondaryImageHeight={20}
                            secondaryImageWidth={20}
                            backgroundColor={'white'}
                            borderRadius={50}
                            isTranslated={true}
                            language={languageState.language}/>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100vh',
        margin: 20,
        gap: 30
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        gap: 10,
        width: '100%',
        maxWidth: 300
    },
    line: {
        height: 2,
        width: 50,
        backgroundColor: 'black'
    }
}

export default Login
