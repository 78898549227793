import React from "react";
import {globalPageStyle} from "../../../global/style/globalPageStyle";
import MyNormalText from "../../commons/myNormalText";

function LocemWebsitePrivacyPolicy() {

    return (
        <div style={globalPageStyle.pageBackground}>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Privacy Policy'}
                                  fontSize={32}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'INFORMATIVA PRIVACY'}/>
                    <br/>
                    <MyNormalText bold={true} text={'AI SENSI DEGLI ARTT. 13-14 REG. UE 2016/679 (GDPR)'}/>
                    <br/>
                    <MyNormalText bold={true} text={'Mod. Informativa Clienti'}
                                  fontSize={14}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText
                        text={'Gentile Interessato, con la presente scheda LOCEM SOLUTIONS S.R.L.S. rende l’informativa circa il trattamento dei dati  personali acquisiti, anche verbalmente, direttamente o tramite terzi, a Lei relativi, necessari all’espletamento  delle prestazioni amministrative, contabili, gestionali e contrattuali connesse o derivanti dall’esecuzione del  contratto. La presente informativa è resa ai sensi di quanto previsto dagli artt. 13-14 del Reg. UE 2016/679  (cd. GDPR).'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'1. IDENTITÀ E DATI DI CONTATTO DEL TITOLARE.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Titolare del trattamento dei dati (di seguito anche “Titolare”) è la società LOCEM SOLUTIONS S.R.L.S. con  sede legale in Via Roma, 56 – 37063 Isola della Scala (VR), C.F. e P. IVA n. 04967430234, R.E.A.  VR-460035, cap. soc. i.v. € 3.200,00, PEC locemsolutions@pro-pec.it.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'2. FINALITÀ DEL TRATTAMENTO DEI DATI.'}/>
                    <br/>
                    <MyNormalText text={'I Suoi dati personali verranno trattati per le seguenti finalità:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) stipulazione ed esecuzione del contratto e di tutte le attività ad esso connesse, quali, a titolo  esemplificativo, fatturazione, tutela del credito, servizi amministrativi, gestionali, organizzativi e  funzionali al rapporto contrattuale (incluso l’assolvimento di obblighi pre e/o post contrattuali); '}/>
                    <br/>
                    <MyNormalText
                        text={'b) adempimento degli obblighi previsti da leggi, regolamenti, normative nazionali e/o comunitarie in  materia contrattuale, contabile e fiscale e per conseguire un’efficace gestione dei rapporti commerciali  e altre disposizioni impartite da autorità investite dalla legge e da organi di vigilanza e controllo;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) uso statistico su dati aggregati o in forma anonima, previo consenso dell’interessato. A tal fine, la Piattaforma Le richiederà specifico consenso.'}/>
                    <br/>
                    <MyNormalText
                        text={'Si segnala che LoceM potrà esaminare, scansionare o analizzare le comunicazioni scambiate sul Servizio di Posta, in particolare per finalità di prevenzione delle frodi, indagini di sicurezza, valutazione del rischio, adempimenti imposti dalla normativa vigente, l\'applicazione dei Termini d’Uso del Servizio e assistenza alla clientela. Ad esempio, nell\'ambito delle attività di prevenzione delle frodi, LoceM potrà scansionare e analizzare i messaggi per nascondere informazioni di contatto e riferimenti ad altri siti, e, nel rispetto della legge applicabile, scansionare e analizzare tutte le immagini caricate dagli utenti sul Servizio di Posta (ad esempio, prove di sfruttamento dei minori) allo scopo di identificare e segnalare le violazioni dei contenuti alle autorità competenti. In alcuni casi, LoceM potrà anche scansionare, rivedere o analizzare messaggi per effettuare il debug, verificare e migliorare il funzionamento della Piattaforma. LoceM utilizzerà metodi automatizzati quando ragionevolmente possibile. Tuttavia, occasionalmente potrà dover controllare manualmente alcune comunicazioni, tra cui, ad esempio, per indagini antifrode e per l\'assistenza clienti. LoceM non esaminerà, osserverà o analizzerà le Sue comunicazioni tramite messaggistica per inviarLe messaggi di marketing di terze parti e non venderà recensioni o analisi di tali comunicazioni.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'3. BASE GIURIDICA DEL TRATTAMENTO.'}/>
                    <br/>
                    <MyNormalText
                        text={'Posto che il trattamento dei dati è necessario per l’esecuzione di un contratto di cui l’interessato è parte o  all’esecuzione di misure precontrattuali adottate su richiesta dello stesso, la base giuridica del trattamento è  quella prevista all’art. 6 co. 1 lett. B) GDPR e dunque l’adempimento di un contratto, nonché l’art. 6, co. 1  lett C) GDPR per quanto riguarda l’adempimento degli obblighi legali inerenti o derivanti dal contratto.  L’utilizzo dei dati per uso statistico trova la propria base giuridica nell’art. 6, co. 1 lett A) GDPR, ovvero l\'interessato ha espresso il consenso al trattamento dei propri dati personali per quella specifica finalità.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'4. MODALITÀ DEL TRATTAMENTO DEI DATI.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il trattamento avverrà con strumenti elettronici, informatici o automatizzati. Il trattamento è svolto dal Titolare e dai collaboratori e/o dipendenti del Titolare in qualità di soggetti autorizzati al trattamento, nonché dai responsabili del trattamento specificamente individuati per iscritto,  nell’ambito delle rispettive funzioni ed in conformità alle istruzioni impartite dal Titolare, assicurando  l’impiego di misure idonee per la sicurezza dei dati trattati e garantendone la riservatezza. Secondo le norme del Regolamento, i trattamenti effettuati dal titolare saranno improntati ai principi di  liceità, correttezza, trasparenza, limitazione delle finalità e della conservazione, minimizzazione dei dati,  esattezza, integrità e riservatezza.  \n' +
                            'I dati saranno sempre trattati nel massimo rispetto del principio della riservatezza anche nel caso di gestione  degli stessi da parte di terzi soggetti espressamente incaricati dal Titolare.  \n' +
                            'I Suoi dati non sono soggetti ad alcun processo decisionale automatizzato.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'5. PERIODO DI CONSERVAZIONE.'}/>
                    <br/>
                    <MyNormalText
                        text={'I Suoi dati personali verranno conservati durante l’esecuzione del contratto e per un periodo di dieci anni  successivi alla cessazione/completamento dello stesso al fine di adempiere agli obblighi di natura fiscale e  contabile, nonché per la tutela giudiziale nel caso di controversie scaturenti dal contratto stesso. I dati raccolti  per obblighi legali verranno conservati fino all’adempimento degli stessi e comunque secondo i periodi/criteri  di conservazione previsti dagli obblighi di legge a cui il Titolare è soggetto. È fatta salva l’ulteriore  conservazione qualora sia necessario per la difesa dei diritti del Titolare. La presente informativa sarà considerata valida anche per i successivi contratti che Voi doveste concludere  con il Titolare.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'6. DESTINATARI DEI DATI PERSONALI.'}/>
                    <br/>
                    <MyNormalText
                        text={'I dati personali da lei forniti possono venire a conoscenza del Titolare, degli incaricati e/o dei responsabili del  trattamento. La lista dei Responsabili del trattamento, ove nominati, è disponibile a richiesta. Eventuali ulteriori categorie di destinatari che potrebbero venire a conoscenza dei suoi dati personali durante o successivamente all’esecuzione del contratto sono:'}/>
                    <br/>
                    <MyNormalText
                        text={'a) i soggetti che elaborano i dati in esecuzione di specifici obblighi di legge;'}/>
                    <br/>
                    <MyNormalText text={'b) le società di assistenza software e hardware;'}/>
                    <br/>
                    <MyNormalText
                        text={'c) società o professionisti per la tutela giudiziale o stragiudiziale dei diritti del Titolare;'}/>
                    <br/>
                    <MyNormalText
                        text={'d) i consulenti esterni che eroghino prestazioni funzionali alle finalità sopra indicate, individuati per iscritto  ed ai quali sono state date specifiche istruzioni scritte con riferimento al trattamento dei dati personali;'}/>
                    <br/>
                    <MyNormalText
                        text={'e) in genere, tutti quei soggetti pubblici e privati cui la comunicazione sia necessaria per il corretto e  completo adempimento delle finalità sopra indicate.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'7. DIFFUSIONE DEI DATI.'}/>
                    <br/>
                    <MyNormalText
                        text={'Salvo sua specifica richiesta scritta, o preciso ordine dell’A.G./obbligo normativo, i dati personali da lei  conferiti non sono soggetti a diffusione.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'8. TRASFERIMENTO DEI DATI ALL’ESTERO.'}/>
                    <br/>
                    <MyNormalText
                        text={'I dati raccolti non verranno trasferiti verso Paesi terzi o organizzazioni internazionali. Alcuni Dati Personali dei soggetti interessati sono condivisi con Destinatari che si potrebbero trovare al di  fuori dello Spazio Economico Europeo. Il Titolare assicura che il trasferimento ed il trattamento avvengano  nel rispetto della normativa applicabile. Invero, i trasferimenti vengono effettuati tramite adeguate garanzie,  quali decisioni di adeguatezza, clausole contrattuali tipo approvate dalla Commissione Europea o altri  strumenti legali.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'9. CONFERIMENTO DEI DATI.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il conferimento dei dati personali non è obbligatorio, tuttavia è necessario per l’esatta esecuzione degli  obblighi contrattuali e precontrattuali. Il Titolare precisa che le verranno chiesti soltanto i dati strettamente  necessari per la conclusione del contratto e per l’esecuzione delle obbligazioni o degli obblighi di legge da  esso derivanti. '}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'10. RIFIUTO DI CONFERIMENTO DEI DATI.'}/>
                    <br/>
                    <MyNormalText
                        text={'La mancata indicazione dei dati da parte dell’interessato comporta l’impossibilità di stipulare il  contratto nonché di eseguire le misure precontrattuali da Lei richieste, e di portare a termine in maniera  esatta l’adempimento delle obbligazioni contrattuali, così come gli adempimenti (anche legali) derivanti o  connessi al contratto e, più in generale, l’impossibilità di adempiere alle attività sopra indicate.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'11. DIRITTI DELL’INTERESSATO.'}/>
                    <br/>
                    <MyNormalText
                        text={'La normativa conferisce all’Interessato l’esercizio di specifici diritti elencati agli artt. da 15 a 22 del GDPR, tra  cui quello di ottenere dal Titolare la conferma, o meno, dell’esistenza di propri dati personali (ovvero  l’accesso), la loro messa a disposizione in forma intelligibile, nonché la rettifica, o la cancellazione degli stessi,  o di limitarne in tutto o in parte il trattamento o opporsi per motivi legittimi al medesimo e/o revocare il  consenso al trattamento in qualsiasi momento (fermo restando le conseguenze indicate), o di chiedere la  portabilità dei propri dati per quanto riguarda i dati oggetto di specifico consenso, o ancora l’aggiornamento.  L’interessato ha diritto di avere conoscenza dell’origine dei dati, della finalità e delle modalità del  trattamento, della logica applicata al trattamento, degli estremi identificativi del titolare e dei soggetti cui i  dati possono essere comunicati. L’Interessato ha altresì il diritto di chiedere la trasformazione in forma anonima, la limitazione o il blocco dei  dati trattati in violazione della legge; può altresì proporre reclamo per quanto concerne il trattamento non  autorizzato dei dati conferiti al Garante per la Protezione dei Dati Personali con le modalità pubblicate sul  sito di detta autorità (http://www.garanteprivacy.it/). Le richieste relative all’esercizio dei predetti diritti possono essere indirizzate al Titolare del trattamento, ai  recapiti sopra indicati, senza formalità o, in alternativa, utilizzando il modello previsto dal Garante per la  Protezione dei Dati Personali reperibile al Sito internet:  http://www.garanteprivacy.it/web /guest/home/docweb/-/ docweb-display/docweb/1089924. L’esercizio dei premessi diritti può essere esercitato mediante comunicazione scritta da inviare a mezzo PEC  o mediante lettera raccomandata a/r indirizzata alla struttura sopra citata.'}/>
                </div>
            </main>
        </div>
    );
}

export default LocemWebsitePrivacyPolicy;
