import Decimal from "decimal.js";
import {toMomentDate} from "./dates";

function calculateTotalFromOrder(item, rentedDays) {

    function calculateDifferenceInDays(firstDay, secondDay) {
        return (firstDay && secondDay) ? secondDay.diff(firstDay, 'days') + 1 : (firstDay ? 1 : 0)
    }

    function calculatePriceForItem(item, days) {
        const pricesLength = item.prices.length
        let totalCost = 0
        for (let i = 0; i < days; i++) {
            const priceIndex = Math.min(i, pricesLength - 1)
            totalCost += item.prices[priceIndex].cost
        }

        return totalCost
    }

    let total = 0
    if (rentedDays.to) {
        const daysDifference = calculateDifferenceInDays(toMomentDate(rentedDays.from), toMomentDate(rentedDays.to))
        const itemTotal = calculatePriceForItem(item, daysDifference)
        total += itemTotal
    } else {
        total += item.prices[0].cost
    }

    return new Decimal(total).toDecimalPlaces(2).toNumber()
}


export function calculateTotal(item, singleDayRent, rentedDays) {
    return singleDayRent ?
        item.singleDayPrices.find(price => price.type === singleDayRent.type).price :
        calculateTotalFromOrder(item, rentedDays)
}
