import React from "react";
import {globalPageStyle} from "../../../global/style/globalPageStyle";
import MyNormalText from "../../commons/myNormalText";

function Help() {

    return (
        <div style={globalPageStyle.pageBackground}>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText text={'Richiedi supporto'}
                                  bold={true}
                                  fontSize={22}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText
                        text={'Hai bisogno di aiuto? Contattaci direttamente alla e-mail help@locemsolutions.com per qualsiasi esigenza, risponderemo nel più breve tempo possibile.'}/>
                </div>
            </main>
        </div>
    )
}

export default Help
