import React, {useState} from "react";
import MainButton from "../../commons/mainButton";
import Loader from "../../commons/loader";
import {GLOBAL_BORDER_RADIUS} from "../../../costants";
import MyNormalText from "../../commons/myNormalText";

function ImpersonificaSection({rents, premiumPalette, loadingRents, switchProfile}) {

    const primaryColor = premiumPalette.primaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative

    const [loading, setLoading] = useState(false)

    if (loadingRents) {
        return <Loader/>
    }

    function handleSwitchProfile(rent) {
        setLoading(true)
        switchProfile(rent.rentId)
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }

    return (
        <div style={{...pageStyle.rentContainer, backgroundColor: primaryColor}}>
            {loading ?
                <Loader absoluteFullPage={true}/> :
                <></>}
            <div style={{marginBottom: 10}}>
                <MyNormalText text={'Scegli il noleggio'}
                              fontSize={18}
                              bold={true}/>
            </div>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: 10}}>
                {rents.map(rent => {
                    return (
                        <div key={rent.rentId}>
                            <MainButton text={rent.name}
                                        action={() => handleSwitchProfile(rent)}
                                        backgroundColor={primaryColorFirstAlternative}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const pageStyle = {
    rentContainer: {
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default ImpersonificaSection
