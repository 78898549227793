import React, {useEffect, useState} from "react";
import {GERMAN_LANGUAGE, ITALIAN_LANGUAGE} from "../../App";
import {websitePages} from "../../costants";
import {useGlobalState} from "../../global/globalState";
import {useLocation} from "react-router-dom";

const traduction = require('../../traduction.json')

function MyNormalText({
                          text,
                          textWithTranslation,
                          textUnderlain,
                          language,
                          link,
                          hideUnderlainLink,
                          color,
                          fontSize,
                          textShadow,
                          textOverflow,
                          lineHeight,
                          bold
                      }) {

    const location = useLocation()
    const {globalState, setGlobalState} = useGlobalState()
    const [textColor, setTextColor] = useState('black')
    const [fontSizeCoefficient, setFontSizeCoefficient] = useState(0)

    useEffect(() => {
        calculateTextColor(globalState)
    }, [globalState.premiumBackgroundPalette])

    useEffect(() => {
        setFontSizeCoefficient(globalState.premiumFontSizeCoefficient)
    }, [globalState.premiumFontSizeCoefficient])

    function calculateTextColor(globalState) {
        if (location.pathname.includes(websitePages.DASHBOARD) && globalState.premiumBackgroundPalette.isDarkMode) {
            setTextColor('white')
        } else {
            setTextColor('black')
        }
    }

    let textStyle = {
        ...pageStyle.text,
        fontSize: (fontSize ? fontSize : 16) + fontSizeCoefficient,
        lineHeight: lineHeight ? lineHeight : undefined,
        color: color ? color : textColor,
        fontWeight: bold ? 'bold' : undefined,
        textShadow: textShadow ? textShadow : undefined,
        textDecoration: textUnderlain ? 'underline' : undefined
    }

    if (textOverflow) {
        textStyle = {...textStyle, ...pageStyle.overflow}
    }

    if (hideUnderlainLink) {
        textStyle = {...textStyle, ...pageStyle.hideUnderlainLink}
    }

    if (textWithTranslation && language) {
        function searchForTranslation() {
            if (language === ITALIAN_LANGUAGE) {
                return traduction.italian[textWithTranslation]
            } else if (language === GERMAN_LANGUAGE) {
                return traduction.german[textWithTranslation]
            } else {
                return traduction.english[textWithTranslation]
            }
        }

        if (link) {
            return <a href={link}
                      style={textStyle}>{searchForTranslation()}</a>
        }

        return <span style={textStyle}>{searchForTranslation()}</span>
    }

    if (link) {
        return <a href={link}
                  style={textStyle}>{text}</a>
    }

    return <span style={textStyle}>{text}</span>
}

const pageStyle = {
    text: {},
    overflow: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    hideUnderlainLink: {
        textDecoration: 'none',
        color: 'black',
        cursor: 'pointer'
    }
}

export default MyNormalText
