import React, {useEffect, useState} from "react";
import {dashboardPremiumLinks, GLOBAL_BORDER_RADIUS_ACCENTUATED, orderStatus, singleRentType} from "../../../costants";
import MainButton from "../../commons/mainButton";
import moment from "moment/moment";
import {fromMomentDate, singleDayRentToMomentDate, toMomentDate} from "../../../global/dates";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import Loader from "../../commons/loader";
import BookingDetail from "../booking/bookingDetail";
import MyPremiumCalendar from "./myPremiumCalendar";
import ItemsPremiumData from "./itemsPremiumData";
import {total} from "react-big-calendar/lib/utils/dates";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Route, Routes, useNavigate} from "react-router-dom";
import BookingPopupCreator from "../booking/bookingPopupCreator";

const NEW_BOOKING_TITLE = ' Date ordine'

function Premium({
                     rentUser,
                     currentPageUrl,
                     premiumPalette,
                     items,
                     setItems,
                     handleSelectChat,
                     reloadTimelineBookings
                 }) {

    const DEFAULT_CALENDAR_HEIGHT = window.innerHeight * 1.4

    const navigate = useNavigate()

    const [zoomCalendar, setZoomCalendar] = useState(false)
    const [currentCalendarMonth, setCurrentCalendarMonth] = useState(new Date())

    const [calendarEvents, setCalendarEvents] = useState([])
    const [loadingPage, setLoadingPage] = useState(false)

    const [newBookingItems, setNewBookingItems] = useState([])

    const [bookingIdSelected, setBookingIdSelected] = useState(null)
    const [openBookingDetail, setOpenBookingDetail] = useState(false)
    const initialBookingCreatorOrderDatesValue = {}
    const [bookingCreatorOrderDates, setBookingCreatorOrderDates] = useState(initialBookingCreatorOrderDatesValue)

    const [thisIsASingleDayRent, setThisIsASingleDayRent] = useState(false)
    const [loadingAvailableItems, setLoadingAvailableItems] = useState(false)

    const [bookingToUpdate, setBookingToUpdate] = useState(null)

    const [theUserIsUpdatingABooking, setTheUserIsUpdatingABooking] = useState(false)
    const initialPopupValues = ""
    const [popupClientName, setPopupClientName] = useState(initialPopupValues)
    const initialPopupRangeSelected = []
    const [popupRangeSelected, setPopupRangeSelected] = useState(initialPopupRangeSelected)
    const [popupClientEmail, setPopupClientEmail] = useState(initialPopupValues)
    const [popupNotes, setPopupNotes] = useState(initialPopupValues)
    const initialPopupSlotValue = null
    const [popupSlotSelected, setPopupSlotSelected] = useState(initialPopupSlotValue)
    const initialStatePopupCustomTimeSlot = '00:00'
    const [popupCustomTimeSlotFrom, setPopupCustomTimeSlotFrom] = useState(initialStatePopupCustomTimeSlot)
    const [popupCustomTimeSlotTo, setPopupCustomTimeSlotTo] = useState(initialStatePopupCustomTimeSlot)
    const itemsToAddInitialValue = []
    const [popupItemsToAdd, setPopupItemsToAdd] = useState(itemsToAddInitialValue)
    const totalInitialState = 0
    const [popupBookingTotal, setPopupBookingTotal] = useState(totalInitialState)
    const [popupBookingIsPaid, setPopupBookingIsPaid] = useState(false)
    const [canCreateOrModifyOder, setCanCreateOrModifyOder] = useState(false)

    const [triggerGetBookingsView, setTriggerGetBookingsView] = useState(false)

    const [calendarHeight, setCalendarHeight] = useState(DEFAULT_CALENDAR_HEIGHT)
    const [seedMyPremiumCalendar, setSeedMyPremiumCalendar] = useState(1)

    // premium data
    const [itemsPremiumData, setItemsPremiumData] = useState([])

    const [isPopupCustomManualTimeSlot, setIsPopupCustomManualTimeSlot] = useState(false)

    useEffect(() => {
        const request = {
            rentId: rentUser.rentId,
            dateFilter: {
                from: fromMomentDate(moment(currentCalendarMonth).startOf('month')),
                to: fromMomentDate(moment(currentCalendarMonth).endOf('month'))
            }
        }
        getBookings(request)
    }, [triggerGetBookingsView])

    useEffect(() => {
        setPopupBookingTotal(popupItemsToAdd.reduce((acc, currentItem) => {
            if (currentItem.total) {
                return acc + currentItem.total
            }
            return acc
        }, 0))
    }, [popupItemsToAdd])

    useEffect(() => {
        if (popupClientName === initialPopupValues ||
            popupBookingTotal === totalInitialState ||
            (thisIsASingleDayRent && (!popupSlotSelected && !isPopupCustomManualTimeSlot))) {
            setCanCreateOrModifyOder(false)
        } else {
            setCanCreateOrModifyOder(true)
        }
    }, [popupClientName, popupBookingTotal, popupItemsToAdd, popupSlotSelected, popupCustomTimeSlotFrom, popupCustomTimeSlotTo, popupBookingIsPaid])

    useEffect(() => {
        const isCustomManualTimeSlot = popupCustomTimeSlotFrom !== initialStatePopupCustomTimeSlot
        setIsPopupCustomManualTimeSlot(isCustomManualTimeSlot)
    }, [popupCustomTimeSlotFrom])

    useEffect(() => {
        if (bookingCreatorOrderDates.from && bookingCreatorOrderDates.to) {
            setThisIsASingleDayRent(false)
        } else if (bookingCreatorOrderDates.from && !bookingCreatorOrderDates.to) {
            setThisIsASingleDayRent(true)
        }
    }, [bookingCreatorOrderDates])

    useEffect(() => {
        if (thisIsASingleDayRent && popupSlotSelected) {
            const orderDates = {from: bookingCreatorOrderDates.from, to: bookingCreatorOrderDates.to}
            getAvailableItems(orderDates, thisIsASingleDayRent)
        }
    }, [popupSlotSelected])

    useEffect(() => {
        if (thisIsASingleDayRent && isPopupCustomManualTimeSlot) {
            const orderDates = {from: bookingCreatorOrderDates.from, to: bookingCreatorOrderDates.to}
            const singleDaySlot = isMorning(popupCustomTimeSlotFrom) ?
                createRentSlotConfigurationByType(singleRentType.HALF_DAY_MORNING, popupCustomTimeSlotFrom, popupCustomTimeSlotTo) :
                createRentSlotConfigurationByType(singleRentType.HALF_DAY_EVENING, popupCustomTimeSlotFrom, popupCustomTimeSlotTo)
            getAvailableItems(orderDates, thisIsASingleDayRent, singleDaySlot)
        }
    }, [popupCustomTimeSlotFrom, popupCustomTimeSlotTo])

    useEffect(() => {
        AsyncStorage.getItem('premium-calendar-height')
            .then(value => {
                if (value) {
                    setCalendarHeight(JSON.parse(value))
                } else {
                    setCalendarHeight(DEFAULT_CALENDAR_HEIGHT)
                }
            })
    }, [])

    function createIdForBlockDates(momentStartDate, momentEndDate) {
        return momentStartDate.toISOString() + '/' + momentEndDate?.toISOString()
    }

    function getAvailableItems(orderDates, thisIsASingleDayRent, singleDaySlot) {
        setLoadingAvailableItems(true)
        httpsCallable(functions, 'rentItemsListView')({
            rentId: rentUser.rentId,
            archived: false,
            excludedBookingDateRange: {beginDate: orderDates.from, endDate: orderDates.to},
            isSingleDayRent: thisIsASingleDayRent,
            singleDaySlot: singleDaySlot ? singleDaySlot : popupSlotSelected
        }).then((response) => {
            const items = response.data.items
            setNewBookingItems(items)
            setLoadingAvailableItems(false)
        }).catch(() => {
            setLoadingAvailableItems(false)
            alert('Ooops! Sembra esserci stato un errore.')
        })
    }

    function handleDeleteManualBooking(booking, reloadBookings) {
        setLoadingPage(true)
        const rentDeleteBookingCommand = httpsCallable(functions, 'rentDeleteBookingCommand')
        return rentDeleteBookingCommand({
            rentId: rentUser.rentId,
            bookingId: booking.bookingId
        }).then(() => {
            if (reloadBookings) {
                setTriggerGetBookingsView(!triggerGetBookingsView)
            }
            reloadTimelineBookings(true)
            setOpenBookingDetail(false)
            setLoadingPage(false)
        }).catch(() => {
            setOpenBookingDetail(false)
            setLoadingPage(false)
            alert('Ooops! Sembra esserci stato un errore.')
        })
    }

    const handleSelectEvent = (event, e) => {
        setBookingIdSelected(event.bookingId)
        setOpenBookingDetail(true)
    }

    const handleSelectRangePopupCreator = ({start, end}) => {
        setPopupRangeSelected([{
            id: createIdForBlockDates(start, end),
            start: start,
            end: end,
            title: NEW_BOOKING_TITLE
        }])

        const from = fromMomentDate(toMomentDate(start))
        const to = fromMomentDate(toMomentDate(end))
        const thisIsASingleDayRent = from === to
        const orderDateToIfPresent = thisIsASingleDayRent ? null : to
        const orderDates = {from: from, to: orderDateToIfPresent}
        setBookingCreatorOrderDates(orderDates)

        setPopupItemsToAdd(itemsToAddInitialValue)
        setPopupBookingTotal(totalInitialState)
        setPopupSlotSelected(initialPopupSlotValue)
        if (thisIsASingleDayRent) {
            setThisIsASingleDayRent(true)
            setNewBookingItems([])
        } else {
            getAvailableItems(orderDates, thisIsASingleDayRent)
        }
    }

    function getBookings(request) {
        setLoadingPage(true)
        httpsCallable(functions, 'rentBookingsByDatePremiumDataView')(request)
            .then((response) => {
                const bookings = response.data.bookings
                let events = []
                bookings.forEach(booking => {
                    if (booking.status === orderStatus.ACCEPTED) {
                        let start
                        let end
                        if (booking.isSingleDayRent) {
                            const singleDaySlot = booking.orderDates.singleDaySlot
                            start = singleDayRentToMomentDate(booking.orderDates.from, singleDaySlot.from)
                            end = singleDayRentToMomentDate(booking.orderDates.from, singleDaySlot.to)
                        } else {
                            start = toMomentDate(booking.orderDates.from)
                            end = toMomentDate(booking.orderDates.to).add(1, 'days')
                        }
                        const event = {
                            id: booking.bookingId,
                            bookingId: booking.bookingId,
                            start: start,
                            end: end ? end : start,
                            title: booking.client.name,
                            backgroundColor: booking.onCalendar?.backgroundColor,
                            type: booking.type,
                            isPaid: booking.isPaid,
                            itemNames: booking.items.map(item => '[' + item.item.assignedRentCode + ']' + ' [' + item.item.size + '] ' + item.item.name).join('\n')
                        }
                        events.push(event)
                    }
                })

                const parsedEvents = events.map(({start, end, ...rest}) => {
                    return {start: start.toDate(), end: end.toDate(), ...rest}
                })
                setCalendarEvents(parsedEvents)
                setLoadingPage(false)
            })
            .catch((error) => {
                alert(error.message)
                setLoadingPage(false)
            })
    }

    const handleNavigate = (newDate, view) => {
        setCurrentCalendarMonth(newDate)
        const request = {
            rentId: rentUser.rentId,
            dateFilter: {
                from: fromMomentDate(moment(newDate).startOf('month')),
                to: fromMomentDate(moment(newDate).endOf('month'))
            }
        }
        getBookings(request)
    }

    function createBooking(backgroundColor) {
        setLoadingPage(true)
        const rentCreateAcceptedBookingCommand = httpsCallable(functions, 'rentCreateAcceptedBookingCommand')
        let request = {
            rentId: rentUser.rentId,
            client: {email: popupClientEmail, name: popupClientName},
            orderDates: bookingCreatorOrderDates,
            total: popupBookingTotal,
            isPaid: popupBookingIsPaid,
            items: popupItemsToAdd,
            notes: popupNotes,
            isSingleDayRent: thisIsASingleDayRent,
            singleDaySlot: calculateSingleDaySlot()
        }
        if (backgroundColor) {
            request.backgroundColor = backgroundColor
        }
        rentCreateAcceptedBookingCommand(request)
            .then(() => {
                handleResetBookingPopupCreatorState()
                reloadTimelineBookings(true)
                setTriggerGetBookingsView(!triggerGetBookingsView)
                setPopupRangeSelected(initialPopupRangeSelected)
                setLoadingPage(false)
                closeBookingCreator()
            })
            .catch(() => {
                setLoadingPage(false)
                alert('Ooops! Sembra esserci stato un errore.')
            })
    }

    function handleInsertOrUpdateNewBooking() {
        if (!canCreateOrModifyOder) {
            return
        }

        if (theUserIsUpdatingABooking) {
            handleDeleteManualBooking(bookingToUpdate, false)
                .then(() => createBooking(bookingToUpdate.onCalendar?.backgroundColor))
        } else {
            createBooking()
        }
    }

    function handleUpdateItemTotal(itemToUpdate, totalToUpdate) {
        let itemsParsed = []
        popupItemsToAdd.forEach(item => {
            if (item.id === itemToUpdate.id) {
                itemsParsed.push({...item, total: parseInt(totalToUpdate)})
            } else {
                itemsParsed.push(item)
            }
        })
        setPopupItemsToAdd([...itemsParsed])
    }

    function openBookingCreator() {
        navigate(dashboardPremiumLinks.CREATE_NEW_BOOKING)
    }

    function closeBookingCreator() {
        navigate(-1)
    }

    function handleSwitchToUpdateManualBooking(bookingToUpdate) {
        function createDateForReactBigCalendar(date) {
            if (date) {
                const componentiData = date.split("-")
                const giorno = parseInt(componentiData[0], 10)
                const mese = parseInt(componentiData[1], 10) - 1
                const anno = parseInt(componentiData[2], 10)
                return new Date(anno, mese, giorno)
            }
        }

        setTheUserIsUpdatingABooking(true)
        setBookingToUpdate(bookingToUpdate)
        const orderDates = bookingToUpdate.orderDates
        const singleDaySlot = orderDates.singleDaySlot
        getAvailableItems(orderDates, bookingToUpdate.isSingleDayRent, singleDaySlot)
        setBookingCreatorOrderDates(orderDates)
        setPopupClientName(bookingToUpdate.client.name)
        setPopupClientEmail(bookingToUpdate.client.email)
        setPopupRangeSelected([{
            id: createIdForBlockDates(toMomentDate(orderDates.from), toMomentDate(orderDates.to)),
            start: createDateForReactBigCalendar(orderDates.from),
            end: createDateForReactBigCalendar(orderDates.to ? orderDates.to : orderDates.from),
            title: NEW_BOOKING_TITLE
        }])
        setPopupNotes(bookingToUpdate.notes)

        if (singleDaySlot && singleDaySlot.isCustomManualTimeSlot) {
            setPopupCustomTimeSlotFrom(singleDaySlot.from.replace('.', ':'))
            setPopupCustomTimeSlotTo(singleDaySlot.to.replace('.', ':'))
            setIsPopupCustomManualTimeSlot(true)
        } else {
            setPopupSlotSelected(singleDaySlot)
        }
        setPopupBookingTotal(bookingToUpdate.total)
        setPopupBookingIsPaid(bookingToUpdate.isPaid)
        setPopupItemsToAdd(bookingToUpdate.items.map(item => item.item))

        reloadTimelineBookings(true)

        setOpenBookingDetail(false)
        openBookingCreator()
    }

    function handleChangePopupSlotSelected(slotSelected) {
        setPopupItemsToAdd(itemsToAddInitialValue)
        setPopupBookingTotal(totalInitialState)
        setPopupSlotSelected(slotSelected)
    }

    function handleResetBookingPopupCreatorState() {
        setCanCreateOrModifyOder(false)
        setTheUserIsUpdatingABooking(false)
        setPopupItemsToAdd(itemsToAddInitialValue)
        setPopupClientName(initialPopupValues)
        setPopupClientEmail(initialPopupValues)
        setPopupNotes(initialPopupValues)
        setPopupBookingTotal(totalInitialState)
        setPopupSlotSelected(initialPopupSlotValue)
        setPopupCustomTimeSlotFrom(initialStatePopupCustomTimeSlot)
        setPopupCustomTimeSlotTo(initialStatePopupCustomTimeSlot)
        setIsPopupCustomManualTimeSlot(false)
        setPopupRangeSelected(initialPopupRangeSelected)
        setBookingCreatorOrderDates(initialBookingCreatorOrderDatesValue)
    }

    function handleChangePremiumPage(premiumPage) {
        navigate(premiumPage)
    }

    function createRentSlotConfigurationByType(type, popupCustomTimeSlotFrom, popupCustomTimeSlotTo) {
        if (!popupCustomTimeSlotFrom) {
            return
        }
        const configurationFound = rentUser.rentSlotConfiguration.find(configuration => configuration.type === type)
        return {
            description: popupCustomTimeSlotFrom + ' - ' + popupCustomTimeSlotTo,
            from: popupCustomTimeSlotFrom.replace(/:/g, '.'),
            to: popupCustomTimeSlotTo.replace(/:/g, '.'),
            type: configurationFound.type
        }
    }

    const handleChangeCustomSlotTimeFrom = (newTime) => {
        setPopupSlotSelected(initialPopupSlotValue)
        setPopupCustomTimeSlotFrom(newTime)
        setPopupCustomTimeSlotTo(newTime)
    }

    const handleChangeCustomSlotTimeTo = (newTime) => {
        setPopupSlotSelected(initialPopupSlotValue)
        setPopupCustomTimeSlotTo(newTime)
    }

    function handleSelectPopupSlot(slot) {
        setPopupCustomTimeSlotFrom(initialStatePopupCustomTimeSlot)
        setPopupCustomTimeSlotTo(initialStatePopupCustomTimeSlot)
        setPopupSlotSelected(slot)
    }

    function calculateSingleDaySlot() {
        if (isPopupCustomManualTimeSlot) {
            return {
                description: popupCustomTimeSlotFrom + ' - ' + popupCustomTimeSlotTo,
                isCustomManualTimeSlot: true,
                from: popupCustomTimeSlotFrom.replace(/:/g, '.'),
                to: popupCustomTimeSlotTo.replace(/:/g, '.'),
                type: isMorning(popupCustomTimeSlotFrom) ? singleRentType.HALF_DAY_MORNING : singleRentType.HALF_DAY_EVENING
            }
        }

        return popupSlotSelected
    }

    function isMorning(ora) {
        if (!ora) {
            return false
        }
        const [ore, minuti] = ora.split(':').map(Number)
        if (ore >= 1 && ore < 12) {
            return true
        } else {
            return ore === 12 && minuti === 0
        }
    }

    function refreshMyPremiumCalendarComponent() {
        setSeedMyPremiumCalendar(Math.random())
    }

    function handleOpenBookingCreator() {
        openBookingCreator()
    }

    return (
        <div style={pageStyle.container}>
            <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                <div style={pageStyle.button}>
                    <MainButton text={'Calendario'}
                                action={() => handleChangePremiumPage(dashboardPremiumLinks.PREMIUM_CALENDAR)}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={currentPageUrl.includes(dashboardPremiumLinks.PREMIUM_CALENDAR) ? premiumPalette.secondaryColor : premiumPalette.primaryColor}/>
                </div>
                <div style={pageStyle.button}>
                    <MainButton text={'Statistiche'}
                                action={() => handleChangePremiumPage(dashboardPremiumLinks.PREMIUM_DATA)}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={currentPageUrl.includes(dashboardPremiumLinks.PREMIUM_DATA) ? premiumPalette.secondaryColor : premiumPalette.primaryColor}/>
                </div>
                {loadingPage ?
                    <Loader absoluteFullPage={true}
                            absoluteFullPageText={'Attendi qualche istante'}/> :
                    <></>}
            </div>
            <div style={{marginTop: 30}}>
                <Routes>
                    <Route path={dashboardPremiumLinks.PREMIUM_CALENDAR}
                           element={
                               <>
                                   <MyPremiumCalendar key={seedMyPremiumCalendar}
                                                      seedMyPremiumCalendar={seedMyPremiumCalendar}
                                                      premiumPalette={premiumPalette}
                                                      zoomCalendar={zoomCalendar}
                                                      setZoomCalendar={setZoomCalendar}
                                                      calendarEvents={calendarEvents}
                                                      selectedDay={currentCalendarMonth}
                                                      handleNavigate={handleNavigate}
                                                      handleSelectEvent={handleSelectEvent}
                                                      handleOpenBookingCreator={handleOpenBookingCreator}
                                                      refreshMyPremiumCalendarComponent={refreshMyPremiumCalendarComponent}
                                                      calendarHeight={calendarHeight}
                                                      setCalendarHeight={setCalendarHeight}/>
                                   <BookingDetail rentUser={rentUser}
                                                  premiumPalette={premiumPalette}
                                                  bookingId={bookingIdSelected}
                                                  isOpen={openBookingDetail}
                                                  setIsOpen={setOpenBookingDetail}
                                                  handleSelectChat={handleSelectChat}
                                                  handleSwitchToUpdateManualBooking={handleSwitchToUpdateManualBooking}
                                                  handleDeleteManualBooking={handleDeleteManualBooking}/>
                               </>
                           }/>
                    <Route path={dashboardPremiumLinks.PREMIUM_DATA}
                           element={<ItemsPremiumData items={items}
                                                      itemsPremiumData={itemsPremiumData}
                                                      setItemsPremiumData={setItemsPremiumData}
                                                      setItems={setItems}
                                                      premiumPalette={premiumPalette}
                                                      rentUser={rentUser}/>}/>
                    <Route path={dashboardPremiumLinks.CREATE_NEW_BOOKING}
                           element={<BookingPopupCreator rentUser={rentUser}
                                                         navigate={navigate}
                                                         premiumPalette={premiumPalette}
                                                         itemsToSelectFrom={newBookingItems.slice()}
                                                         setNewBookingItems={setNewBookingItems}
                                                         orderDates={bookingCreatorOrderDates}
                                                         rangeSelected={popupRangeSelected}
                                                         handleSelectRange={handleSelectRangePopupCreator}
                                                         initialStringValues={initialPopupValues}
                                                         initialSlotValue={initialPopupSlotValue}
                                                         clientName={popupClientName}
                                                         setClientName={setPopupClientName}
                                                         slotSelected={popupSlotSelected}
                                                         setSlotSelected={handleChangePopupSlotSelected}
                                                         customTimeSlotFrom={popupCustomTimeSlotFrom}
                                                         customTimeSlotTo={popupCustomTimeSlotTo}
                                                         handleChangeCustomSlotTimeFrom={handleChangeCustomSlotTimeFrom}
                                                         handleChangeCustomSlotTimeTo={handleChangeCustomSlotTimeTo}
                                                         initialStateCustomTimeSlot={initialStatePopupCustomTimeSlot}
                                                         handleSelectSlot={handleSelectPopupSlot}
                                                         clientEmail={popupClientEmail}
                                                         setClientEmail={setPopupClientEmail}
                                                         notes={popupNotes}
                                                         setNotes={setPopupNotes}
                                                         itemsToAdd={popupItemsToAdd}
                                                         setItemsToAdd={setPopupItemsToAdd}
                                                         total={popupBookingTotal}
                                                         isPaid={popupBookingIsPaid}
                                                         setIsPaid={setPopupBookingIsPaid}
                                                         handleUpdateItemTotal={handleUpdateItemTotal}
                                                         loadingAvailableItems={loadingAvailableItems}
                                                         handleInsertOrUpdateNewBooking={handleInsertOrUpdateNewBooking}
                                                         canCreateNewBooking={canCreateOrModifyOder}
                                                         handleCloseBookingCreator={closeBookingCreator}
                                                         theUserIsUpdatingABooking={theUserIsUpdatingABooking}/>}/>
                </Routes>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    button: {},
    blackPage: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    }
}

export default Premium
