import React from "react";
import MyNormalText from "../commons/myNormalText";
import {
    communicationMessagesLevel,
    communicationMessagesLevelColors,
    GLOBAL_BACKGROUND_POPUP_ABSOLUTE, GLOBAL_BACKGROUND_TRANSPARENT,
    GLOBAL_BORDER_RADIUS
} from "../../costants";
import MainButton from "../commons/mainButton";

function DashboardAdminCommunication({communications, navigate, handleRemoveCommunication, premiumPalette}) {

    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative

    if (communications.length === 0) {
        return
    }

    return (
        <div style={{...pageStyle.container, backgroundColor: primaryColorFirstAlternative}}>
            {communications.map((communication, index) => {
                let backgroundColor = communicationMessagesLevelColors.INFO
                if (communication.level === communicationMessagesLevel.WARNING) {
                    backgroundColor = communicationMessagesLevelColors.WARNING
                } else if (communication.level === communicationMessagesLevel.ALERT) {
                    backgroundColor = communicationMessagesLevelColors.ALERT
                }
                return (
                    <div key={index}
                         style={{...pageStyle.communicationContainer, backgroundColor: backgroundColor}}>
                        <div style={pageStyle.communicationInnerContainer}>
                            <div>
                                <MyNormalText text={communication.message}/>
                            </div>
                            {communication.hasLink ?
                                <div style={{display: 'flex', width: 100}}>
                                    <MainButton text={'Apri'}
                                                action={() => navigate(communication.link)}
                                                backgroundColor={GLOBAL_BACKGROUND_TRANSPARENT}
                                                secondaryImage={require('../../imgs/actions/external-link.png')}
                                                secondaryImageHeight={18}
                                                secondaryImageWidth={18}/>
                                </div> :
                                <></>}
                        </div>
                        <div style={pageStyle.buttonClose}>
                            <MainButton image={require('../../imgs/actions/remove.png')}
                                        action={() => handleRemoveCommunication(index)}
                                        imageWidth={15}
                                        imageHeight={15}/>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        gap: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    communicationContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    communicationInnerContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        gap: 20
    },
    buttonClose: {}
}

export default DashboardAdminCommunication
