import React from "react";
import {HOME_TRANSPARENT_BACKGROUND, MAX_DIV_WIDTH_HOME} from "../../../costants";
import {FaCode, FaProjectDiagram} from "react-icons/fa";
import MyNormalText from "../../commons/myNormalText";

function HomeWhoWeAre({language}) {

    const FONT_SIZE_TITLE = 20
    const FONT_SIZE_TEXT = 16
    const IMAGE_SIZE = 40

    return (
        <div style={pageStyle.container}>
            <div style={pageStyle.section}>
                <MyNormalText bold={true}
                              textWithTranslation={'the.locem.brand'}
                              language={language}
                              fontSize={FONT_SIZE_TITLE}/>
                <MyNormalText textWithTranslation={'the.locem.brand.first.sentence'}
                              language={language}
                              fontSize={FONT_SIZE_TEXT}/>
                <MyNormalText textWithTranslation={'the.locem.brand.second.sentence'}
                              language={language}
                              fontSize={FONT_SIZE_TEXT}/>
                <MyNormalText textWithTranslation={'the.locem.brand.third.sentence'}
                              language={language}
                              fontSize={FONT_SIZE_TEXT}/>
                <MyNormalText textWithTranslation={'the.locem.brand.fourth.sentence'}
                              language={language}
                              fontSize={FONT_SIZE_TEXT}/>
            </div>
            <div style={pageStyle.section}>
                <div style={pageStyle.subSection}>
                    <MyNormalText bold={true} textWithTranslation={'home.vision'}
                                  language={language}
                                  fontSize={FONT_SIZE_TITLE}/>
                    <MyNormalText textWithTranslation={'home.vision.first.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                </div>
                <div style={pageStyle.subSection}>
                    <MyNormalText bold={true} textWithTranslation={'home.mission'}
                                  language={language}
                                  fontSize={FONT_SIZE_TITLE}/>
                    <MyNormalText textWithTranslation={'home.mission.first.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                </div>
                <div style={pageStyle.subSection}>
                    <MyNormalText bold={true} textWithTranslation={'home.no.one.like.us'}
                                  language={language}
                                  fontSize={FONT_SIZE_TITLE}/>
                    <MyNormalText textWithTranslation={'home.no.one.like.us.first.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                    <br/>
                    <MyNormalText textWithTranslation={'home.no.one.like.us.second.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                    <br/>
                    <MyNormalText textWithTranslation={'home.no.one.like.us.third.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                </div>
            </div>
            <div style={pageStyle.section}>
                <MyNormalText bold={true}
                              textWithTranslation={'home.key.values'}
                              language={language}
                              fontSize={FONT_SIZE_TITLE}/>
                <div style={pageStyle.subSectionValues}>
                    <div style={pageStyle.imageValue}>
                        <FaProjectDiagram size={IMAGE_SIZE}
                                          color={'black'}/>
                    </div>
                    <MyNormalText textWithTranslation={'home.key.values.first.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                </div>
                <div style={pageStyle.subSectionValues}>
                    <div style={pageStyle.imageValue}>
                        <FaCode size={IMAGE_SIZE}
                                color={'black'}/>
                    </div>
                    <MyNormalText textWithTranslation={'home.key.values.second.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                </div>
                <div style={pageStyle.subSectionValues}>
                    <div style={pageStyle.imageValue}>
                        <img src={require("../../../imgs/home/agile.png")}
                             alt="Agile"
                             style={{width: IMAGE_SIZE}}/>
                    </div>
                    <MyNormalText textWithTranslation={'home.key.values.third.sentence'}
                                  language={language}
                                  fontSize={FONT_SIZE_TEXT}/>
                </div>
                <div style={pageStyle.subSectionValues}>
                    <div style={pageStyle.imageValue}>
                        <img src={require("../../../imgs/home/discovery.png")}
                             alt="Discovery"
                             style={{width: IMAGE_SIZE}}/>
                    </div>
                    <div>
                        <MyNormalText textWithTranslation={'home.key.values.fourth.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                        <MyNormalText textWithTranslation={'home.key.values.fifth.sentence'}
                                      language={language}
                                      fontSize={FONT_SIZE_TEXT}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        gap: 20,
        padding: 40,
        margin: 20,
        borderRadius: 50,
        maxWidth: MAX_DIV_WIDTH_HOME,
        backgroundColor: HOME_TRANSPARENT_BACKGROUND
    },
    subSection: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    subSectionValues: {
        display: 'flex',
        alignItems: 'center'
    },
    imageValue: {
        marginRight: 10
    }
}

export default HomeWhoWeAre;
