import React, {useEffect, useState} from "react";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import MainButton from "../../commons/mainButton";
import {Route, Routes, useNavigate} from "react-router-dom";
import {dashboardSuperUserAdminLinks} from "../../../costants";
import ImpersonificaSection from "./impersonificaSection";
import CreateRentSection from "./createRentSection";
import CreateItems from "../item/createItems";
import CreateCommunication from "./createCommunication";

function SuperUserAdmin({rentUser, premiumPalette, currentPageUrl, switchProfile}) {

    const navigate = useNavigate()

    const [rents, setRents] = useState([])
    const [rentSelected, setRentSelected] = useState(rentUser)
    const [loadingRents, setLoadingRents] = useState(true)

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor

    useEffect(() => {
        const rentSuperUserAdminRentsView = httpsCallable(functions, 'rentSuperUserAdminRentsView')
        rentSuperUserAdminRentsView({rentId: rentUser.rentId})
            .then(response => {
                const rents = response.data.rents
                setRents(rents)
                setLoadingRents(false)
            })
            .catch(() => setLoadingRents(false))
    }, [])

    return (
        <div style={pageStyle.container}>
            <div style={pageStyle.buttonContainer}>
                <div style={pageStyle.button}>
                    <MainButton text={'Crea comunicazione'}
                                action={() => navigate(dashboardSuperUserAdminLinks.CREATE_COMMUNICATION)}
                                borderRadius={20}
                                backgroundColor={currentPageUrl.includes(dashboardSuperUserAdminLinks.CREATE_COMMUNICATION) ? secondaryColor : primaryColor}/>
                </div>
                <div style={pageStyle.button}>
                    <MainButton text={'Impersonifica'}
                                action={() => navigate(dashboardSuperUserAdminLinks.IMPERSONIFICA)}
                                borderRadius={20}
                                backgroundColor={currentPageUrl.includes(dashboardSuperUserAdminLinks.IMPERSONIFICA) ? secondaryColor : primaryColor}/>
                </div>
                <div style={pageStyle.button}>
                    <MainButton text={'Censimento noleggio'}
                                action={() => navigate(dashboardSuperUserAdminLinks.CREATE_RENT)}
                                borderRadius={20}
                                backgroundColor={currentPageUrl.includes(dashboardSuperUserAdminLinks.CREATE_RENT) ? secondaryColor : primaryColor}/>
                </div>
                <div style={pageStyle.button}>
                    <MainButton text={'Crea articoli'}
                                action={() => navigate(dashboardSuperUserAdminLinks.CREATE_ITEMS)}
                                borderRadius={20}
                                backgroundColor={currentPageUrl.includes(dashboardSuperUserAdminLinks.CREATE_ITEMS) ? secondaryColor : primaryColor}/>
                </div>
            </div>
            <Routes>
                <Route path={dashboardSuperUserAdminLinks.CREATE_COMMUNICATION}
                       element={<CreateCommunication rents={rents}
                                                     rentUser={rentUser}
                                                     loadingRents={loadingRents}
                                                     premiumPalette={premiumPalette}/>}/>
                <Route path={dashboardSuperUserAdminLinks.IMPERSONIFICA}
                       element={<ImpersonificaSection rents={rents}
                                                      loadingRents={loadingRents}
                                                      switchProfile={switchProfile}
                                                      premiumPalette={premiumPalette}/>}/>
                <Route path={dashboardSuperUserAdminLinks.CREATE_RENT}
                       element={<CreateRentSection rentUser={rentUser}
                                                   premiumPalette={premiumPalette}/>}/>
                <Route path={dashboardSuperUserAdminLinks.CREATE_ITEMS}
                       element={<CreateItems rents={rents}
                                             rentSelected={rentSelected}
                                             setRentSelected={setRentSelected}
                                             premiumPalette={premiumPalette}/>}/>
            </Routes>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    buttonContainer: {
        display: 'flex',
        alignSelf: 'flex-start',
        marginBottom: 30
    },
    button: {
        marginRight: 10
    }
}

export default SuperUserAdmin
