import {premiumDataType} from "../costants";

function createDateFromTimestamp(premiumData) {
    if (premiumData.timeStampEventDate) {
        return new Date(premiumData.timeStampEventDate._seconds * 1000)
    }
    return new Date(premiumData.timeStampInsertDate._seconds * 1000)
}

function calculatePercentage(value, total) {
    return (value / total) * 100
}

export function calculateItemPremiumDataCosts(data) {
    return data
        .filter(value => value.type === premiumDataType.MANUAL_INSERT_COST)
        .sort((a, b) => a.timeStampInsertDate._seconds - b.timeStampInsertDate._seconds)
}

export function calculateItemBookingsVsCosts(itemPremiumData) {
    let manualAndLoceMBookingAmount = 0
    let manualInsertCostAmount = 0
    let amountTotal = 0
    itemPremiumData.forEach(value => {
        if (value.type === premiumDataType.MANUAL_ACCEPTED_BOOKING || value.type === premiumDataType.APP_LOCEM_ACCEPTED_BOOKING) {
            manualAndLoceMBookingAmount += value.amount
        } else if (value.type === premiumDataType.MANUAL_INSERT_COST) {
            manualInsertCostAmount += value.amount
        }
        amountTotal += Math.abs(value.amount)
    })

    return [{
        description: 'Ordini',
        percentage: calculatePercentage(manualAndLoceMBookingAmount, amountTotal)
    }, {
        description: 'Costi',
        percentage: calculatePercentage(-manualInsertCostAmount, amountTotal)
    }]
}

export function calculateItemCosts(itemPremiumData) {
    let value = []
    return itemPremiumData.map(premiumData => {
        if (premiumData.type === premiumDataType.MANUAL_INSERT_COST) {
            if (value[premiumData.description]) {
                value[premiumData.description].amount += premiumData.amount
            } else {
                value[premiumData.description] = {
                    description: premiumData.description,
                    amount: premiumData.amount
                }
            }
        }
    }).map(() => Object.values(value))
}

export function calculateBarChartTimeline(itemPremiumData) {
    let value = []
    itemPremiumData.map(premiumData => {
        if (premiumData.type === premiumDataType.MANUAL_ACCEPTED_BOOKING) {
            value.push({
                description: 'Ordine manuale',
                bookingId: premiumData.bookingId,
                amount: premiumData.amount,
                timestamp: createDateFromTimestamp(premiumData)
            })
        } else if (premiumData.type === premiumDataType.APP_LOCEM_ACCEPTED_BOOKING) {
            value.push({
                description: 'App LoceM',
                bookingId: premiumData.bookingId,
                amount: premiumData.amount,
                timestamp: createDateFromTimestamp(premiumData)
            })
        } else if (premiumData.type === premiumDataType.MANUAL_INSERT_COST) {
            value.push({
                description: premiumData.description,
                amount: premiumData.amount,
                timestamp: createDateFromTimestamp(premiumData)
            })
        }
    })

    value.sort((a, b) => a.timestamp - b.timestamp)
    return value
}

export function calculateBarChatTimelinePerMonth(data) {
    const timelinePerMonth = data.reduce((acc, current) => {
        const date = createDateFromTimestamp(current)
        const monthKey = `${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`
        if (acc[monthKey]) {
            acc[monthKey].amount += current.amount
        } else {
            acc[monthKey] = {
                month: monthKey,
                amount: current.amount,
                timestamp: date,
            }
        }
        return acc
    }, {})

    return Object.values(timelinePerMonth).sort((a, b) => a.timestamp - b.timestamp)
}
