import React from "react";
import BookingRow from "../booking/bookingRow";
import Select from "react-select";
import {globalElementSelectStyle} from "../../../global/style/globalElementSelectStyle";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import * as XLSX from 'xlsx';
import MainButton from "../../commons/mainButton";

function SearchBookings({
                            user,
                            premiumPalette,
                            bookings,
                            setBookings,
                            setMonthYearStringSelected,
                            monthYearStrings,
                            monthYearStringSelected,
                            setLoading,
                            handleSelectChat
                        }) {

    const primaryColor = premiumPalette.primaryColor

    function fetchBookings(value) {
        setMonthYearStringSelected(value.label)

        setLoading(true)
        const firstDay = value.value.from
        const lastDay = value.value.to
        const rentBookingsByDateView = httpsCallable(functions, 'rentBookingsByDateView')
        rentBookingsByDateView({rentId: user.rentId, dateFilter: {from: firstDay, to: lastDay}})
            .then((response) => {
                const bookings = response.data.bookings
                setBookings(bookings)
                setLoading(false)
            }).catch(() => setLoading(false))
    }

    const exportToExcel = () => {
        const parsedBookings = bookings.map(booking => {
            return {
                id: booking.orderId,
                status: booking.status,
                total: booking.total,
                paid: booking.isPaid
            }
        })
        const worksheet = XLSX.utils.json_to_sheet(parsedBookings)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dati')
        XLSX.writeFile(workbook, monthYearStringSelected + '.xlsx')
    }

    return (
        <>
            <Select styles={globalElementSelectStyle}
                    placeholder={monthYearStringSelected ? monthYearStringSelected : 'Scegli un mese...'}
                    onChange={(newValue) => fetchBookings(newValue)}
                    options={monthYearStrings}/>
            {bookings.length > 0 ?
                <div style={{marginTop: 20}}>
                    <div style={{width: 150, marginBottom: 20}}>
                        <MainButton text={'Download'}
                                    action={exportToExcel}
                                    secondaryImage={require('../../../imgs/actions/excel.png')}
                                    secondaryImageHeight={20}
                                    secondaryImageWidth={20}
                                    backgroundColor={primaryColor}/>
                    </div>
                    {bookings?.map((booking, index) => {
                        const lastBooking = (index === bookings.length - 1)
                        const marginBottom = lastBooking ? 0 : 20
                        return (
                            <div key={booking.bookingId}
                                 style={{marginBottom: marginBottom}}>
                                <BookingRow rentUser={user}
                                            premiumPalette={premiumPalette}
                                            booking={booking}
                                            handleSelectChat={handleSelectChat}/>
                            </div>
                        )
                    })}
                </div> :
                <></>}
        </>
    )
}

export default SearchBookings
