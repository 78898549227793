import React, {useState} from "react";
import MyNormalText from "../../commons/myNormalText";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import Select from "react-select";
import {globalElementSelectStyle} from "../../../global/style/globalElementSelectStyle";
import {EURO_VALUE, GLOBAL_BORDER_RADIUS, operationOnRentItemType, SIZES} from "../../../costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import MainButton from "../../commons/mainButton";

function UpdateItem({rentUser, item, refreshItems, refreshItem, premiumPalette}) {

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor

    const [category, setCategory] = useState(item.category)
    const [size, setSize] = useState(item.size)
    const [minUserHeight, setMinUserHeight] = useState(item.heightSuggested[0])
    const [maxUserHeight, setMaxUserHeight] = useState(item.heightSuggested[1])
    const [sorting, setSorting] = useState(item.sorting)
    const [assignedRentCode, setAssignedRentCode] = useState(item.assignedRentCode)
    const [priceFirstDay, setPriceFirstDay] = useState(item.prices[0]?.cost)
    const [priceSecondDay, setPriceSecondDay] = useState(item.prices[1]?.cost)
    const [priceThirdDay, setPriceThirdDay] = useState(item.prices[2]?.cost)
    const [priceFourthDay, setPriceFourthDay] = useState(item.prices[3]?.cost)
    const [priceFifthDay, setPriceFifthDay] = useState(item.prices[4]?.cost)
    const [priceSixthDay, setPriceSixthDay] = useState(item.prices[5]?.cost)
    const [priceSeventhDay, setPriceSeventhDay] = useState(item.prices[6]?.cost)
    const [slotConfiguration, setSlotConfiguration] = useState(item.singleDayPrices)
    const [description, setDescription] = useState(item.infos[0].description)
    const [loading, setLoading] = useState(false)

    const textAreaStyle = {
        ...globalElementInputStyle.customInput,
        resize: 'none',
        height: 160
    }

    function handleUpdateItem() {
        const atLeastOneValueMissing = !category || !size || !minUserHeight || !maxUserHeight || !sorting || !assignedRentCode ||
            !priceFirstDay || !priceSecondDay || !priceThirdDay || !priceFourthDay || !priceFifthDay || !priceSixthDay || !priceSeventhDay ||
            slotConfiguration.filter(slot => !slot.price).length > 0 || !description

        if (atLeastOneValueMissing) {
            alert('Attenzione, non hai compilato tutti i campi')
            return
        }

        setLoading(true)
        const itemRequest = {
            assignedRentCode: assignedRentCode,
            singleDayPrices: slotConfiguration,
            heightSuggested: [minUserHeight, maxUserHeight],
            size: size,
            sorting: sorting,
            category: category,
            prices: [
                {
                    cost: priceFirstDay,
                    day: "1"
                },
                {
                    cost: priceSecondDay,
                    day: "2"
                },
                {
                    cost: priceThirdDay,
                    day: "3"
                },
                {
                    cost: priceFourthDay,
                    day: "4"
                },
                {
                    cost: priceFifthDay,
                    day: "5"
                },
                {
                    cost: priceSixthDay,
                    day: "6"
                },
                {
                    cost: priceSeventhDay,
                    day: "7"
                }
            ],
            infos: [
                {
                    description: description,
                    title: "Descrizione"
                }
            ]
        }

        const request = {
            itemId: item.id,
            rentId: rentUser.rentId,
            item: {...item, ...itemRequest},
            operationType: operationOnRentItemType.UPDATE_GENERIC_INFO
        }
        const rentUpdateItemInfoCommand = httpsCallable(functions, 'rentUpdateItemInfoCommand')
        rentUpdateItemInfoCommand(request)
            .then((response) => {
                setLoading(false)
                if (response.data.response === 'OK') {
                    refreshItems()
                    refreshItem()
                } else {
                    alert('Ooops! Sembra esserci stato un problema nel bloccare le date.')
                }
            })
            .catch(() => {
                setLoading(false)
            })
    }

    function getRentCategories() {
        return rentUser.categories.map(category => ({value: category, label: category}))
    }

    function getSizes() {
        return Object.keys(SIZES).map(size => ({value: size, label: size}))
    }

    return (
        <div style={pageStyle.container}>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={pageStyle.section}>
                    <div>
                        <MyNormalText text={'Codice magazzino'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'text'}
                               value={assignedRentCode}
                               placeholder={'Codice magazzino'}
                               onChange={(e) => setAssignedRentCode(e.target.value)}/>
                    </div>
                    <div>
                        <MyNormalText text={'Categoria'}/>
                        <Select styles={globalElementSelectStyle}
                                placeholder={category}
                                onChange={(value) => setCategory(value.label)}
                                options={getRentCategories()}/>
                    </div>
                    <div>
                        <MyNormalText text={'Taglia'}/>
                        <Select styles={globalElementSelectStyle}
                                placeholder={size}
                                onChange={(value) => setSize(value.label)}
                                options={getSizes()}/>
                    </div>
                    <div>
                        <MyNormalText text={'Altezza minima suggerita in cm'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={minUserHeight}
                               placeholder={minUserHeight}
                               onChange={(e) => {
                                   const height = e.target.value
                                   if (height) {
                                       setMinUserHeight(parseInt(height))
                                   }
                               }}/>
                    </div>
                    <div>
                        <MyNormalText text={'Altezza massima suggerita in cm'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={maxUserHeight}
                               placeholder={'Altezza massima consigliata in cm'}
                               onChange={(e) => {
                                   const height = e.target.value
                                   if (height) {
                                       setMaxUserHeight(parseInt(height))
                                   }
                               }}/>
                    </div>
                    <div>
                        <MyNormalText text={'Posizione in elenco'}/>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={sorting}
                               placeholder={sorting}
                               onChange={(e) => {
                                   const sorting = e.target.value
                                   if (sorting) {
                                       setSorting(parseInt(sorting))
                                   }
                               }}/>
                    </div>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Descrizione'}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={pageStyle.section}>
                    <textarea style={textAreaStyle}
                              value={description}
                              placeholder={'Inserisci la descrizione'}
                              onChange={(event) => setDescription(event.target.value)}
                              rows={description.split('\n').length}/>
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{marginBottom: 5}}>
                    <MyNormalText text={'Prezzi giorno singolo ' + EURO_VALUE}
                                  bold={true}
                                  fontSize={18}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                    {rentUser.rentSlotConfiguration.map((slot, index) => {
                        return (
                            <div key={index}
                                 style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{marginRight: 10}}>
                                    <MyNormalText text={slot.description}/>
                                </div>
                                <div>
                                    <input style={globalElementInputStyle.customInput}
                                           type={'number'}
                                           value={slotConfiguration[index].price}
                                           placeholder={'Prezzo'}
                                           onChange={(e) => {
                                               const price = e.target.value
                                               if (price) {
                                                   slotConfiguration[index].price = parseInt(price)
                                                   setSlotConfiguration([...slotConfiguration])
                                               }
                                           }}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div style={{...pageStyle.innerContainer, backgroundColor: primaryColor}}>
                <div style={{display: 'flex', flexDirection: 'column', marginBottom: 10}}>
                    <MyNormalText text={'Prezzi su più giorni ' + EURO_VALUE}
                                  bold={true}
                                  fontSize={18}/>
                    <MyNormalText
                        text={'Esempio: Primo giorno: 60, secondo giorno: 55, terzo giorno 50, quarto giorno...'}
                        fontSize={14}/>
                </div>
                <div style={pageStyle.section}>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceFirstDay}
                               placeholder={'Primo giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceFirstDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceSecondDay}
                               placeholder={'Secondo giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceSecondDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceThirdDay}
                               placeholder={'Terzo giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceThirdDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceFourthDay}
                               placeholder={'Quarto giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceFourthDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceFifthDay}
                               placeholder={'Quinto giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceFifthDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceSixthDay}
                               placeholder={'Sesto giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceSixthDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                    <div>
                        <input style={globalElementInputStyle.customInput}
                               type={'number'}
                               value={priceSeventhDay}
                               placeholder={'Settimo giorno'}
                               onChange={(e) => {
                                   const price = e.target.value
                                   if (price) {
                                       setPriceSeventhDay(parseInt(price))
                                   }
                               }}/>
                    </div>
                </div>
            </div>
            <div>
                <MainButton text={'Aggiorna'}
                            action={handleUpdateItem}
                            loading={loading}
                            disabled={loading}
                            backgroundColor={secondaryColor}/>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    innerContainer: {
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    section: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10
    }
}

export default UpdateItem
