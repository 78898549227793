import React, {useEffect, useState} from 'react';
import {GLOBAL_BORDER_RADIUS} from "../../costants";
import MyNormalText from "./myNormalText";

const Snackbar = ({message, language, duration = 3000, onClose, isOk, isInfo, isWarning, isAlert}) => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        setVisible(true)

        const timer = setTimeout(() => {
            setVisible(false)
            onClose()
        }, duration)

        return () => clearTimeout(timer)
    }, [duration, onClose])

    function calculateBackground() {
        if (isOk) {
            return '#98e098'
        } else if (isInfo) {
            return '#81cfff'
        } else if (isWarning) {
            return '#fffd81'
        } else if (isAlert) {
            return '#fc713c'
        }
        return 'white'
    }

    const snackbarStyle = {
        visibility: visible ? 'visible' : 'hidden',
        minWidth: 250,
        margin: 'auto',
        backgroundColor: calculateBackground(),
        textAlign: 'center',
        padding: 20,
        position: 'fixed',
        left: '50%',
        bottom: visible ? 30 : -100,
        transform: 'translateX(-50%)',
        borderRadius: GLOBAL_BORDER_RADIUS,
        zIndex: 15,
        transition: 'bottom 0.3s ease, visibility 0.3s ease'
    }

    if (language) {
        return (
            <div style={snackbarStyle}>
                <MyNormalText textWithTranslation={message}
                              language={language}
                              color={'black'}/>
            </div>
        )
    }

    return (
        <div style={snackbarStyle}>
            <MyNormalText text={message}
                          color={'black'}/>
        </div>
    )
}

export default Snackbar
