import React, {useRef, useState} from "react";
import MainButton from "../../commons/mainButton";
import {ENGLISH_LANGUAGE, GERMAN_LANGUAGE, ITALIAN_LANGUAGE} from "../../../App";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";
import useMyClickOutListener from "../../../global/listeners/useMyClickOutListener";

function Languages({languageState}) {

    const [openSelection, setOpenSelection] = useState(false)

    const languagesRef = useRef()

    useMyEscapeListener(() => setOpenSelection(false))
    useMyClickOutListener(languagesRef, () => setOpenSelection(false))

    function handleActionOnFlag(languageSelected) {
        if (!openSelection) {
            setOpenSelection(true)
            return
        }

        languageState.setLanguage(languageSelected)
        setOpenSelection(false)
    }

    const buttons = [
        {
            language: ITALIAN_LANGUAGE,
            button: <MainButton image={require("../../../imgs/languages/italian.png")}
                                action={() => handleActionOnFlag(ITALIAN_LANGUAGE)}/>
        },
        {
            language: GERMAN_LANGUAGE,
            button: <MainButton image={require("../../../imgs/languages/germany.png")}
                                action={() => handleActionOnFlag(GERMAN_LANGUAGE)}/>
        },
        {
            language: ENGLISH_LANGUAGE,
            button: <MainButton image={require("../../../imgs/languages/uk.png")}
                                action={() => handleActionOnFlag(ENGLISH_LANGUAGE)}/>
        }
    ]

    function flagByLanguage() {
        if (languageState.language === ITALIAN_LANGUAGE) {
            return buttons.find(button => button.language === ITALIAN_LANGUAGE).button
        } else if (languageState.language === GERMAN_LANGUAGE) {
            return buttons.find(button => button.language === GERMAN_LANGUAGE).button
        } else if (languageState.language === ENGLISH_LANGUAGE) {
            return buttons.find(button => button.language === ENGLISH_LANGUAGE).button
        }
    }

    return (
        <div style={pageStyle.container}
             ref={languagesRef}>
            {openSelection ?
                <div style={pageStyle.containerFlags}>
                    {buttons.map((button, index) => {
                        return (
                            <div key={index.toString()}>
                                {buttons[index].button}
                            </div>
                        )
                    })}
                </div> :
                <></>}
            {flagByLanguage()}
        </div>
    )
}

const pageStyle = {
    container: {},
    containerFlags: {
        position: 'fixed',
        top: 60
    }
}

export default Languages
