import React from "react";
import {globalPageStyle} from "../../../global/style/globalPageStyle";
import MyNormalText from "../../commons/myNormalText";

function Contacts({languageState, isMobileView}) {

    return (
        <div style={globalPageStyle.pageBackground}>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText textWithTranslation={'contacts.page.title'}
                                  bold={true}
                                  language={languageState.language}
                                  fontSize={30}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'Get in touch'}
                                  bold={true}/>
                    <br/>
                    <MyNormalText textWithTranslation={'contacts.page.support.text'}
                                  language={languageState.language}/>
                    <br/>
                    <br/>
                    <MyNormalText textWithTranslation={'contacts.page.support.title'}
                                  bold={true}
                                  language={languageState.language}/>
                    <br/>
                    <MyNormalText textWithTranslation={'contacts.page.do.you.need.help'}
                                  language={languageState.language}/>

                    <br/>
                    <MyNormalText text={'help@locemsolutions.com'}/>
                    <br/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'Work with us'}/>
                    <br/>
                    <MyNormalText textWithTranslation={'contacts.page.work.with.us'}
                                  language={languageState.language}/>
                    <br/>
                    <MyNormalText textWithTranslation={'contacts.page.work.with.us.text'}
                                  language={languageState.language}/>
                </div>
            </main>
        </div>
    )
}

export default Contacts
