function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return [r, g, b]
}

function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
}

function isDarkMode([r, g, b]) {
    const luminance = 0.2126 * (r / 255) ** 2.2 +
        0.7152 * (g / 255) ** 2.2 +
        0.0722 * (b / 255) ** 2.2
    return luminance < 0.2
}

function lightenColor([r, g, b], amount) {
    const newR = Math.min(255, Math.round(r + (255 - r) * amount))
    const newG = Math.min(255, Math.round(g + (255 - g) * amount))
    const newB = Math.min(255, Math.round(b + (255 - b) * amount))
    return rgbToHex(newR, newG, newB)
}

export function generatePremiumPaletteCustomColor(hexColor) {
    if (!hexColor) {
        return
    }
    const primaryRgb = hexToRgb(hexColor)
    const secondaryColor = rgbToHex((primaryRgb[0] + 50) % 256, (primaryRgb[1] + 75) % 256, (primaryRgb[2] + 125) % 256)
    const firstAlternative = lightenColor(primaryRgb, 0.15)
    const secondAlternative = lightenColor(primaryRgb, 0.30)
    const thirdAlternative = lightenColor(primaryRgb, 0.50)
    const darkMode = isDarkMode(primaryRgb)
    return {
        primaryColor: hexColor,
        secondaryColor: secondaryColor,
        primaryColorFirstAlternative: firstAlternative,
        primaryColorSecondAlternative: secondAlternative,
        primaryColorFourthAlternative: thirdAlternative,
        isDarkMode: darkMode
    }
}
