import React, {useState} from "react";
import MainButton from "../../commons/mainButton";
import Loader from "../../commons/loader";
import {communicationMessagesLevel, GLOBAL_BORDER_RADIUS} from "../../../costants";
import MyNormalText from "../../commons/myNormalText";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import DatePicker from "react-datepicker";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import {toDDMMYYYYDate, toMomentDate} from "../../../global/dates";

function CreateCommunication({rents, rentUser, premiumPalette, loadingRents}) {

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative

    const [rentsSelected, setRentsSelected] = useState([])
    const [newMessage, setNewMessage] = useState('')
    const [linkActive, setLinkActive] = useState(false)
    const [link, setLink] = useState('')
    const [levelSelected, setLevelSelected] = useState(null)
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [loading, setLoading] = useState(false)

    if (loadingRents) {
        return <Loader/>
    }

    function handleSelectRent(rent) {
        rentsSelected.push(rent)
        setRentsSelected([...rentsSelected])
    }

    const textAreaStyle = {
        ...globalElementInputStyle.customInput,
        resize: 'none',
        height: 80
    }

    function handleSaveCommunication() {
        if (rentsSelected.length === 0 || newMessage === '' || !levelSelected || !fromDate || !toDate) {
            return
        }

        setLoading(true)
        const rentSuperUserAdminCreateCommunicationCommand = httpsCallable(functions, 'rentSuperUserAdminCreateCommunicationCommand')
        const request = {
            rentId: rentUser.rentId,
            rentIds: rentsSelected.map(rent => rent.rentId),
            message: newMessage,
            level: levelSelected,
            from: toDDMMYYYYDate(toMomentDate(fromDate)),
            to: toDDMMYYYYDate(toMomentDate(toDate)),
            hasLink: linkActive,
            link: link
        }
        rentSuperUserAdminCreateCommunicationCommand(request)
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }

    return (
        <div style={pageStyle.rentContainer}>
            {loading ?
                <Loader absoluteFullPage={true}/> :
                <></>}
            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                <MyNormalText text={'Scegli i noleggi'}
                              bold={true}
                              fontSize={18}/>
                <div style={pageStyle.rents}>
                    {rents.map(rent => {
                        const rentSelected = rentsSelected.map(rent => rent.rentId).includes(rent.rentId)
                        return (
                            <div key={rent.rentId}>
                                <MainButton text={rent.name}
                                            action={() => handleSelectRent(rent)}
                                            backgroundColor={rentSelected ? secondaryColor : primaryColorFirstAlternative}/>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                <MyNormalText text={'Livello'}
                              bold={true}
                              fontSize={18}/>
                <MainButton text={communicationMessagesLevel.INFO}
                            action={() => setLevelSelected(communicationMessagesLevel.INFO)}
                            backgroundColor={levelSelected === communicationMessagesLevel.INFO ? secondaryColor : primaryColorFirstAlternative}/>
                <MainButton text={communicationMessagesLevel.WARNING}
                            action={() => setLevelSelected(communicationMessagesLevel.WARNING)}
                            backgroundColor={levelSelected === communicationMessagesLevel.WARNING ? secondaryColor : primaryColorFirstAlternative}/>
                <MainButton text={communicationMessagesLevel.ALERT}
                            action={() => setLevelSelected(communicationMessagesLevel.ALERT)}
                            backgroundColor={levelSelected === communicationMessagesLevel.ALERT ? secondaryColor : primaryColorFirstAlternative}/>
            </div>
            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                <MyNormalText text={'Dal - Al'}
                              bold={true}
                              fontSize={18}/>
                <div style={{display: 'flex', gap: 10}}>
                    <DatePicker selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                showMonthYearDropdown={false}
                                className={'custom-input'}
                                dateFormat={'dd/MM/yyyy'}
                                locale={'it'}
                                placeholderText={'Data inizio'}/>
                    <DatePicker selected={toDate}
                                onChange={(date) => setToDate(date)}
                                showMonthYearDropdown={false}
                                className={'custom-input'}
                                dateFormat={'dd/MM/yyyy'}
                                locale={'it'}
                                placeholderText={'Data Fine'}/>
                </div>
            </div>
            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                <MyNormalText text={'Scrivi il messaggio'}
                              bold={true}
                              fontSize={18}/>
                <textarea style={textAreaStyle}
                          value={newMessage}
                          placeholder={'Scrivi un messaggio...'}
                          onChange={(event) => setNewMessage(event.target.value)}
                          rows={newMessage.split('\n').length}/>
            </div>
            <div style={{...pageStyle.section, backgroundColor: primaryColor}}>
                <MyNormalText text={'Link'}
                              bold={true}
                              fontSize={18}/>
                <div style={{display: 'flex'}}>
                    <MyNormalText text={'Attivo?'}/>
                    <input type={'checkbox'}
                           style={{marginLeft: 10, width: 20, height: 20}}
                           checked={linkActive}
                           onChange={() => setLinkActive(!linkActive)}/>
                </div>
                <textarea style={globalElementInputStyle.customInput}
                          value={link}
                          placeholder={'Inserisci il link...'}
                          onChange={(event) => setLink(event.target.value)}
                          rows={link.split('\n').length}/>
            </div>
            <MainButton text={'Salva'}
                        action={handleSaveCommunication}
                        backgroundColor={secondaryColor}
                        height={50}/>
        </div>
    )
}

const pageStyle = {
    rentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        gap: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    rents: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 10
    }
}

export default CreateCommunication
