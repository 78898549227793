import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import MainButton from "../../commons/mainButton";
import MyNormalText from "../../commons/myNormalText";

const CustomToolbar = (toolbar) => {
    const {premiumPalette} = toolbar
    const {buttonHeight} = toolbar
    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor

    const getTitle = () => {
        const {view, date, localizer} = toolbar
        if (view === 'month') {
            return localizer.format(date, 'MMMM yyyy')
        } else if (view === 'day') {
            return localizer.format(date, 'DD MMMM YYYY')
        }
        return ''
    }

    return (
        <div style={styles.toolbarContainer}>
            <div style={styles.leftSection}>
                <MainButton image={require('../../../imgs/actions/arrow-left.png')}
                            action={() => toolbar.onNavigate('PREV')}
                            backgroundColor={primaryColor}
                            height={buttonHeight}
                            imageWidth={15}
                            imageHeight={15}/>
                <MainButton text={'Oggi'}
                            action={() => toolbar.onNavigate('TODAY')}
                            height={buttonHeight}
                            backgroundColor={primaryColor}/>
                <MainButton image={require('../../../imgs/actions/arrow-right.png')}
                            action={() => toolbar.onNavigate('NEXT')}
                            height={buttonHeight}
                            backgroundColor={primaryColor}
                            imageWidth={15}
                            imageHeight={15}/>
            </div>
            <div style={styles.centerSection}>
                <MyNormalText text={getTitle()}
                              bold={true}
                              fontSize={18}/>
            </div>
            <div style={styles.rightSection}>
                <MainButton text={'Mese'}
                            action={() => toolbar.onView('month')}
                            borderRadius={50}
                            height={buttonHeight}
                            backgroundColor={toolbar.view === 'month' ? secondaryColor : primaryColor}/>
                <MainButton text={'Giorno'}
                            action={() => toolbar.onView('day')}
                            borderRadius={50}
                            height={buttonHeight}
                            backgroundColor={toolbar.view === 'day' ? secondaryColor : primaryColor}/>
            </div>
        </div>
    )
}

const styles = {
    toolbarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10
    },
    leftSection: {
        display: 'flex',
        gap: 5
    },
    centerSection: {
        textAlign: 'center',
        flex: 1
    },
    rightSection: {
        display: 'flex',
        gap: 5
    }
}

export default CustomToolbar;
