import React from "react";
import ItemBox from "./item/itemBox";
import Loader from "../commons/loader";
import {dashboardMyItemsLinks, GLOBAL_BORDER_RADIUS_ACCENTUATED} from "../../costants";
import MainButton from "../commons/mainButton";
import ButtonsToOrderItems from "./premium/buttonsToOrderItems";
import {Route, Routes, useNavigate} from "react-router-dom";

function MyItems({
                     rentUser,
                     currentPageUrl,
                     premiumPalette,
                     items,
                     setItems,
                     itemsArchived,
                     setItemsArchivedForMyItems,
                     loadingItems,
                     refreshItems
                 }) {

    const navigate = useNavigate()

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor

    const currentlyOnVetrina = currentPageUrl.includes(dashboardMyItemsLinks.VETRINA)
    const currentlyOnArchivedSection = currentPageUrl.includes(dashboardMyItemsLinks.ARCHIVE)

    function onlineItems(items) {
        return (
            <>
                <ButtonsToOrderItems items={currentlyOnVetrina ? items : itemsArchived}
                                     setItems={currentlyOnVetrina ? setItems : setItemsArchivedForMyItems}
                                     premiumPalette={premiumPalette}
                                     buttonsColor={primaryColor}/>
                {loadingItems ?
                    <Loader/> :
                    <div style={pageStyle.itemList}>
                        {items.map((item) => {
                            return (
                                <div key={item.id}
                                     style={pageStyle.itemContainer}>
                                    <ItemBox rentUser={rentUser}
                                             premiumPalette={premiumPalette}
                                             refreshItems={refreshItems}
                                             openOperations={true}
                                             item={item}
                                             width={'100%'}
                                             imageHeight={120}/>
                                </div>
                            )
                        })}
                    </div>}
            </>
        )
    }

    function handleChangeMyItemsSection(section) {
        navigate(section)
    }

    return (
        <div style={pageStyle.container}>
            <div style={pageStyle.buttonsContainer}>
                <div>
                    <MainButton text={'In Vetrina (' + items.length + ')'}
                                action={() => handleChangeMyItemsSection(dashboardMyItemsLinks.VETRINA)}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={currentlyOnVetrina ? secondaryColor : primaryColor}/>
                </div>
                <div>
                    <MainButton text={'Archiviati (' + itemsArchived.length + ')'}
                                action={() => handleChangeMyItemsSection(dashboardMyItemsLinks.ARCHIVE)}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={currentlyOnArchivedSection ? secondaryColor : primaryColor}/>
                </div>
            </div>
            <div>
                <Routes>
                    <Route path={dashboardMyItemsLinks.VETRINA}
                           element={onlineItems(items)}/>
                    <Route path={dashboardMyItemsLinks.ARCHIVE}
                           element={onlineItems(itemsArchived)}/>
                </Routes>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 30
    },
    buttonsContainer: {
        display: 'flex',
        gap: 10
    },
    itemList: {
        marginTop: 10,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: 10
    },
    itemContainer: {
        marginBottom: 20
    }
}

export default MyItems
