import React, {useEffect, useState} from "react";
import {motion} from 'framer-motion';
import MyNormalText from "./myNormalText";
import Lottie from "lottie-react";

import coloredLoader from '../../animations/colored-loader.json';
import yellowBikeLoader from '../../animations/yellow-bike.json';
import cubeLoader from '../../animations/cube-loader.json';
import {GLOBAL_BACKGROUND_POPUP_ABSOLUTE} from "../../costants";
import {BeatLoader} from "react-spinners";

function Loader({color, absoluteFullPage, absoluteFullPageText}) {

    const lottieAnimations = [coloredLoader, yellowBikeLoader, cubeLoader]

    const [animationData, setAnimationData] = useState(null)

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * lottieAnimations.length)
        setAnimationData(lottieAnimations[randomIndex])
    }, [])

    const spinnerColor = 'white'
    if (absoluteFullPage) {
        return (
            <div style={pageStyle.containerFullPageOpacity}>
                <div style={pageStyle.middlePage}>
                    <motion.div initial={{opacity: 0, scale: 0.5}}
                                animate={{opacity: 1, scale: 1}}
                                exit={{opacity: 0, scale: 0.5}}>
                        <Lottie animationData={animationData}
                                loop={true}
                                style={{width: 150, height: 150}}/>
                    </motion.div>
                    <MyNormalText text={absoluteFullPageText ? absoluteFullPageText : 'Loading...'}
                                  color={spinnerColor}
                                  fontSize={26}/>
                </div>
            </div>
        )
    }

    return (
        <BeatLoader color={color ? color : spinnerColor}
                    loading={true}
                    cssOverride={pageStyle.loader}
                    aria-label={'Loading Spinner'}
                    data-testid={'loader'}/>
    )

}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
        cursor: 'wait'
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    middlePage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh'
    }
}

export default Loader
