import React, {useEffect, useRef, useState} from "react";
import {EURO_VALUE, GLOBAL_BACKGROUND_POPUP_ABSOLUTE, GLOBAL_BORDER_RADIUS} from "../../../costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import Loader from "../../commons/loader";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MyNormalText from "../../commons/myNormalText";
import {Bar, Doughnut, Pie} from 'react-chartjs-2';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    RadialLinearScale,
    Title,
    Tooltip
} from "chart.js";
import MainButton from "../../commons/mainButton";
import {
    calculateBarChartTimeline,
    calculateBarChatTimelinePerMonth,
    calculateItemBookingsVsCosts,
    calculateItemCosts,
    calculateItemPremiumDataCosts
} from "../../../global/premiumDataDetail";
import useMyClickOutListener from "../../../global/listeners/useMyClickOutListener";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";

ChartJS.register(CategoryScale, LinearScale, LineElement, BarElement, PointElement, Title, Tooltip, Legend, RadialLinearScale, ArcElement)

const borderCharColor = ['rgba(255, 255, 255, 0.6)']

function ItemPremiumDataDetail({
                                   rentUser,
                                   item,
                                   manualAndLoceMBookingAmount,
                                   amountProduced,
                                   manualInsertCostAmount,
                                   premiumPalette,
                                   handleReloadData,
                                   setIsOpen
                               }) {

    const primaryColor = premiumPalette.primaryColor
    const primaryColorSecondAlternative = premiumPalette.primaryColorSecondAlternative

    const [loadingItemPremiumData, setLoadingItemPremiumData] = useState(true)
    const [loadingDeleteItemPremiumData, setLoadingDeleteItemPremiumData] = useState(false)
    const [itemPremiumDataCosts, setItemPremiumDataCosts] = useState([])
    const [barChartTimeline, setBarChartTimeline] = useState([])
    const [barChartTimelinePerMonth, setBarChartTimelinePerMonth] = useState([])
    const [manualCosts, setManualCosts] = useState([])
    const [bookingsVsCosts, setBookingsVsCosts] = useState([])

    const itemDetailRef = useRef()

    useEffect(() => {
        getItemPremiumData()
    }, [])

    useMyEscapeListener(() => setIsOpen(false))
    useMyClickOutListener(itemDetailRef, () => setIsOpen(false))

    function updatePremiumData(premiumItemData) {
        setBarChartTimeline(calculateBarChartTimeline(premiumItemData))
        setBarChartTimelinePerMonth(calculateBarChatTimelinePerMonth(premiumItemData))
        calculateItemCosts(premiumItemData)
            .map(values => setManualCosts(values))
        setBookingsVsCosts(calculateItemBookingsVsCosts(premiumItemData))
        setItemPremiumDataCosts(calculateItemPremiumDataCosts(premiumItemData))
    }

    function getItemPremiumData() {
        httpsCallable(functions, 'rentItemDetailPremiumDataView')({rentId: rentUser.rentId, itemId: item.id})
            .then((response) => {
                const premiumItemData = response.data.premiumItemData
                updatePremiumData(premiumItemData)
                setLoadingItemPremiumData(false)
            })
            .catch(() => setLoadingItemPremiumData(false))
    }

    function handleDeleteItemPremiumData(itemPremiumDataId) {
        setLoadingDeleteItemPremiumData(true)
        const request = {
            rentId: rentUser.rentId,
            itemId: item.id,
            itemPremiumDataId: itemPremiumDataId
        }
        httpsCallable(functions, 'rentDeletePremiumDataCommand')(request)
            .then((response) => {
                const premiumItemData = response.data.response
                updatePremiumData(premiumItemData)
                handleReloadData()
                setLoadingDeleteItemPremiumData(false)
            }).catch(() => setLoadingDeleteItemPremiumData(false))
    }

    function createDateFromTimestamp(premiumData) {
        if (premiumData.timeStampEventDate) {
            return new Date(premiumData.timeStampEventDate._seconds * 1000)
        }
        return new Date(premiumData.timeStampInsertDate._seconds * 1000)
    }

    function itemPolarChartView() {
        if (manualCosts.length === 0) {
            return
        }

        const chartData = {
            labels: manualCosts.map(value => value.description),
            datasets: [
                {
                    label: 'Totale',
                    data: manualCosts.map(value => Math.abs(value.amount)),
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
                    borderColor: borderCharColor,
                    borderWidth: 1
                }
            ]
        }

        const options = {
            responsive: true,
            plugins: {legend: {position: 'top'}, tooltip: {enabled: true}},
            cutout: '40%'
        }

        return <Doughnut data={chartData}
                         options={options}/>
    }

    function itemPieChartBookingsVsCostsView() {
        const chartData = {
            labels: bookingsVsCosts.map(value => value.description),
            datasets: [
                {
                    label: 'Percentuale %',
                    data: bookingsVsCosts.map(value => value.percentage),
                    backgroundColor: ['rgb(81,192,74)', 'rgb(223,0,0)'],
                    borderColor: borderCharColor,
                    borderWidth: 1
                }
            ]
        }

        return <Pie data={chartData}/>
    }

    function itemBarChartView() {
        if (barChartTimeline.length === 0) {
            return
        }

        const chartData = {
            labels: barChartTimeline.map(value => value.timestamp.toLocaleDateString('it-IT')),
            datasets: [
                {
                    label: 'Totale',
                    data: barChartTimeline.map(entry => entry.amount),
                    backgroundColor: barChartTimeline.map(entry => entry.amount > 0 ? 'rgb(81,192,74)' : 'rgb(223,0,0)'),
                    borderColor: borderCharColor,
                    borderWidth: 1
                }
            ]
        }

        const options = {
            responsive: true,
            plugins: {
                legend: {position: 'top'},
                title: {display: true, text: 'Andamento articolo per giorno'}
            },
            scales: {y: {beginAtZero: true}}
        }

        return <Bar data={chartData}
                    options={options}/>
    }

    function itemBarChartPerMonthView() {
        if (barChartTimelinePerMonth.length === 0) {
            return
        }

        const chartData = {
            labels: barChartTimelinePerMonth.map(value => value.month),
            datasets: [
                {
                    label: 'Totale',
                    data: barChartTimelinePerMonth.map(entry => entry.amount),
                    backgroundColor: barChartTimelinePerMonth.map(entry => entry.amount > 0 ? 'rgb(81,192,74)' : 'rgb(223,0,0)'),
                    borderColor: borderCharColor,
                    borderWidth: 1
                }
            ]
        }

        const options = {
            responsive: true,
            plugins: {
                legend: {position: 'top'},
                title: {display: true, text: 'Andamento articolo per mese'}
            },
            scales: {y: {beginAtZero: true}}
        }

        return <Bar data={chartData}
                    options={options}/>
    }

    const subSection = {...pageStyle.section, ...{flex: 1, backgroundColor: primaryColorSecondAlternative}}

    function formatDate(date) {
        const options = {day: '2-digit', month: '2-digit', year: 'numeric'}
        return date.toLocaleDateString('it-IT', options)
    }

    function costs() {
        return itemPremiumDataCosts
            .map(value => {
                return (
                    <div key={value.itemPremiumDataId}
                         style={{...pageStyle.containerCost, ...{backgroundColor: primaryColor}}}>
                        <div style={{display: 'flex', flex: 1, flexDirection: 'column', margin: 5}}>
                            <MyNormalText text={value.description}
                                          fontSize={14}
                                          bold={true}/>
                            <MyNormalText text={EURO_VALUE + ' ' + value.amount}
                                          fontSize={14}/>
                            <MyNormalText text={formatDate(createDateFromTimestamp(value))}
                                          fontSize={14}/>
                        </div>
                        <div>
                            <MainButton image={require("../../../imgs/actions/remove.png")}
                                        action={() => handleDeleteItemPremiumData(value.itemPremiumDataId)}
                                        disabled={loadingDeleteItemPremiumData}
                                        hideDisabledColor={true}
                                        imageHeight={18}
                                        imageWidth={18}
                                        height={30}/>
                        </div>
                    </div>
                )
            })
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingDeleteItemPremiumData ?
                <Loader absoluteFullPage={true}/> :
                <></>}
            {loadingItemPremiumData ?
                <Loader absoluteFullPage={true}/> :
                <div ref={itemDetailRef}
                     style={pageStyle.container}>
                    <div style={{...pageStyle.section, ...{backgroundColor: primaryColorSecondAlternative}}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <MyNormalText text={item.name}
                                          bold={true}
                                          fontSize={20}/>
                            <MyNormalText text={item.id}/>
                        </div>
                        <div style={pageStyle.subSection}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <MyNormalText text={item?.category + ', ' + item?.size}
                                              bold={true}/>
                                <MyNormalText text={item?.assignedRentCode ? item?.assignedRentCode : '-'}/>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{...pageStyle.generalInfoContainer, ...{backgroundColor: primaryColorSecondAlternative}}}>
                        <MyNormalText text={'Rendimento'}
                                      bold={true}/>
                        <div style={pageStyle.generalInfoSubContainer}>
                            <div style={{...pageStyle.generalInfoSectionContainer, ...{backgroundColor: primaryColor}}}>
                                <MyNormalText text={'Ordini'}
                                              bold={true}/>
                                <MyNormalText text={EURO_VALUE + ' ' + manualAndLoceMBookingAmount}/>
                            </div>
                            <div style={{...pageStyle.generalInfoSectionContainer, ...{backgroundColor: primaryColor}}}>
                                <MyNormalText text={'Costi'}
                                              bold={true}/>
                                <MyNormalText text={EURO_VALUE + ' ' + manualInsertCostAmount}
                                              color={manualInsertCostAmount < 0 ? 'red' : undefined}/>
                            </div>
                            <div style={{...pageStyle.generalInfoSectionContainer, ...{backgroundColor: primaryColor}}}>
                                <MyNormalText text={'Totale'}
                                              bold={true}/>
                                <MyNormalText text={EURO_VALUE + ' ' + amountProduced}
                                              color={amountProduced < 0 ? 'red' : undefined}/>
                            </div>
                        </div>
                    </div>
                    <div style={{...pageStyle.sectionCosts, ...{backgroundColor: primaryColorSecondAlternative}}}>
                        <MyNormalText text={'Elenco costi'}
                                      bold={true}/>
                        <div style={pageStyle.containerCostsList}>
                            {costs()}
                        </div>
                    </div>
                    <div style={{...pageStyle.section, ...{backgroundColor: primaryColorSecondAlternative}}}>
                        <MyNormalText text={'Entrate e spese per giorno'}
                                      bold={true}/>
                        {itemBarChartView()}
                    </div>
                    <div style={{...pageStyle.section, ...{backgroundColor: primaryColorSecondAlternative}}}>
                        <MyNormalText text={'Entrate e spese per mese'}
                                      bold={true}/>
                        {itemBarChartPerMonthView()}
                    </div>
                    <div style={pageStyle.containerSubSection}>
                        <div style={subSection}>
                            <MyNormalText text={'Costi'}
                                          bold={true}/>
                            {itemPolarChartView()}
                        </div>
                        <div style={subSection}>
                            <MyNormalText text={'% Ordini & Costi'}
                                          bold={true}/>
                            {itemPieChartBookingsVsCostsView()}
                        </div>
                    </div>
                </div>}
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 100,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
        overflowY: 'auto',
    },
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        transform: 'translate(-50%, 0)',
        width: 'calc(100% / 2)'
    },
    containerSubSection: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: 20
    },
    containerCostsList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
        gap: 10,
        marginTop: 5
    },
    containerCost: {
        display: 'flex',
        borderRadius: GLOBAL_BORDER_RADIUS,
    },
    section: {
        padding: 10,
        marginBottom: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    sectionCosts: {
        padding: 10,
        marginBottom: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    subSection: {
        marginTop: 20
    },
    generalInfoContainer: {
        padding: 10,
        marginBottom: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    generalInfoSubContainer: {
        marginTop: 5,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: 10
    },
    generalInfoSectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default ItemPremiumDataDetail
