import React, {lazy, Suspense, useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import LocemPrivacyPolicy from "./components/home/locemapp/locemPrivacyPolicy";
import LocemTermsOfService from "./components/home/locemapp/locemTermsOfService";
import Login from "./components/home/login/login";
import NotFound from "./components/notfound/notFound";
import {dashboardSectionLinks, MINIMUM_WIDTH_SUPPORTED, subscriptionType, websitePages} from "./costants";
import Help from "./components/home/help/help";
import LocemBusinessPrivacyPolicy from "./components/home/locembusinessapp/locemBusinessPrivacyPolicy";
import LocemBusinessTermsOfService from "./components/home/locembusinessapp/locemBusinessTermsOfService";
import LocemWebsitePrivacyPolicy from "./components/home/locemwebsite/locemWebsitePrivacyPolicy";
import LocemTermsOfServiceSpecific from "./components/home/locemapp/locemTermsOfServiceSpecific";
import LegalNotes from "./components/home/legalnotes/legalNotes";
import Contacts from "./components/home/contacts/contacts";
import LocemWebsiteCookiesPolicy from "./components/home/locemwebsite/locemWebsiteCookiesPolicy";
import {Helmet} from "react-helmet";
import Dashboard from "./components/dashboard/dashboard";
import Loader from "./components/commons/loader";

//commons css to
import './css/input.css';
import '../src/css/scrollbar.css';
import {getBrowserLanguage} from "./global/language";
import Values from "./components/home/values/values";
import WaveBackground from "./components/home/homeWaveBackground";
import Footer from "./components/home/footer/footer";
import Header from "./components/home/header/header";
import {isMobile} from "./global/mobile";
import JoinTheNetwork from "./components/home/jointhenetwork/joinTheNetwork";
import ScrollToTop from "./global/scrollToTop";
import {AuthContext} from "./components/dashboard/authProvider";
import {httpsCallable} from "firebase/functions";
import {auth, functions} from "./firebase";
import {signInWithCustomToken} from "firebase/auth";

const Home = lazy(() => import('./components/home/home'))

export const ITALIAN_LANGUAGE = 'italian'
export const ENGLISH_LANGUAGE = 'english'
export const GERMAN_LANGUAGE = 'german'

function App() {

    const {rentUser, reloadUser, loadingUser} = useContext(AuthContext)
    const [language, setLanguage] = useState(getBrowserLanguage())
    const languageState = {language: language, setLanguage: setLanguage}

    const [isMobileView, setIsMobileView] = useState(false)

    //account type
    const accountTypeInitialState = {}
    const [accountType, setAccountType] = useState(accountTypeInitialState)

    useEffect(() => {
        if (rentUser) {
            setAccountType({
                isAccountPremium: rentUser.subscriptionType === subscriptionType.PREMIUM || rentUser.subscriptionType === subscriptionType.SUPER_USER_ADMIN,
                isAccountSuperAdmin: rentUser.subscriptionType === subscriptionType.SUPER_USER_ADMIN
            })
        }
    }, [rentUser])

    useEffect(() => {
        setIsMobileView(isMobile())
    }, [])

    useEffect(() => {
        const handleResize = () => setIsMobileView(isMobile() || (window.innerWidth <= MINIMUM_WIDTH_SUPPORTED))
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    function switchProfile(rentIdTarget) {
        const rentGenerateCustomAuthTokenCommand = httpsCallable(functions, 'rentGenerateCustomAuthTokenCommand')
        const request = {
            rentId: rentUser.rentId,
            rentIdTarget: rentIdTarget
        }
        return rentGenerateCustomAuthTokenCommand(request)
            .then(async response => {
                const customToken = response.data.customToken
                return signInWithCustomToken(auth, customToken)
            })
    }

    function getDashboard() {
        return (
            <>
                {isMobileView ?
                    <Helmet>
                        <meta name={'viewport'}
                              content={`width=${MINIMUM_WIDTH_SUPPORTED}}`}/>
                    </Helmet> :
                    <></>}
                <Dashboard rentUser={rentUser}
                           reloadUser={reloadUser}
                           switchProfile={switchProfile}
                           accountType={accountType}
                           isMobileView={isMobileView}/>
            </>
        )
    }

    function getHome() {
        return (
            <Suspense fallback={<Loader absoluteFullPage={true}/>}>
                <Home isMobileView={isMobileView}
                      languageState={languageState}/>
            </Suspense>
        )
    }

    return (
        <Router>
            <Header languageState={languageState}
                    isMobileView={isMobileView}/>
            <WaveBackground/>
            <ScrollToTop/>
            <Routes>
                <Route path={'/'}
                       element={getHome()}/>
                <Route path={'/app/locem'}
                       element={getHome()}/>
                <Route path={'/app/locem-business'}
                       element={getHome()}/>
                <Route path={websitePages.VALUES}
                       element={<Values languageState={languageState}/>}/>
                <Route path={websitePages.CONTACT_US}
                       element={<JoinTheNetwork languageState={languageState}
                                                isMobileView={isMobileView}/>}/>
                <Route path={'/app/locem/privacy'}
                       element={<LocemPrivacyPolicy/>}/>
                <Route path={'/app/locem/legal-terms'}
                       element={<LocemTermsOfService/>}/>
                <Route path={'/app/locem/legal-terms/specific'}
                       element={<LocemTermsOfServiceSpecific/>}/>
                <Route path={'/app/locem-business/privacy'}
                       element={<LocemBusinessPrivacyPolicy/>}/>
                <Route path={'/app/locem-business/legal-terms'}
                       element={<LocemBusinessTermsOfService/>}/>
                <Route path={'/app/locem-website/privacy-policy'}
                       element={<LocemWebsitePrivacyPolicy/>}/>
                <Route path={'/app/locem-website/cookies-policy'}
                       element={<LocemWebsiteCookiesPolicy/>}/>
                <Route path={'/help'}
                       element={<Help/>}/>
                <Route path={'/legal-notes'}
                       element={<LegalNotes/>}/>
                <Route path={'/contacts'}
                       element={<Contacts languageState={languageState}
                                          isMobileView={isMobileView}/>}/>
                <Route path={websitePages.LOGIN}
                       element={rentUser ?
                           <Navigate to={websitePages.DASHBOARD + '/' + dashboardSectionLinks.NEW_BOOKINGS}/> :
                           <Login languageState={languageState}
                                  loadingUser={loadingUser}/>}/>
                <Route path={websitePages.DASHBOARD + '/*'}
                       element={rentUser ?
                           getDashboard() :
                           <Navigate to={websitePages.LOGIN}
                                     languageState={languageState}/>}/>
                <Route path={websitePages.DASHBOARD + '/bookings/:idBooking'}
                       element={rentUser ?
                           getDashboard() :
                           <Navigate to={websitePages.LOGIN}
                                     languageState={languageState}/>}/>
                <Route path={'*'}
                       element={<NotFound languageState={languageState}
                                          isMobileView={isMobileView}/>}/>
            </Routes>
            <Footer language={languageState.language}/>
        </Router>
    )
}

export default App
