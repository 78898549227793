import React, {useState} from "react";
import {DISABLED, GLOBAL_BORDER_RADIUS, GLOBAL_SHADOW_MEDIUM_ACCENTUATED} from "../../costants";
import Loader from "./loader";
import MyNormalText from "./myNormalText";
import {getHoverStyle} from "../../global/style/globalButtonHoverShadowStyle";
import MyIcon from "./myIcon";

function MainButton({
                        action,
                        firstText,
                        text,
                        isTranslated,
                        language,
                        textBold,
                        textAlignStart,
                        textUnderlain,
                        image,
                        imageWidth,
                        imageHeight,
                        backgroundColor,
                        height,
                        textColor,
                        fontSize,
                        loading,
                        disabled,
                        hideDisabledColor,
                        firstImage,
                        firstImageWidth,
                        firstImageHeight,
                        secondaryImage,
                        secondaryImageWidth,
                        secondaryImageHeight,
                        paddingHorizontal,
                        paddingVertical,
                        borderRadius,
                        showShadow,
                        injectMainBody,
                        buttonHoverDisabled
                    }) {

    const [isHovered, setIsHovered] = useState(false)

    const borderRadiusCalculated = borderRadius ? borderRadius : GLOBAL_BORDER_RADIUS
    const buttonStyle = {
        ...styles.button,
        cursor: disabled ? 'not-allowed' : (loading ? 'wait' : 'pointer'),
        height: paddingVertical ? 0 : (height ? height : 40),
        paddingTop: paddingVertical ? paddingVertical : 5,
        paddingBottom: paddingVertical ? paddingVertical : 5,
        paddingLeft: paddingHorizontal ? paddingHorizontal : 10,
        paddingRight: paddingHorizontal ? paddingHorizontal : 10,
        borderRadius: borderRadiusCalculated,
        backgroundColor: (disabled && !hideDisabledColor) ? DISABLED : (backgroundColor ? backgroundColor : 'transparent'),
        boxShadow: showShadow ? GLOBAL_SHADOW_MEDIUM_ACCENTUATED : undefined
    }

    function getFirstBody() {
        if (firstText) {
            return <MyNormalText text={firstText}
                                 textUnderlain={textUnderlain}
                                 textOverflow={true}
                                 color={textColor ? textColor : undefined}
                                 fontSize={fontSize}/>
        } else if (firstImage) {
            return (
                <div style={{marginRight: 20}}>
                    <MyIcon icon={firstImage}
                            name={'first icon'}
                            pointer={!disabled}
                            width={firstImageWidth ? firstImageWidth : 25}
                            height={firstImageHeight ? firstImageHeight : 25}/>
                </div>
            )
        }
    }

    function getSecondaryBody() {
        if (secondaryImage) {
            return (
                <div style={{marginLeft: 10}}>
                    <MyIcon icon={secondaryImage}
                            name={'secondary icon'}
                            pointer={!disabled}
                            width={secondaryImageWidth ? secondaryImageWidth : 25}
                            height={secondaryImageHeight ? secondaryImageHeight : 25}/>
                </div>
            )
        }
    }

    function getMainBody() {
        if (text) {
            return (
                <div style={{textAlign: textAlignStart ? 'justify' : 'center'}}>
                    {isTranslated ?
                        <MyNormalText textWithTranslation={text}
                                      language={language}
                                      textUnderlain={textUnderlain}
                                      textOverflow={true}
                                      bold={textBold}
                                      color={textColor ? textColor : undefined}
                                      fontSize={fontSize}/> :
                        <MyNormalText text={text}
                                      textUnderlain={textUnderlain}
                                      textOverflow={true}
                                      bold={textBold}
                                      color={textColor ? textColor : undefined}
                                      fontSize={fontSize}/>}
                </div>
            )
        } else if (image) {
            return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <MyIcon icon={image}
                            name={'icon'}
                            pointer={!disabled}
                            width={imageWidth ? imageWidth : 25}
                            height={imageHeight ? imageHeight : 25}/>
                </div>
            )
        } else if (injectMainBody) {
            return injectMainBody
        }
    }

    function handleOnMouseEnter() {
        if (buttonHoverDisabled) {
            return
        }
        setIsHovered(true)
    }

    function handleOnMouseLeave() {
        setIsHovered(false)
    }

    function handleAction() {
        if (!!loading || disabled) {
            return
        }
        return action
    }

    return (
        <button style={buttonStyle}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
                onClick={handleAction()}>
            <div style={getHoverStyle(isHovered, borderRadiusCalculated)}/>
            {loading ?
                <Loader/> :
                <>
                    <div style={{flex: 1}}>
                        {getFirstBody()}
                    </div>
                    <div style={{flex: 5}}>
                        {getMainBody()}
                    </div>
                    <div style={{flex: 1}}>
                        {getSecondaryBody()}
                    </div>
                </>}
        </button>
    )
}

const styles = {
    button: {
        fontFamily: 'Poppins',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        textDecoration: 'none',
        width: '100%'
    }
}
export default MainButton
