export function validateAndSetPassword(password, setPassword, setPasswordValidated) {
    let reg = /^(?=.*[a-zA-Z]).{6,}$/
    if (reg.test(password) === false) {
        setPassword(password)
        setPasswordValidated(false)
        return false;
    } else {
        setPassword(password)
        setPasswordValidated(true)
    }
}
