import React from "react";
import MyNormalText from "../../commons/myNormalText";
import {HIGHLIGHT_COLOR} from "../../../costants";
import MainButton from "../../commons/mainButton";

function ChatList({chatRef, chats, premiumPalette, handleActivateChat, isMobileView}) {

    if (chats.length === 0) {
        return (
            <div style={{margin: 10}}>
                <MyNormalText text={'Nessuna chat attiva'}/>
            </div>
        )
    }

    if (isMobileView) {
        return (
            <div ref={chatRef}
                 style={{...pageStyle.container, ...{width: 120}}}>
                {chats.map((chat, index) => {
                    const chatBody = chat.body
                    const isLast = chats.length === index + 1
                    const infoContainerStyle = {...pageStyle.infoContainer, marginBottom: isLast ? 0 : 10}
                    return (
                        <div key={chat.chatId}>
                            <div style={infoContainerStyle}>
                                <MainButton
                                    text={chatBody.client.name + (chat.newMessagesAmount > 0 ? (' (' + chat.newMessagesAmount + ') ') : '')}
                                    action={() => handleActivateChat(chat)}
                                    backgroundColor={chat.newMessagesAmount > 0 ? HIGHLIGHT_COLOR : premiumPalette.primaryColorFirstAlternative}/>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div ref={chatRef}
             style={{...pageStyle.container, ...{width: 200}}}>
            <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
                {chats.map((chat, index) => {
                    const chatBody = chat.body
                    const isLast = chats.length === index + 1
                    const infoContainerStyle = {...pageStyle.infoContainer, marginBottom: isLast ? 0 : 10}
                    return (
                        <div key={chat.chatId}>
                            <div style={infoContainerStyle}>
                                <MainButton
                                    text={chatBody.client.name + (chat.newMessagesAmount > 0 ? (' (' + chat.newMessagesAmount + ') ') : '')}
                                    action={() => handleActivateChat(chat)}
                                    firstImage={require("../../../imgs/booking/user.png")}
                                    firstImageHeight={15}
                                    firstImageWidth={15}
                                    backgroundColor={chat.newMessagesAmount > 0 ? HIGHLIGHT_COLOR : premiumPalette.primaryColorFirstAlternative}/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '80vh',
        width: 200
    },
    infoContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    }
}

export default ChatList
