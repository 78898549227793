import React, {useEffect, useRef, useState} from "react";
import {bookingType, HIGHLIGHT_COLOR} from "../../../costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import AutomaticBookingDetail from "./automaticBookingDetail";
import ManualBookingDetail from "./manualBookingDetail";
import MainButton from "../../commons/mainButton";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";

function BookingDetail({
                           rentUser,
                           premiumPalette,
                           bookingId,
                           isOpen,
                           setIsOpen,
                           handleSelectChat,
                           handleSwitchToUpdateManualBooking,
                           handleDeleteManualBooking
                       }) {

    const [booking, setBooking] = useState({})
    const [loadingBooking, setLoadingBooking] = useState(true)
    const [loadingAccept, setLoadingAccept] = useState(false)
    const [loadingRejected, setLoadingRejected] = useState(false)
    const [openRejectOptions, setOpenRejectOptions] = useState(false)
    const [commentRejection, setCommentRejection] = useState("")

    const isAnAutomaticBooking = booking.type === bookingType.SYSTEM_AUTOMATIC

    useEffect(() => {
        getBookingDetail()
    }, [bookingId, isOpen])

    useMyEscapeListener(() => setIsOpen(false))

    function getBookingDetail() {
        setLoadingBooking(true)
        if (bookingId && isOpen) {
            const getRentBookingDetail = httpsCallable(functions, 'getRentBookingDetail')
            let request = {rentId: rentUser.rentId, bookingId: bookingId}
            getRentBookingDetail(request)
                .then((response) => {
                    const booking = response.data.booking
                    setBooking(booking)
                    setLoadingBooking(false)
                }).catch(() => setLoadingBooking(false))
        }
    }

    if (!isOpen) {
        return null
    }

    function acceptBooking() {
        setLoadingAccept(true)
        const rentAcceptBookingCommand = httpsCallable(functions, 'rentAcceptBookingCommand')
        rentAcceptBookingCommand({
            bookingId: booking.bookingId,
            rentId: rentUser.rentId
        }).then((response) => {
            const booking = response.data.booking
            setBooking(booking)
            setLoadingAccept(false)
        }).catch(() => {
            setLoadingAccept(false)
            alert('Ooops! Sembra esserci stato un errore nell\'accettare l\'ordine.')
        })
    }

    function rejectBooking() {
        setOpenRejectOptions(false)
        setLoadingRejected(true)
        const rentRejectBookingCommand = httpsCallable(functions, 'rentRejectBookingCommand')
        rentRejectBookingCommand({
            bookingId: booking.bookingId,
            rentId: rentUser.rentId,
            motivationRejection: commentRejection
        }).then((response) => {
            const booking = response.data.booking
            setBooking(booking)
            setLoadingRejected(false)
        }).catch(() => {
            setLoadingRejected(false)
            alert('Ooops! Sembra esserci stato un errore nel rifiutare l\'ordine.')
        })
    }

    if (!loadingBooking && !booking.bookingId) {
        return (
            <div style={pageStyle.container404}>
                <MainButton text={'404 booking not found'}
                            backgroundColor={HIGHLIGHT_COLOR}
                            showShadow={true}
                            action={() => setIsOpen(false)}
                            secondaryImage={require("../../../imgs/actions/delete.png")}/>
            </div>
        )
    }

    if (isAnAutomaticBooking) {
        return <AutomaticBookingDetail booking={booking}
                                       rentUser={rentUser}
                                       premiumPalette={premiumPalette}
                                       loadingBooking={loadingBooking}
                                       acceptBooking={acceptBooking}
                                       rejectBooking={rejectBooking}
                                       loadingAccept={loadingAccept}
                                       loadingRejected={loadingRejected}
                                       handleSelectChat={handleSelectChat}
                                       commentRejection={commentRejection}
                                       setCommentRejection={setCommentRejection}
                                       openRejectOptions={openRejectOptions}
                                       setOpenRejectOptions={setOpenRejectOptions}
                                       setIsOpen={setIsOpen}/>
    }

    return <ManualBookingDetail booking={booking}
                                rentUser={rentUser}
                                premiumPalette={premiumPalette}
                                loadingBooking={loadingBooking}
                                handleSwitchToUpdateManualBooking={handleSwitchToUpdateManualBooking}
                                handleDeleteManualBooking={handleDeleteManualBooking}
                                setIsOpen={setIsOpen}/>
}

const pageStyle = {
    container404: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, 0)',
        zIndex: 1
    }
}

export default BookingDetail
