import {ENGLISH_LANGUAGE, GERMAN_LANGUAGE, ITALIAN_LANGUAGE} from "../App";

export function getBrowserLanguage() {
    const language = navigator.language
    if (language === 'it' || language === 'it-IT') {
        return ITALIAN_LANGUAGE
    } else if (language === 'de' || language === 'de-DE') {
        return GERMAN_LANGUAGE
    }
    return ENGLISH_LANGUAGE
}
