import React, {createContext, useContext, useEffect, useState} from 'react';
import AsyncStorage from "@react-native-async-storage/async-storage";
import {backgroundPalette} from "../costants";

const GlobalStateContext = createContext()

export const GlobalStateProvider = ({children}) => {

    const [globalState, setGlobalState] = useState({
        premiumBackgroundPalette: {},
        premiumFontSizeCoefficient: 0
    })

    useEffect(() => {
        AsyncStorage.getItem('premium-background-palette')
            .then(value => {
                    if (value) {
                        setGlobalState(prevState => ({
                            ...prevState,
                            premiumBackgroundPalette: JSON.parse(value)
                        }))
                    } else {
                        setGlobalState(prevState => ({
                            ...prevState,
                            premiumBackgroundPalette: backgroundPalette.firstPalette
                        }))
                    }
                }
            )
    }, [])

    useEffect(() => {
        AsyncStorage.getItem('premium-fontsize-coefficient')
            .then(value => {
                    if (value) {
                        setGlobalState(prevState => ({
                            ...prevState,
                            premiumFontSizeCoefficient: JSON.parse(value)
                        }))
                    } else {
                        setGlobalState(prevState => ({
                            ...prevState,
                            premiumFontSizeCoefficient: 0
                        }))
                    }
                }
            )
    }, [])

    return (
        <GlobalStateContext.Provider value={{globalState, setGlobalState}}>
            {children}
        </GlobalStateContext.Provider>
    )
}

export const useGlobalState = () => useContext(GlobalStateContext)
