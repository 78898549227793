import React from "react";
import {globalPageStyle} from "../../../global/style/globalPageStyle";
import MyNormalText from "../../commons/myNormalText";

function LegalNotes() {

    return (
        <div style={globalPageStyle.pageBackground}>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText text={'NOTE LEGALI'}
                                  bold={true}
                                  fontSize={30}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'LOCEM SOLUTIONS SOCIETA’ A RESPONSABILITA’ LIMITATA SEMPLIFICATA'}
                                  bold={true}
                                  fontSize={22}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'Via Roma, 56'}/>
                    <br/>
                    <MyNormalText text={'37063 – Isola della Scala (VR)'}/>
                    <br/>
                    <MyNormalText text={'ITALIA'}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'Cod. Fiscale, partita iva e n. Iscrizione registro imprese: 04967430234'}/>
                    <br/>
                    <MyNormalText text={'Numero R.E.A.: VR – 460035'}/>
                    <br/>
                    <br/>
                    <MyNormalText text={'PEC: locemsolutions@pro-pec.it'}/>
                    <br/>
                    <MyNormalText text={'Email: hello@locemsolutions.com'}/>
                    <br/>
                    <MyNormalText text={'Supporto tecnico: help@locemsolutions.com'}/>
                </div>
            </main>
        </div>
    )
}

export default LegalNotes
