import React, {useState} from "react";
import BookingRow from "./booking/bookingRow";
import MyNormalText from "../commons/myNormalText";
import MainButton from "../commons/mainButton";
import {dashboardSectionNames, websitePages} from "../../costants";

const AMOUNT = 'TOTALE'
const FROM_ORDER_DATE = 'FROM ORDER DATE'
const INSERT_DATE = 'INSERT DATE'

const ASC = 'ASC'
const DESC = 'DESC'

function NewBookings({rentUser, premiumPalette, newBookings}) {

    const initialState = '';
    const [buttonOrderSelected, setButtonOrderSelected] = useState(initialState)
    const [orderByAmount, setOrderByAmount] = useState(ASC)
    const [orderByFromOrderDate, setOrderByFromOrderDate] = useState(ASC)
    const [orderByInsertDateDirection, setOrderByInsertDateDirection] = useState(ASC)

    function noBookings() {
        return <MyNormalText text={'Nessuna richiesta di prenotazione presente.'}/>
    }

    function handleOrderByAmount(order) {
        setButtonOrderSelected(AMOUNT)
        setOrderByAmount(order)
        if (order === 'ASC') {
            newBookings.sort((a, b) => a.total - b.total)
        } else if (order === 'DESC') {
            newBookings.sort((a, b) => b.total - a.total)
        }
    }

    function handleOrderByFromOrderDate(order) {
        setButtonOrderSelected(FROM_ORDER_DATE)
        setOrderByFromOrderDate(order)
        if (order === 'ASC') {
            newBookings.sort((a, b) => a.timeStampOrderDates.from - b.timeStampOrderDates.from)
        } else if (order === 'DESC') {
            newBookings.sort((a, b) => b.timeStampOrderDates.from - a.timeStampOrderDates.from)
        }
    }

    function handleOrderByInsertDate(order) {
        setButtonOrderSelected(INSERT_DATE);
        setOrderByInsertDateDirection(order);
        if (order === 'ASC') {
            newBookings.sort((a, b) => a.timeStampInsertDate.seconds - b.timeStampInsertDate.seconds)
        } else if (order === 'DESC') {
            newBookings.sort((a, b) => b.timeStampInsertDate.seconds - a.timeStampInsertDate.seconds)
        }
    }

    return (
        <div style={pageStyle.container}>
            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
                <div style={pageStyle.buttonOrderList}>
                    <MainButton text={'Totale'}
                                backgroundColor={buttonOrderSelected === AMOUNT ? premiumPalette.secondaryColor : premiumPalette.primaryColor}
                                secondaryImage={orderByAmount === ASC ? require("../../imgs/actions/arrow-up.png") : require("../../imgs/actions/arrow-down.png")}
                                height={30}
                                action={() => handleOrderByAmount(orderByAmount === ASC ? DESC : ASC)}/>

                </div>
                <div style={pageStyle.buttonOrderList}>
                    <MainButton text={'Date richieste'}
                                backgroundColor={buttonOrderSelected === FROM_ORDER_DATE ? premiumPalette.secondaryColor : premiumPalette.primaryColor}
                                secondaryImage={orderByFromOrderDate === ASC ? require("../../imgs/actions/arrow-up.png") : require("../../imgs/actions/arrow-down.png")}
                                height={30}
                                action={() => handleOrderByFromOrderDate(orderByFromOrderDate === ASC ? DESC : ASC)}/>
                </div>
                <div style={pageStyle.buttonOrderList}>
                    <MainButton text={'Inviato il'}
                                backgroundColor={buttonOrderSelected === INSERT_DATE ? premiumPalette.secondaryColor : premiumPalette.primaryColor}
                                secondaryImage={orderByInsertDateDirection === ASC ? require("../../imgs/actions/arrow-up.png") : require("../../imgs/actions/arrow-down.png")}
                                height={30}
                                action={() => handleOrderByInsertDate(orderByInsertDateDirection === ASC ? DESC : ASC)}/>
                </div>
            </div>
            {newBookings.length === 0 ?
                noBookings() :
                <></>}
            {newBookings.length > 0 ?
                newBookings.map((newBooking, index) => {
                    const lastBooking = index === newBookings.length - 1
                    const marginBottom = lastBooking ? 0 : 20
                    return (
                        <div key={newBooking.bookingId}
                             style={{marginBottom: marginBottom}}>
                            <BookingRow rentUser={rentUser}
                                        premiumPalette={premiumPalette}
                                        booking={newBooking}/>
                        </div>
                    )
                }) :
                <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    buttonOrderList: {
        marginRight: 10
    }
}

export default NewBookings
