import React, {useState} from "react";
import Loader from "../../commons/loader";
import SearchBookings from "./searchBookings";
import MainButton from "../../commons/mainButton";
import {dashboardHistoryLinks, GLOBAL_BORDER_RADIUS_ACCENTUATED} from "../../../costants";
import {Route, Routes, useNavigate} from "react-router-dom";
import MonthHistory from "./monthHistory";

function History({
                     user,
                     premiumPalette,
                     currentPageUrl,
                     bookings,
                     setBookings,
                     bookingsSearched,
                     setBookingsSearched,
                     loadingSearchBooking,
                     setLoadingSearchBooking,
                     searchValue,
                     setSearchValue,
                     monthYearStrings,
                     setMonthYearStringSelected,
                     monthYearStringSelected,
                     handleSelectChat
                 }) {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    function handleChangeHistorySection(historySection) {
        navigate(historySection)
    }

    return (
        <div style={pageStyle.container}>
            <div style={pageStyle.buttonsContainer}>
                <div>
                    <MainButton text={'Archivio'}
                                action={() => handleChangeHistorySection(dashboardHistoryLinks.ARCHIVE)}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={currentPageUrl.includes(dashboardHistoryLinks.ARCHIVE) ? premiumPalette.secondaryColor : premiumPalette.primaryColor}/>
                </div>
                <div>
                    <MainButton text={'Cerca'}
                                action={() => handleChangeHistorySection(dashboardHistoryLinks.SEARCH)}
                                borderRadius={GLOBAL_BORDER_RADIUS_ACCENTUATED}
                                backgroundColor={currentPageUrl.includes(dashboardHistoryLinks.SEARCH) ? premiumPalette.secondaryColor : premiumPalette.primaryColor}/>
                </div>
            </div>
            {loading ?
                <Loader absoluteFullPage={true}/> :
                <></>}
            <Routes>
                <Route path={dashboardHistoryLinks.ARCHIVE}
                       element={<MonthHistory user={user}
                                              bookings={bookings}
                                              setBookings={setBookings}
                                              premiumPalette={premiumPalette}
                                              monthYearStrings={monthYearStrings}
                                              monthYearStringSelected={monthYearStringSelected}
                                              setLoading={setLoading}
                                              setMonthYearStringSelected={setMonthYearStringSelected}
                                              handleSelectChat={handleSelectChat}/>}/>
                <Route path={dashboardHistoryLinks.SEARCH}
                       element={<SearchBookings user={user}
                                                premiumPalette={premiumPalette}
                                                historyBookings={bookingsSearched}
                                                setHistoryBookings={setBookingsSearched}
                                                searchValue={searchValue}
                                                setSearchValue={setSearchValue}
                                                loading={loadingSearchBooking}
                                                setLoading={setLoadingSearchBooking}
                                                handleSelectChat={handleSelectChat}/>}/>
            </Routes>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    buttonsContainer: {
        display: 'flex',
        gap: 10,
        marginBottom: 30
    }
}

export default History
