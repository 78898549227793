import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {GlobalStateProvider} from "./global/globalState";
import {AuthProvider} from "./components/dashboard/authProvider";

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <GlobalStateProvider>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </GlobalStateProvider>
    </React.StrictMode>
)

reportWebVitals()
