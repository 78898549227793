import React, {useState} from "react";
import {
    bookingType,
    colorBookingPaid,
    colorStatus,
    colorStatusOverlay,
    EURO_VALUE,
    GLOBAL_BORDER_RADIUS,
    orderStatus
} from "../../../costants";
import {formatUnixDate} from "../../../global/dates";
import MyNormalText from "../../commons/myNormalText";
import BookingDetail from "./bookingDetail";
import {getHoverStyle} from "../../../global/style/globalButtonHoverShadowStyle";
import MyIcon from "../../commons/myIcon";
import MyToolTip from "../../commons/myToolTip";

const EXTERNAL_BORDER_RADIUS = GLOBAL_BORDER_RADIUS

function BookingRow({rentUser, booking, handleSelectChat, premiumPalette}) {

    const [openBookingDetail, setOpenBookingDetail] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    const isAnAutomaticBooking = booking.type === bookingType.SYSTEM_AUTOMATIC
    const unixDate = booking?.timeStampInsertDate?.seconds ? booking?.timeStampInsertDate?.seconds : booking?.timeStampInsertDate?._seconds
    const dateOrderTimeStamp = new Date(unixDate * 1000)

    const aDiscountWasApplied = booking.discount
    const isSingleDayRent = booking.isSingleDayRent
    const singleDaySlot = booking.orderDates.singleDaySlot

    function getBookingTextDates(orderDates) {
        if (orderDates.to) {
            return <MyNormalText text={'da ' + orderDates.from + ' a ' + orderDates.to}/>
        }
        return <MyNormalText text={'per il giorno ' + orderDates.from}/>
    }

    function handleClick() {
        setOpenBookingDetail(true)
    }

    function getBookingStyle() {
        if (booking.status === orderStatus.ACCEPTED) {
            return {...pageStyle.booking, backgroundColor: colorStatus.ACCEPTED}
        } else if (booking.status === orderStatus.REJECTED) {
            return {...pageStyle.booking, backgroundColor: colorStatus.REJECTED}
        } else if (booking.status === orderStatus.PENDING) {
            return {...pageStyle.booking, backgroundColor: colorStatus.PENDING}
        } else if (booking.status === orderStatus.REFUNDED) {
            return {...pageStyle.booking, backgroundColor: colorStatus.REFUNDED}
        } else if (booking.status === orderStatus.CANCELED_FROM_SYSTEM) {
            return {...pageStyle.booking, backgroundColor: colorStatus.CANCELED_FROM_SYSTEM}
        }
    }

    function getStatusDescription() {
        if (booking.status === orderStatus.ACCEPTED) {
            return <MyNormalText text={'Accettato'}/>
        } else if (booking.status === orderStatus.REJECTED) {
            return <MyNormalText text={'Rifiutato'}/>
        } else if (booking.status === orderStatus.PENDING) {
            return <MyNormalText text={'Da confermare'}/>
        } else if (booking.status === orderStatus.REFUNDED) {
            return <MyNormalText text={'Rimborsato'}/>
        } else if (booking.status === orderStatus.CANCELED_FROM_SYSTEM) {
            return <MyNormalText text={'Cancellato (scadute 24h)'}/>
        }
    }

    function getBackgroundOverlayFromBookingStatus() {
        if (booking.status === orderStatus.ACCEPTED) {
            return {backgroundColor: colorStatusOverlay.ACCEPTED}
        } else if (booking.status === orderStatus.REJECTED) {
            return {backgroundColor: colorStatusOverlay.REJECTED}
        } else if (booking.status === orderStatus.PENDING) {
            return {backgroundColor: colorStatusOverlay.PENDING}
        } else if (booking.status === orderStatus.REFUNDED) {
            return {backgroundColor: colorStatusOverlay.REFUNDED}
        } else if (booking.status === orderStatus.CANCELED_FROM_SYSTEM) {
            return {backgroundColor: colorStatusOverlay.CANCELED_FROM_SYSTEM}
        }
    }

    pageStyle.bookingInfoSection = {...pageStyle.bookingInfoSection, ...getBackgroundOverlayFromBookingStatus(booking.status)}

    function singleDayRentDescription() {
        return singleDaySlot.from + ' - ' + singleDaySlot.to + ' (' + singleDaySlot.description + ')'
    }

    function calculateIsPaidBookingSection() {
        function getPaidStyle() {
            const backgroundColor = booking.isPaid ? colorBookingPaid.YES : colorBookingPaid.NO
            return {...pageStyle.bookingInfoSection, backgroundColor: backgroundColor}
        }

        return (
            <MyToolTip text={!booking.isPaid ? 'Accettando l\'ordine il pagamento verrà eseguito' : 'Pagato'}
                       premiumPalette={premiumPalette}
                       isActive={isAnAutomaticBooking}>
                <div style={pageStyle.subHeaderRow}>
                    <div style={getPaidStyle()}>
                        <MyIcon icon={require("../../../imgs/booking/paid.png")}
                                name={'Paid'}
                                width={pageStyle.imageInfo.width}
                                height={pageStyle.imageInfo.height}/>
                        <div style={pageStyle.columnInfo}>
                            <MyNormalText text={'Pagato'}
                                          bold={true}/>
                            <MyNormalText text={booking.isPaid ? 'Sì' : 'No'}/>
                        </div>
                    </div>
                </div>
            </MyToolTip>
        )
    }

    return (
        <div style={getBookingStyle()}
             key={booking.bookingId}>
            <div onClick={handleClick}
                 style={{width: '100%'}}
                 onMouseEnter={() => setIsHovered(true)}
                 onMouseLeave={() => setIsHovered(false)}>
                <div style={getHoverStyle(isHovered, EXTERNAL_BORDER_RADIUS)}/>
                <div style={{...pageStyle.headerRow, ...getBackgroundOverlayFromBookingStatus()}}>
                    {isAnAutomaticBooking ?
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{marginRight: 5}}>
                                <MyNormalText text={'App LoceM'}
                                              bold={true}/>
                            </div>
                            <MyIcon icon={require("../../../imgs/booking/logo.png")}
                                    name={'logo'}
                                    width={pageStyle.imageInfo.width}
                                    height={pageStyle.imageInfo.height}/>
                        </div> :
                        <MyNormalText text={'Ordine manuale'}
                                      bold={true}/>}
                </div>
                {calculateIsPaidBookingSection()}
                <div style={pageStyle.bookingInfo}>
                    <div style={pageStyle.bookingInfoSection}>
                        <MyIcon icon={require("../../../imgs/booking/status.png")}
                                name={'Status'}
                                width={pageStyle.imageInfo.width}
                                height={pageStyle.imageInfo.height}/>
                        <div style={pageStyle.columnInfo}>
                            <MyNormalText text={'Stato'}
                                          bold={true}/>
                            {getStatusDescription()}
                        </div>
                    </div>
                    <div style={pageStyle.bookingInfoSection}>
                        <MyIcon icon={require("../../../imgs/booking/id.png")}
                                name={'Id'}
                                width={pageStyle.imageInfo.width}
                                height={pageStyle.imageInfo.height}/>
                        <div style={pageStyle.columnInfo}>
                            <MyNormalText text={'Codice ordine'}
                                          bold={true}/>
                            <MyNormalText text={booking.orderId}/>
                        </div>
                    </div>
                    <div style={pageStyle.bookingInfoSection}>
                        <MyIcon icon={require("../../../imgs/booking/user.png")}
                                name={'client'}
                                width={20}
                                height={20}/>
                        <div style={pageStyle.columnInfo}>
                            <MyNormalText text={'Ordine in arriva da'}
                                          bold={true}/>
                            <MyNormalText text={booking.client.name}/>
                        </div>
                    </div>
                    <div style={pageStyle.bookingInfoSection}>
                        <MyIcon icon={require("../../../imgs/booking/calendar.png")}
                                name={'dates'}
                                width={pageStyle.imageInfo.width}
                                height={pageStyle.imageInfo.height}/>
                        <div style={pageStyle.columnInfo}>
                            <MyNormalText text={'Date richieste'}
                                          bold={true}/>
                            <div style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                {getBookingTextDates(booking.orderDates)}
                            </div>
                        </div>
                    </div>
                    {isSingleDayRent ?
                        <div style={pageStyle.bookingInfoSection}>
                            <MyIcon icon={require("../../../imgs/booking/single-day-rent.png")}
                                    name={'single day rent'}
                                    width={pageStyle.imageInfo.width}
                                    height={pageStyle.imageInfo.height}/>
                            <div style={pageStyle.columnInfo}>
                                <MyNormalText text={'Fascia oraria'}
                                              bold={true}/>
                                <MyNormalText text={singleDayRentDescription()}/>
                            </div>
                        </div> :
                        <></>}
                    <div style={pageStyle.bookingInfoSection}>
                        <MyIcon icon={require("../../../imgs/booking/send.png")}
                                name={'sendtime'}
                                width={pageStyle.imageInfo.width}
                                height={pageStyle.imageInfo.height}/>
                        <div style={pageStyle.columnInfo}>
                            <MyNormalText text={'Inviato il'}
                                          bold={true}/>
                            <MyNormalText text={formatUnixDate(dateOrderTimeStamp)}/>
                        </div>
                    </div>
                    {aDiscountWasApplied ?
                        <div style={pageStyle.bookingInfoSection}>
                            <MyIcon icon={require("../../../imgs/booking/discount.png")}
                                    name={'total discounted'}
                                    width={pageStyle.imageInfo.width}
                                    height={pageStyle.imageInfo.height}/>
                            <div style={pageStyle.columnInfo}>
                                <MyNormalText text={'Totale scontato'}
                                              bold={true}/>
                                <MyNormalText text={EURO_VALUE + booking.discount.totalDiscounted}/>
                            </div>
                        </div> :
                        <></>}
                    <div style={pageStyle.bookingInfoSection}>
                        <MyIcon icon={require("../../../imgs/booking/detail.png")}
                                name={'total'}
                                width={pageStyle.imageInfo.width}
                                height={pageStyle.imageInfo.height}/>
                        <div style={pageStyle.columnInfo}>
                            <MyNormalText text={'Totale'}
                                          bold={true}/>
                            <MyNormalText text={EURO_VALUE + booking.total}/>
                        </div>
                    </div>
                    {booking.status === orderStatus.REFUNDED ?
                        <div style={pageStyle.bookingInfoSection}>
                            <MyIcon icon={require("../../../imgs/booking/refund.png")}
                                    name={'total-refund'}
                                    width={pageStyle.imageInfo.width}
                                    height={pageStyle.imageInfo.height}/>
                            <div style={pageStyle.columnInfo}>
                                <MyNormalText text={'Totale rimborsato'}
                                              bold={true}/>
                                <MyNormalText text={EURO_VALUE + booking.totalRefunded}/>
                            </div>
                        </div> :
                        <></>
                    }
                </div>
            </div>
            <BookingDetail rentUser={rentUser}
                           bookingId={booking.bookingId}
                           premiumPalette={premiumPalette}
                           isOpen={openBookingDetail}
                           setIsOpen={setOpenBookingDetail}
                           handleSelectChat={handleSelectChat}/>
        </div>
    )
}

const pageStyle = {
    booking: {
        display: 'flex',
        position: 'relative',
        padding: 10,
        borderRadius: EXTERNAL_BORDER_RADIUS,
        cursor: 'pointer'
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 20
    },
    subHeaderRow: {
        display: 'flex',
        marginBottom: 20
    },
    bookingInfo: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: 10
    },
    bookingInfoSection: {
        display: 'flex',
        alignSelf: 'flex-start',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    textInfo: {
        fontSize: 18
    },
    columnInfo: {
        display: "flex",
        flexDirection: 'column',
        marginLeft: 5
    },
    imageInfo: {
        height: 25,
        width: 25
    }
}

export default BookingRow
