import React, {useRef} from "react";
import MainButton from "../../commons/mainButton";
import {
    colorBookingPaid,
    colorStatus,
    EURO_VALUE,
    GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
    GLOBAL_BORDER_RADIUS,
    orderStatus
} from "../../../costants";
import Loader from "../../commons/loader";
import MyNormalText from "../../commons/myNormalText";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import {formatUnixDate} from "../../../global/dates";
import ItemBox from "../item/itemBox";
import MyIcon from "../../commons/myIcon";
import useMyClickOutListener from "../../../global/listeners/useMyClickOutListener";

function AutomaticBookingDetail({
                                    rentUser,
                                    booking,
                                    premiumPalette,
                                    acceptBooking,
                                    rejectBooking,
                                    loadingBooking,
                                    loadingAccept,
                                    loadingRejected,
                                    handleSelectChat,
                                    commentRejection,
                                    setCommentRejection,
                                    openRejectOptions,
                                    setOpenRejectOptions,
                                    setIsOpen
                                }) {

    const primaryColor = premiumPalette.primaryColor
    const primaryColorSecondAlternative = premiumPalette.primaryColorSecondAlternative
    const primaryColorFourthAlternative = premiumPalette.primaryColorFourthAlternative

    const dateOrderTimeStamp = new Date(booking?.timeStampInsertDate?._seconds * 1000)
    const bookingDetailRef = useRef()

    const aDiscountWasApplied = booking.discount
    const isSingleDayRent = booking.isSingleDayRent
    const singleDaySlot = booking.orderDates?.singleDaySlot

    useMyClickOutListener(bookingDetailRef, () => setIsOpen(false))

    function getStatus() {
        if (booking.status === orderStatus.PENDING) {
            return (
                <div style={{...pageStyle.bookingInfo, backgroundColor: colorStatus.PENDING}}>
                    <MyIcon icon={require("../../../imgs/booking/status.png")}
                            name={'total-refund'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText bold={true}
                                      text={'Stato'}/>
                        <MyNormalText text={'Da confermare'}/>
                    </div>
                </div>
            )
        } else if (booking.status === orderStatus.ACCEPTED) {
            return (
                <div style={{...pageStyle.bookingInfo, backgroundColor: colorStatus.ACCEPTED}}>
                    <MyIcon icon={require("../../../imgs/booking/status.png")}
                            name={'total-refund'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText bold={true}
                                      text={'Stato'}/>
                        <MyNormalText text={'Accettato'}/>
                    </div>
                </div>
            )
        } else if (booking.status === orderStatus.REJECTED) {
            return (
                <div style={{...pageStyle.bookingInfo, backgroundColor: colorStatus.REJECTED}}>
                    <MyIcon icon={require("../../../imgs/booking/status.png")}
                            name={'total-refund'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText bold={true}
                                      text={'Stato'}/>
                        <MyNormalText text={'Rifiutato'}/>
                        <MyNormalText text={'Motivazione: ' + booking.motivationRejection}/>
                    </div>
                </div>
            )
        } else if (booking.status === orderStatus.REFUNDED) {
            return (
                <div style={{...pageStyle.bookingInfo, backgroundColor: colorStatus.REFUNDED}}>
                    <MyIcon icon={require("../../../imgs/booking/status.png")}
                            name={'total-refund'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText bold={true}
                                      text={'Stato'}/>
                        <MyNormalText text={'Rimborsato'}/>
                    </div>
                </div>
            )
        } else if (booking.status === orderStatus.CANCELED_FROM_SYSTEM) {
            return (
                <div style={{...pageStyle.bookingInfo, backgroundColor: colorStatus.CANCELED_FROM_SYSTEM}}>
                    <MyIcon icon={require("../../../imgs/booking/status.png")}
                            name={'total-refund'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText bold={true}
                                      text={'Stato'}/>
                        <MyNormalText text={'Cancellato (scadute 24h)'}/>
                    </div>
                </div>
            )
        }
    }

    function getOrderDates() {
        if (booking.orderDates.to) {
            return (
                <div
                    style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                    <MyIcon icon={require("../../../imgs/booking/calendar.png")}
                            name={'dates'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText bold={true}
                                      text={'Date richieste'}/>
                        <MyNormalText text={'da ' + booking.orderDates.from + ' a ' + booking.orderDates.to}/>
                    </div>
                </div>
            )
        }

        return (
            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                <MyIcon icon={require("../../../imgs/booking/calendar.png")}
                        name={'dates'}
                        width={pageStyle.imageInfo.width}
                        height={pageStyle.imageInfo.height}/>
                <div style={pageStyle.columnInfo}>
                    <MyNormalText bold={true}
                                  text={'Data richiesta'}/>
                    <MyNormalText text={booking.orderDates.from}/>
                </div>
            </div>
        )
    }

    const textAreaInputStyle = {
        ...globalElementInputStyle.customInput,
        resize: 'none',
        height: 100
    }

    function openChat() {
        setIsOpen(false)
        handleSelectChat(booking.chatId)
    }

    function createSlotDescription() {
        return singleDaySlot?.from + ' - ' + singleDaySlot?.to + ' (' + singleDaySlot?.description + ')'
    }

    function calculateIsPaidBookingSection() {
        function getPaidStyle() {
            const backgroundColor = booking.isPaid ? colorBookingPaid.YES : colorBookingPaid.NO
            return {...pageStyle.bookingInfo, backgroundColor: backgroundColor}
        }

        return (
            <div style={pageStyle.subHeaderRow}>
                <div style={getPaidStyle()}>
                    <MyIcon icon={require("../../../imgs/booking/paid.png")}
                            name={'Paid'}
                            width={pageStyle.imageInfo.width}
                            height={pageStyle.imageInfo.height}/>
                    <div style={pageStyle.columnInfo}>
                        <MyNormalText text={'Pagato'}
                                      bold={true}/>
                        <MyNormalText text={booking.isPaid ? 'Sì' : 'No'}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingBooking ?
                <Loader absoluteFullPage={true}/> :
                <div ref={bookingDetailRef}
                     style={pageStyle.container}>
                    <div style={{...pageStyle.booking, backgroundColor: primaryColorSecondAlternative}}>
                        <div style={{...pageStyle.headerRow, backgroundColor: primaryColor}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{marginRight: 10}}>
                                    <MyNormalText text={'App LoceM'}
                                                  bold={true}/>
                                </div>
                                <MyIcon icon={require("../../../imgs/booking/logo.png")}
                                        name={'Logo'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                            </div>
                        </div>
                        {calculateIsPaidBookingSection()}
                        <div style={pageStyle.bookingInfoContainer}>
                            {getStatus()}
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/id.png")}
                                        name={'id'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText bold={true}
                                                  text={'Codice ordine'}/>
                                    <MyNormalText text={booking.orderId}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/user.png")}
                                        name={'user'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText bold={true}
                                                  text={'Ordine di'}/>
                                    <MyNormalText text={booking.client.name}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/email.png")}
                                        name={'email'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText bold={true}
                                                  text={'E-Mail'}/>
                                    <MyNormalText text={booking.client.email}/>
                                </div>
                            </div>
                            {getOrderDates()}
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/send.png")}
                                        name={'senttime'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText bold={true}
                                                  text={'Inviato il'}/>
                                    <MyNormalText text={formatUnixDate(dateOrderTimeStamp)}/>
                                </div>
                            </div>
                            {isSingleDayRent ?
                                <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                    <MyIcon icon={require("../../../imgs/booking/single-day-rent.png")}
                                            name={'dates'}
                                            width={pageStyle.imageInfo.width}
                                            height={pageStyle.imageInfo.height}/>
                                    <div style={pageStyle.columnInfo}>
                                        <MyNormalText text={'Fascia oraria'}
                                                      bold={true}/>
                                        <MyNormalText text={createSlotDescription()}/>
                                    </div>
                                </div> :
                                <></>}
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/time.png")}
                                        name={'pickup'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText bold={true}
                                                  text={'Orario ritiro'}/>
                                    <MyNormalText text={booking.arrivalTime.time}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/language.png")}
                                        name={'language'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText bold={true}
                                                  text={'Lingua'}/>
                                    <MyNormalText text={booking.language.toUpperCase()}/>
                                </div>
                            </div>
                            {aDiscountWasApplied ?
                                <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                    <MyIcon icon={require("../../../imgs/booking/discount.png")}
                                            name={'discount'}
                                            width={pageStyle.imageInfo.width}
                                            height={pageStyle.imageInfo.height}/>
                                    <div style={pageStyle.columnInfo}>
                                        <MyNormalText bold={true}
                                                      text={'Sconto applicato'}/>
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            <MyNormalText text={booking.discount.codeApplied}/>
                                            <MyNormalText text={'(-' + booking.discount.percentageToApply + '%)'}/>
                                        </div>
                                    </div>
                                </div> :
                                <></>}
                            {aDiscountWasApplied ?
                                <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                    <MyIcon icon={require("../../../imgs/booking/discount.png")}
                                            name={'discount'}
                                            width={pageStyle.imageInfo.width}
                                            height={pageStyle.imageInfo.height}/>
                                    <div style={pageStyle.columnInfo}>
                                        <MyNormalText bold={true}
                                                      text={'Totale scontato'}/>
                                        <MyNormalText text={'€ ' + booking.discount.totalDiscounted}/>
                                    </div>
                                </div> :
                                <></>}
                            <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                <MyIcon icon={require("../../../imgs/booking/detail.png")}
                                        name={'total'}
                                        width={pageStyle.imageInfo.width}
                                        height={pageStyle.imageInfo.height}/>
                                <div style={pageStyle.columnInfo}>
                                    <MyNormalText bold={true}
                                                  text={'Totale'}/>
                                    <MyNormalText text={EURO_VALUE + ' ' + booking.total}/>
                                </div>
                            </div>
                            {booking.status === orderStatus.REFUNDED ?
                                <div style={{...pageStyle.bookingInfo, backgroundColor: primaryColorFourthAlternative}}>
                                    <MyIcon icon={require("../../../imgs/booking/refund.png")}
                                            name={'total-refund'}
                                            width={pageStyle.imageInfo.width}
                                            height={pageStyle.imageInfo.height}/>
                                    <div style={pageStyle.columnInfo}>
                                        <MyNormalText bold={true}
                                                      text={'Totale rimborsato'}/>
                                        <MyNormalText text={EURO_VALUE + ' ' + booking.totalRefunded}/>
                                    </div>
                                </div> : <></>}
                        </div>
                        <div style={pageStyle.containerItems}>
                            {booking.items?.map(item => {
                                return (
                                    <div key={item.item.id}
                                         style={{marginRight: 10, marginBottom: 10}}>
                                        <ItemBox rentUser={rentUser}
                                                 premiumPalette={premiumPalette}
                                                 item={item.item}
                                                 hidePrices={true}
                                                 openOperations={false}/>
                                    </div>
                                )
                            })}
                        </div>
                        {booking.status === orderStatus.ACCEPTED && handleSelectChat ?
                            <MainButton image={require('../../../imgs/actions/chat.png')}
                                        action={() => openChat()}
                                        backgroundColor={premiumPalette.secondaryColor}/> :
                            <></>}
                        {booking.status === orderStatus.PENDING ?
                            <div style={pageStyle.actionButtonsContainer}>
                                <div style={pageStyle.buttonLeft}>
                                    <MainButton text={'Rifiuta'}
                                                action={() => setOpenRejectOptions(!openRejectOptions)}
                                                loading={loadingRejected}
                                                disabled={loadingRejected || loadingAccept}
                                                backgroundColor={colorStatus.REJECTED}/>
                                </div>
                                <div style={pageStyle.buttonRight}>
                                    <MainButton text={'Accetta'}
                                                action={acceptBooking}
                                                loading={loadingAccept}
                                                disabled={loadingRejected || loadingAccept}
                                                backgroundColor={colorStatus.ACCEPTED}/>
                                </div>
                            </div> : <></>}
                        {openRejectOptions ?
                            <div style={pageStyle.rejectOptions}>
                                <div style={pageStyle.margin10}>
                                        <textarea style={textAreaInputStyle}
                                                  value={commentRejection}
                                                  placeholder={'Scrivi un messaggio...'}
                                                  onChange={(e) => setCommentRejection(e.target.value)}
                                                  rows={commentRejection.split('\n').length}/>
                                </div>
                                <MainButton text={'Invia'}
                                            action={rejectBooking}
                                            backgroundColor={premiumPalette.secondaryColor}/>
                            </div> :
                            <></>}
                    </div>
                </div>}
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
        overflowY: 'scroll'
    },
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        transform: 'translate(-50%, 0)',
        width: 'calc(100% / 2)',
        zIndex: 1
    },
    booking: {
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 20
    },
    containerItems: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 20
    },
    subHeaderRow: {
        display: 'flex',
        marginBottom: 20
    },
    bookingInfoContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: 10
    },
    bookingInfo: {
        display: 'flex',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    rejectOptions: {
        display: 'flex',
        flexDirection: 'column'
    },
    margin10: {
        marginTop: 10
    },
    buttonLeft: {
        width: '100%',
        marginRight: 10
    },
    buttonRight: {
        width: '100%',
        marginLeft: 10
    },
    actionButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10
    },
    imageInfo: {
        height: 24,
        width: 24
    },
    columnInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 5
    }
}

export default AutomaticBookingDetail
