export function getHoverStyle(isHovered, borderRadius) {
    return {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        opacity: isHovered ? 1 : 0,
        transition: 'opacity 0.2s ease',
        borderRadius: borderRadius
    }
}
