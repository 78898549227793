import React, {useEffect, useRef, useState} from "react";
import {GLOBAL_BORDER_RADIUS, GLOBAL_SHADOW_SUPER_ACCENTUATED} from "../../../costants";
import {collection, onSnapshot, orderBy, query} from "firebase/firestore";
import {db, functions} from "../../../firebase";
import MyNormalText from "../../commons/myNormalText";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import {httpsCallable} from "firebase/functions";
import BookingDetail from "../booking/bookingDetail";
import MainButton from "../../commons/mainButton";
import Loader from "../../commons/loader";

const NEW_MESSAGE_MAX_LENGTH = 400

function ChatDetail({rentUser, premiumPalette, chat, isActive, handleCloseChat}) {

    const secondaryColor = premiumPalette.secondaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative
    const primaryColorFourthAlternative = premiumPalette.primaryColorFourthAlternative

    const chatBody = chat.body
    const [messages, setMessages] = useState([])
    const newMessageInitialValue = ''
    const [newMessage, setNewMessage] = useState(newMessageInitialValue)
    const [loadingSendingMessage, setLoadingSendingMessage] = useState(false)

    const [bookingIdSelected, setBookingIdSelected] = useState(null)
    const [openBookingDetail, setOpenBookingDetail] = useState(false)

    const messagesEndRef = useRef(null)

    useEffect(() => {
        const unsubscribeToChats = subscribeToNewChatMessages()
        if (isActive) {
            scrollToBottom()
        }
        return () => unsubscribeToChats()
    }, [isActive])

    useEffect(() => {
        if (!loadingSendingMessage) {
            scrollToBottom()
        }
    }, [loadingSendingMessage])

    if (!isActive) {
        return null
    }

    function subscribeToNewChatMessages() {
        const q = query(
            collection(db, "rent_app_my_chats", rentUser.rentId, "chats", chat.chatId, "messages"),
            orderBy('timeStampInsertDate', 'asc'))
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const messages = []
            querySnapshot.forEach((doc) => messages.push(doc.data()))
            setMessages(messages)
        })
        return () => unsubscribe()
    }

    function scrollToBottom() {
        setTimeout(() => {
            messagesEndRef?.current?.scrollIntoView({behavior: 'smooth'})
        }, 200)
    }

    const handleSendMessage = (event) => {
        if (event.key === 'Enter') {
            if (newMessage === newMessageInitialValue || newMessage.length > NEW_MESSAGE_MAX_LENGTH) {
                return
            }

            event.preventDefault()
            setNewMessage((prevValue) => prevValue + '\n')

            const createRentAppChatMessage = httpsCallable(functions, 'createRentAppChatMessage')
            const newMessageRequest = {
                chatId: chat.chatId,
                sender: rentUser.rentId,
                recipient: chatBody.client.uid,
                message: newMessage
            }
            setLoadingSendingMessage(true)
            setNewMessage(newMessageInitialValue)
            createRentAppChatMessage(newMessageRequest)
                .then(() => setLoadingSendingMessage(false))
                .catch(error => {
                    setLoadingSendingMessage(false)
                    if (error.message === 'too many messages') {
                        alert('Hai raggiunto il limite massimo di messaggi per questa chat.\nContatta il negozio direttamente.')
                    }
                })
        }
    }

    function formatTimestamp(timeStampInsertDate) {
        const seconds = timeStampInsertDate.seconds
        const nanoseconds = timeStampInsertDate.nanoseconds
        const date = new Date(seconds * 1000 + Math.floor(nanoseconds / 1000000))
        return date.toLocaleTimeString('it-IT') + ' - ' + date.toLocaleDateString('it-IT')
    }

    function handleClickOnBooking(chat) {
        setBookingIdSelected(chat.body.bookingId)
        setOpenBookingDetail(true)
    }


    const handleChange = (event) => {
        setNewMessage(event.target.value)
    }

    const textAreaStyle = {
        ...globalElementInputStyle.customInput,
        resize: 'none',
        height: 80
    }

    return (
        <div style={{...pageStyle.container, backgroundColor: primaryColorFourthAlternative}}>
            <BookingDetail rentUser={rentUser}
                           premiumPalette={premiumPalette}
                           bookingId={bookingIdSelected}
                           isOpen={openBookingDetail}
                           setIsOpen={setOpenBookingDetail}/>
            <div style={{display: 'flex'}}>
                <div style={{flex: 12, marginRight: 10}}>
                    <MainButton
                        text={'Ordine ' + ((chatBody.orderDates.to ? ' da ' : ' il ') + chatBody.orderDates.from) + (chatBody.orderDates.to ? ', fino a ' + chatBody.orderDates.to : '')}
                        action={() => handleClickOnBooking(chat)}
                        backgroundColor={secondaryColor}
                        secondaryImage={require("../../../imgs/actions/link.png")}
                        secondaryImageHeight={20}
                        secondaryImageWidth={20}/>
                </div>
                <div style={{flex: 1}}>
                    <MainButton image={require("../../../imgs/actions/minus.png")}
                                imageHeight={15}
                                imageWidth={15}
                                action={() => handleCloseChat(chat)}/>
                </div>
            </div>
            <div style={pageStyle.messagesContainer}>
                {messages.map((message, index) => {
                    const isRentMessage = message.sender === rentUser.rentId
                    const messageContainerStyle = {
                        ...pageStyle.messageContainer,
                        borderTop: index === 0 ? undefined : '1px solid ' + primaryColorFirstAlternative
                    }
                    const firstLetterMessageContainerStyle = {
                        ...pageStyle.firstLetterMessageContainer,
                        backgroundColor: isRentMessage ? primaryColorFirstAlternative : secondaryColor
                    }
                    return (
                        <div ref={messagesEndRef}
                             key={message.messageId}
                             style={messageContainerStyle}>
                            <div style={firstLetterMessageContainerStyle}>
                                <MyNormalText
                                    text={isRentMessage ? rentUser.name.charAt(0) : chatBody.client.name.charAt(0)}/>
                            </div>
                            <div style={pageStyle.bodyMessageContainer}>
                                <MyNormalText text={isRentMessage ? rentUser.name : chatBody.client.name}
                                              bold={true}/>
                                <MyNormalText text={formatTimestamp(message.timeStampInsertDate)}
                                              fontSize={14}/>
                                <div style={{marginTop: 5}}>
                                    <MyNormalText text={message.message}/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div style={pageStyle.footerInfoContainer}>
                <MyNormalText text={'Lingua: ' + chat.language}/>
                {loadingSendingMessage ?
                    <Loader/> :
                    <></>}
                <MyNormalText text={newMessage.length + '/' + NEW_MESSAGE_MAX_LENGTH}
                              color={newMessage.length > NEW_MESSAGE_MAX_LENGTH ? 'red' : undefined}/>
            </div>
            <div>
                <textarea style={textAreaStyle}
                          value={newMessage}
                          placeholder={'Scrivi un messaggio...'}
                          onKeyDown={handleSendMessage}
                          onChange={handleChange}
                          rows={newMessage.split('\n').length}/>
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        padding: 10,
        bottom: 65,
        borderRadius: GLOBAL_BORDER_RADIUS,
        height: window.innerHeight / 1.4,
        width: 500,
        boxShadow: GLOBAL_SHADOW_SUPER_ACCENTUATED
    },
    messagesContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginBottom: 10,
        overflowY: 'auto',
        scrollbarWidth: 'none'
    },
    messageContainer: {
        display: 'grid',
        gridTemplateColumns: '40px 1fr',
        paddingTop: 10,
        paddingBottom: 10
    },
    bodyMessageContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    firstLetterMessageContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        borderRadius: 40,
        width: 20,
        height: 20
    },
    footerInfoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 5
    }
}

export default ChatDetail
