import React, {useEffect, useRef, useState} from "react";
import {EURO_VALUE, GLOBAL_BACKGROUND_POPUP_ABSOLUTE, GLOBAL_BORDER_RADIUS, premiumDataType} from "../../../costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import Loader from "../../commons/loader";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MyNormalText from "../../commons/myNormalText";
import {Bar, Doughnut, Pie} from 'react-chartjs-2';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    RadialLinearScale,
    Title,
    Tooltip
} from "chart.js";
import {
    calculateBarChartTimeline,
    calculateBarChatTimelinePerMonth,
    calculateItemBookingsVsCosts,
    calculateItemCosts
} from "../../../global/premiumDataDetail";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";
import useMyClickOutListener from "../../../global/listeners/useMyClickOutListener";

ChartJS.register(CategoryScale, LinearScale, LineElement, BarElement, PointElement, Title, Tooltip, Legend, RadialLinearScale, ArcElement)

const borderCharColor = ['rgba(255, 255, 255, 0.6)']

function ItemsPremiumDataMultipleDetail({rentUser, items, premiumPalette, setIsOpen}) {

    const primaryColor = premiumPalette.primaryColor
    const primaryColorSecondAlternative = premiumPalette.primaryColorSecondAlternative

    const [itemsPremiumData, setItemsPremiumData] = useState([])
    const [triggerCalculateAmounts, settriggerCalculateAmounts] = useState(false)
    const [loadingItemsPremiumData, setLoadingItemsPremiumData] = useState(true)
    const [barChartTimeline, setBarChartTimeline] = useState([])
    const [barChartTimelinePerMonth, setBarChartTimelinePerMonth] = useState([])
    const [manualCosts, setManualCosts] = useState([])
    const [bookingsVsCosts, setBookingsVsCosts] = useState([])

    const [amountProduced, setAmountProduced] = useState(0)
    const [manualAndLoceMBookingAmount, setManualAndLoceMBookingAmount] = useState(0)
    const [manualInsertCostAmount, setManualInsertCostAmount] = useState(0)

    const itemDetailRef = useRef()

    const [callCount, setCallCount] = useState(0)

    useEffect(() => {
        let valueAmountProduced = 0
        let valueManualAndLoceMBookingAmount = 0
        let valueManualInsertCostAmount = 0
        if (itemsPremiumData.length > 0) {
            itemsPremiumData.forEach(value => {
                if (value.type === premiumDataType.MANUAL_ACCEPTED_BOOKING || value.type === premiumDataType.APP_LOCEM_ACCEPTED_BOOKING) {
                    valueManualAndLoceMBookingAmount += value.amount
                } else if (value.type === premiumDataType.MANUAL_INSERT_COST) {
                    valueManualInsertCostAmount += value.amount
                }
                valueAmountProduced += value.amount
            })
            setAmountProduced(amountProduced + valueAmountProduced)
            setManualAndLoceMBookingAmount(manualAndLoceMBookingAmount + valueManualAndLoceMBookingAmount)
            setManualInsertCostAmount(manualInsertCostAmount + valueManualInsertCostAmount)
        }
    }, [triggerCalculateAmounts])

    useEffect(() => {
        const sleep = (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms))
        }
        const fetchSequentially = async () => {
            const results = []
            for (const item of items) {
                setCallCount(prevCount => prevCount + 1)
                try {
                    const premiumData = await getItemPremiumData(item)
                    results.push(...premiumData)

                    await sleep(150)
                } catch (error) {
                    alert('Ooops! Qualcosa sembra essere andato storto.')
                }
            }
            return results
        }

        fetchSequentially()
            .then((itemsPremiumDataMerged) => {
                setItemsPremiumData(itemsPremiumDataMerged)
                setBarChartTimeline(calculateBarChartTimeline(itemsPremiumDataMerged))
                setBarChartTimelinePerMonth(calculateBarChatTimelinePerMonth(itemsPremiumDataMerged))
                calculateItemCosts(itemsPremiumDataMerged)
                    .map(values => setManualCosts(values))
                setBookingsVsCosts(calculateItemBookingsVsCosts(itemsPremiumDataMerged))
                settriggerCalculateAmounts(!triggerCalculateAmounts)
                setLoadingItemsPremiumData(false)
            })
    }, [])

    async function getItemPremiumData(item) {
        const request = {
            rentId: rentUser.rentId,
            itemId: item.id
        }
        return await httpsCallable(functions, 'rentItemDetailPremiumDataView')(request)
            .then((response) => response.data.premiumItemData)
    }

    useMyEscapeListener(() => setIsOpen(false))
    useMyClickOutListener(itemDetailRef, () => setIsOpen(false))

    function itemPolarChartView() {
        if (manualCosts.length === 0) {
            return
        }

        const chartData = {
            labels: manualCosts.map(value => value.description),
            datasets: [
                {
                    label: 'Totale',
                    data: manualCosts.map(value => Math.abs(value.amount)),
                    backgroundColor: ['#FF5733', '#33FF57', '#3357FF', '#FF33A6', '#FFB833', '#8D33FF', '#33FFF6', '#FF3333',
                        '#33FFA6', '#FFD633', '#FF33F6', '#33A6FF', '#D633FF', '#33FF8D', '#FF5733',
                        '#5733FF', '#A6FF33', '#FF33D6', '#33FFD6', '#FFA633'],
                    borderColor: borderCharColor,
                    borderWidth: 1
                }
            ]
        }

        const options = {
            responsive: true,
            plugins: {legend: {position: 'top'}, tooltip: {enabled: true}},
            cutout: '40%'
        }

        return <Doughnut data={chartData}
                         options={options}/>
    }

    function itemPieChartBookingsVsCostsView() {
        const chartData = {
            labels: bookingsVsCosts.map(value => value.description),
            datasets: [
                {
                    label: 'Percentuale %',
                    data: bookingsVsCosts.map(value => value.percentage),
                    backgroundColor: ['rgb(81,192,74)', 'rgb(223,0,0)'],
                    borderColor: borderCharColor,
                    borderWidth: 1
                }
            ]
        }

        return <Pie data={chartData}/>
    }

    function itemBarChartView() {
        if (barChartTimeline.length === 0) {
            return
        }

        const chartData = {
            labels: barChartTimeline.map(value => value.timestamp.toLocaleDateString('it-IT')),
            datasets: [
                {
                    label: 'Totale',
                    data: barChartTimeline.map(entry => entry.amount),
                    backgroundColor: barChartTimeline.map(entry => entry.amount > 0 ? 'rgb(81,192,74)' : 'rgb(223,0,0)'),
                    borderColor: borderCharColor,
                    borderWidth: 1
                }
            ]
        }

        const options = {
            responsive: true,
            plugins: {
                legend: {position: 'top'},
                title: {display: true, text: 'Andamento articolo per giorno'}
            },
            scales: {y: {beginAtZero: true}}
        }

        return <Bar data={chartData}
                    options={options}/>
    }

    function itemBarChartPerMonthView() {
        if (barChartTimelinePerMonth.length === 0) {
            return
        }

        const chartData = {
            labels: barChartTimelinePerMonth.map(value => value.month),
            datasets: [
                {
                    label: 'Totale',
                    data: barChartTimelinePerMonth.map(entry => entry.amount),
                    backgroundColor: barChartTimelinePerMonth.map(entry => entry.amount > 0 ? 'rgb(81,192,74)' : 'rgb(223,0,0)'),
                    borderColor: borderCharColor,
                    borderWidth: 1
                }
            ]
        }

        const options = {
            responsive: true,
            plugins: {
                legend: {position: 'top'},
                title: {display: true, text: 'Andamento articolo per mese'}
            },
            scales: {y: {beginAtZero: true}}
        }

        return <Bar data={chartData}
                    options={options}/>
    }

    const subSection = {...pageStyle.section, ...{flex: 1, backgroundColor: primaryColorSecondAlternative}}

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingItemsPremiumData ?
                <Loader absoluteFullPage={true}
                        absoluteFullPageText={callCount + ' su ' + items.length}/> :
                <div ref={itemDetailRef}
                     style={pageStyle.container}>
                    <div
                        style={{...pageStyle.generalInfoContainer, ...{backgroundColor: primaryColorSecondAlternative}}}>
                        <MyNormalText text={'Rendimento'}
                                      bold={true}/>
                        <div style={pageStyle.generalInfoSubContainer}>
                            <div style={{...pageStyle.generalInfoSectionContainer, ...{backgroundColor: primaryColor}}}>
                                <MyNormalText text={'Ordini'}
                                              bold={true}/>
                                <MyNormalText text={EURO_VALUE + ' ' + manualAndLoceMBookingAmount}/>
                            </div>
                            <div style={{...pageStyle.generalInfoSectionContainer, ...{backgroundColor: primaryColor}}}>
                                <MyNormalText text={'Costi'}
                                              bold={true}/>
                                <MyNormalText text={EURO_VALUE + ' ' + manualInsertCostAmount}
                                              color={manualInsertCostAmount < 0 ? 'red' : undefined}/>
                            </div>
                            <div style={{...pageStyle.generalInfoSectionContainer, ...{backgroundColor: primaryColor}}}>
                                <MyNormalText text={'Totale'}
                                              bold={true}/>
                                <MyNormalText text={EURO_VALUE + ' ' + amountProduced}
                                              color={amountProduced < 0 ? 'red' : undefined}/>
                            </div>
                        </div>
                    </div>
                    <div style={{...pageStyle.section, ...{backgroundColor: primaryColorSecondAlternative}}}>
                        <MyNormalText text={'Entrate e spese per giorno'}
                                      bold={true}/>
                        {itemBarChartView()}
                    </div>
                    <div style={{...pageStyle.section, ...{backgroundColor: primaryColorSecondAlternative}}}>
                        <MyNormalText text={'Entrate e spese per mese'}
                                      bold={true}/>
                        {itemBarChartPerMonthView()}
                    </div>
                    <div style={pageStyle.containerSubSection}>
                        <div style={subSection}>
                            <MyNormalText text={'Costi'}
                                          bold={true}/>
                            {itemPolarChartView()}
                        </div>
                        <div style={subSection}>
                            <MyNormalText text={'% Ordini & Costi'}
                                          bold={true}/>
                            {itemPieChartBookingsVsCostsView()}
                        </div>
                    </div>
                </div>}
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 100,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
        overflowY: 'auto',
    },
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        transform: 'translate(-50%, 0)',
        width: 'calc(100% / 2)'
    },
    containerSubSection: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: 20
    },
    containerCostsList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
        gap: 10,
        marginTop: 10
    },
    containerCost: {
        display: 'flex',
        borderRadius: GLOBAL_BORDER_RADIUS,
    },
    section: {
        padding: 10,
        marginBottom: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    sectionCosts: {
        padding: 10,
        marginBottom: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    subSection: {
        marginTop: 20
    },
    generalInfoContainer: {
        padding: 10,
        marginBottom: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    generalInfoSubContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: 10
    },
    generalInfoSectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default ItemsPremiumDataMultipleDetail
