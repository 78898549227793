import React, {useEffect, useState} from "react";
import ButtonsToOrderItems from "./buttonsToOrderItems";
import ItemPremiumDataRow from "./itemPremiumDataRow";
import MainButton from "../../commons/mainButton";
import _ from "lodash";
import ItemsPremiumDataMultipleDetail from "./ItemsPremiumDataMultipleDetail";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";

function ItemsPremiumData({items, itemsPremiumData, setItemsPremiumData, premiumPalette, setItems, rentUser}) {

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor

    const [triggerRefreshPremiumData, setTriggerRefreshPremiumData] = useState(false)

    const [openCategories, setOpenCategories] = useState(false)
    const [allItemsSelected, setAllItemsSelected] = useState(false)
    const [itemsSelected, setItemsSelected] = useState([])
    const categorySelectedInitialState = ''
    const [filterCategorySelected, setFilterCategorySelected] = useState(categorySelectedInitialState)
    const [openMultiplePremiumDataDetail, setOpenMultiplePremiumDataDetail] = useState(false)

    useEffect(() => {
        const rentItemsPremiumDataListView = httpsCallable(functions, 'rentItemsPremiumDataListView')
        rentItemsPremiumDataListView({rentId: rentUser.rentId})
            .then((response) => {
                const premiumItemsData = response.data.premiumItemsData
                setItemsPremiumData(premiumItemsData)
            })
            .catch(() => alert('Ooops! Sembra esserci stato un errore.'))
    }, [triggerRefreshPremiumData])

    function handleSelectItem(item) {
        setItemsSelected([...itemsSelected, ...[item]])
    }

    function handleRemoveItem(item) {
        setAllItemsSelected(false)
        setFilterCategorySelected(categorySelectedInitialState)
        itemsSelected.splice(_.indexOf(itemsSelected, _.find(itemsSelected, {id: item.id})), 1)
        setItemsSelected([...itemsSelected])
    }

    function handleSelectAll() {
        if (allItemsSelected) {
            setAllItemsSelected(false)
            setFilterCategorySelected(categorySelectedInitialState)
            setItemsSelected([])
        } else {
            setAllItemsSelected(true)
            setFilterCategorySelected(categorySelectedInitialState)
            setItemsSelected([...items])
        }
    }

    function handleSelectByCategory(category) {
        setAllItemsSelected(false)
        if (filterCategorySelected === category) {
            setFilterCategorySelected(categorySelectedInitialState)
            setItemsSelected([])
        } else {
            setFilterCategorySelected(category)
            setItemsSelected([...items.filter(item => item.category === category)])
        }
    }

    return (
        <div>
            <div style={{marginBottom: 20}}>
                <div style={pageStyle.containerButtons}>
                    <div style={{marginRight: 5}}>
                        <MainButton text={'Tutti'}
                                    height={30}
                                    action={() => handleSelectAll()}
                                    backgroundColor={allItemsSelected ? secondaryColor : primaryColor}/>
                    </div>
                    <div>
                        <MainButton
                            image={openCategories ? require('../../../imgs/actions/arrow-left.png') : require('../../../imgs/actions/arrow-right.png')}
                            action={() => setOpenCategories(!openCategories)}
                            backgroundColor={primaryColor}
                            height={30}
                            imageHeight={20}
                            imageWidth={20}/>
                    </div>
                    {openCategories ?
                        <div style={pageStyle.containerFiltersButtons}>
                            {rentUser.categories.map(category => {
                                return (
                                    <div key={category}
                                         style={pageStyle.containerButton}>
                                        <MainButton text={category}
                                                    height={30}
                                                    action={() => handleSelectByCategory(category)}
                                                    backgroundColor={filterCategorySelected === category ? secondaryColor : primaryColor}/>
                                    </div>
                                )
                            })}
                        </div> :
                        <></>}
                </div>
                {itemsSelected.length > 0 ?
                    <div style={{display: 'flex', alignSelf: 'flex-start'}}>
                        <MainButton text={'Calcola statistiche per ' + itemsSelected.length + ' articoli'}
                                    action={() => setOpenMultiplePremiumDataDetail(true)}
                                    backgroundColor={primaryColor}/>
                    </div> :
                    <></>}
            </div>
            <div style={{marginBottom: 10}}>
                <ButtonsToOrderItems items={items}
                                     setItems={setItems}
                                     premiumPalette={premiumPalette}
                                     premiumDataButtons={true}
                                     buttonsColor={primaryColor}/>
            </div>
            <div style={pageStyle.containerItemsPremiumData}>
                {items.map(item => {
                    const premiumData = itemsPremiumData.filter(itemPremiumData => item.id === itemPremiumData.itemId)
                    return (
                        <div key={item.id}>
                            <ItemPremiumDataRow item={item}
                                                isThisItemSelected={itemsSelected.find(itemSelected => itemSelected.id === item.id)}
                                                rentUser={rentUser}
                                                premiumPalette={premiumPalette}
                                                handleSelectItem={handleSelectItem}
                                                handleRemoveItem={handleRemoveItem}
                                                premiumData={premiumData}
                                                triggerRefreshData={triggerRefreshPremiumData}
                                                setTriggerRefreshData={setTriggerRefreshPremiumData}/>
                        </div>
                    )
                })}
            </div>
            {openMultiplePremiumDataDetail ?
                <ItemsPremiumDataMultipleDetail rentUser={rentUser}
                                                items={itemsSelected}
                                                premiumPalette={premiumPalette}
                                                setIsOpen={setOpenMultiplePremiumDataDetail}/> :
                <></>}
        </div>
    )
}

const pageStyle = {
    containerItemsPremiumData: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: 20
    },
    containerButtons: {
        display: 'flex',
        marginBottom: 10
    },
    containerFiltersButtons: {
        display: 'flex',
        marginLeft: 5
    },
    containerButton: {
        marginLeft: 5
    }
}

export default ItemsPremiumData
