import React, {useRef, useState} from "react";
import {GLOBAL_BACKGROUND_POPUP_ABSOLUTE, GLOBAL_BORDER_RADIUS} from "../../../costants";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MyNormalText from "../../commons/myNormalText";
import MainButton from "../../commons/mainButton";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import DatePicker from "react-datepicker";
import {toDDMMYYYYDate, toMomentDate} from "../../../global/dates";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";
import useMyClickOutListener from "../../../global/listeners/useMyClickOutListener";

function ItemCostsPopup({item, rentId, premiumPalette, setOpen, handleReloadData}) {

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative

    const [descriptionSelected, setDescriptionSelected] = useState(null)
    const [eventDateSelected, setEventDateSelected] = useState(null)
    const [priceForThisCost, setPriceForThisCost] = useState(null)
    const [loading, setLoading] = useState(false)

    const itemCostsPopupRef = useRef()

    const isSaveCostDisabled = !descriptionSelected || !priceForThisCost || !eventDateSelected

    const costsDescription = [
        "Manutenzione regolare",
        "Sostituzione delle parti usurate",
        "Batterie",
        "Assicurazione e registrazioni",
        "Accessori aggiuntivi",
        "Riparazioni accidentali",
        "Servizi professionali",
        "Upgrade e personalizzazioni",
        "Costi di stoccaggio",
        "Trasporto"
    ]

    useMyEscapeListener(() => setOpen(false))
    useMyClickOutListener(itemCostsPopupRef, () => setOpen(false))

    function handleInsertCost(cost) {
        setPriceForThisCost(Math.abs(cost))
    }

    function handleSaveCost() {
        setLoading(true)
        const rentCreatePremiumDataCommand = httpsCallable(functions, 'rentCreatePremiumDataCommand')
        rentCreatePremiumDataCommand({
            itemId: item.id,
            rentId: rentId,
            description: descriptionSelected,
            amount: -Math.abs(priceForThisCost),
            eventDate: toDDMMYYYYDate(toMomentDate(eventDateSelected))
        }).then((response) => {
            if (response.data.response === 'OK') {
                setLoading(false)
                handleReloadData()
                setOpen(false)
            } else {
                alert('Ooops! Sembra esserci stato un errore.')
            }
        }).catch(() => {
            setLoading(false)
            alert('Ooops! Sembra esserci stato un errore.')
        })
    }

    function handleSelectDate(date) {
        setEventDateSelected(date)
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            <div ref={itemCostsPopupRef}
                 style={pageStyle.container}>
                <div style={{...pageStyle.mainSection, ...{backgroundColor: primaryColorFirstAlternative}}}>
                    <MyNormalText text={item.name}
                                  bold={true}/>
                    <MyNormalText text={'Inserisci una spesa'}/>
                    <div style={{...pageStyle.section, ...{backgroundColor: primaryColor}}}>
                        <MyNormalText text={'1. Giorno'}/>
                        <div style={{marginTop: 10}}>
                            <DatePicker selected={eventDateSelected}
                                        onChange={handleSelectDate}
                                        className={'custom-input'}
                                        dateFormat={'dd/MM/yyyy'}
                                        locale={'it'}
                                        showMonthYearDropdown={false}
                                        placeholderText={'Data'}/>
                        </div>
                    </div>
                    <div style={{...pageStyle.section, ...{backgroundColor: primaryColor}}}>
                        <MyNormalText text={'2. Descrizione'}/>
                        {costsDescription.map((description, index) => {
                            return (
                                <div key={index.toString()}
                                     style={{marginTop: 10}}>
                                    <MainButton text={description}
                                                action={() => setDescriptionSelected(description)}
                                                backgroundColor={descriptionSelected === description ? secondaryColor : primaryColorFirstAlternative}/>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{...pageStyle.section, ...{backgroundColor: primaryColor}}}>
                        <MyNormalText text={'3. Costo'}/>
                        <div style={{marginTop: 10}}>
                            <input style={globalElementInputStyle.customInput}
                                   type={'number'}
                                   value={priceForThisCost}
                                   placeholder={'0'}
                                   onChange={(e) => handleInsertCost(parseInt(e.target.value))}/>
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <MainButton text={'Salva'}
                                    loading={loading}
                                    disabled={isSaveCostDisabled}
                                    action={handleSaveCost}
                                    backgroundColor={secondaryColor}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        position: 'fixed',
        zIndex: 100,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
        overflowY: 'scroll'
    },
    container: {
        position: 'absolute',
        left: '50%',
        top: '5%',
        bottom: '5%',
        minWidth: 'calc(100% / 5)',
        transform: 'translate(-50%, 0)',
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    mainSection: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    section: {
        marginTop: 10,
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    }
}

export default ItemCostsPopup
