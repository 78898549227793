import React, {useState} from "react";
import MyNormalText from "../../commons/myNormalText";
import {EURO_VALUE, GLOBAL_BORDER_RADIUS, premiumDataType} from "../../../costants";
import MainButton from "../../commons/mainButton";
import ItemCostsPopup from "./itemCostsPopup";
import ItemBox from "../item/itemBox";
import ItemPremiumDataDetail from "./ItemPremiumDataDetail";

function ItemPremiumDataRow({
                                item,
                                rentUser,
                                handleSelectItem,
                                handleRemoveItem,
                                isThisItemSelected,
                                premiumPalette,
                                premiumData,
                                triggerRefreshData,
                                setTriggerRefreshData
                            }) {

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative
    const primaryColorFourthAlternative = premiumPalette.primaryColorFourthAlternative

    const [itemCostsToOpen, setItemCostsToOpen] = useState(null)
    const [openPremiumDataDetail, setOpenPremiumDataDetail] = useState(false)
    const [openItemCostsPopup, setOpenItemCostsPopup] = useState(false)

    let amount = 0
    let manualAndLoceMBookingAmount = 0
    let manualInsertCostAmount = 0

    if (premiumData.length > 0) {
        premiumData.forEach(value => {
            if (value.type === premiumDataType.MANUAL_ACCEPTED_BOOKING || value.type === premiumDataType.APP_LOCEM_ACCEPTED_BOOKING) {
                manualAndLoceMBookingAmount += value.amount
            } else if (value.type === premiumDataType.MANUAL_INSERT_COST) {
                manualInsertCostAmount += value.amount
            }
            amount += value.amount
        })
        item.premiumDataTotalFromOrders = manualAndLoceMBookingAmount
        item.premiumDataTotalCosts = manualInsertCostAmount
        item.premiumDataAmountProduced = amount
    }

    function handleOpenItemCostsPopup(item) {
        setOpenItemCostsPopup(!openItemCostsPopup)
        setItemCostsToOpen(item)
    }

    function reloadData() {
        setTriggerRefreshData(!triggerRefreshData)
    }

    return (
        <div>
            <div style={{...pageStyle.row, ...{backgroundColor: primaryColor}}}>
                <div style={pageStyle.containerSelectionButton}>
                    <MainButton
                        image={isThisItemSelected ? require('../../../imgs/actions/remove.png') : require('../../../imgs/actions/add.png')}
                        action={() => isThisItemSelected ? handleRemoveItem(item) : handleSelectItem(item)}
                        backgroundColor={isThisItemSelected ? secondaryColor : primaryColorFourthAlternative}
                        height={30}
                        imageHeight={15}
                        imageWidth={15}/>
                </div>
                <div style={{marginRight: 5}}>
                    <ItemBox item={item}
                             premiumPalette={premiumPalette}
                             rentUser={rentUser}
                             fontSizeTitle={14}
                             fontSizeInfo={13}
                             hidePrices={true}
                             imageHeight={95}
                             width={120}
                             backgroundColor={primaryColorFirstAlternative}/>
                </div>
                <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                    <div style={{...pageStyle.containerInfo, ...{backgroundColor: primaryColorFirstAlternative}}}>
                        <div style={pageStyle.containerSingleInfo}>
                            <MyNormalText text={EURO_VALUE + ' ' + manualAndLoceMBookingAmount + ' (ordini)'}/>
                        </div>
                        <div style={pageStyle.containerSingleInfo}>
                            <MyNormalText text={EURO_VALUE + ' ' + manualInsertCostAmount + ' (costi)'}
                                          color={manualInsertCostAmount < 0 ? 'red' : undefined}/>
                        </div>
                        <div style={{...pageStyle.separator, ...{backgroundColor: primaryColor}}}/>
                        <div style={pageStyle.containerSingleInfo}>
                            <MyNormalText text={EURO_VALUE + ' ' + amount}
                                          color={amount < 0 ? 'red' : undefined}/>
                        </div>
                    </div>
                    <MainButton text={'Statistiche'}
                                action={() => setOpenPremiumDataDetail(true)}
                                firstImage={require("../../../imgs/actions/statistics.png")}
                                textAlignStart={true}
                                firstImageWidth={18}
                                firstImageHeight={18}/>
                    <MainButton text={'Aggiungi spesa'}
                                action={() => handleOpenItemCostsPopup(item)}
                                firstImage={require("../../../imgs/actions/repair.png")}
                                textAlignStart={true}
                                firstImageWidth={18}
                                firstImageHeight={18}/>
                </div>
            </div>
            {openPremiumDataDetail ?
                <ItemPremiumDataDetail rentUser={rentUser}
                                       item={item}
                                       manualAndLoceMBookingAmount={manualAndLoceMBookingAmount}
                                       manualInsertCostAmount={manualInsertCostAmount}
                                       amountProduced={amount}
                                       premiumPalette={premiumPalette}
                                       handleReloadData={reloadData}
                                       setIsOpen={setOpenPremiumDataDetail}/> :
                <></>}
            {openItemCostsPopup ?
                <ItemCostsPopup item={itemCostsToOpen}
                                premiumPalette={premiumPalette}
                                rentId={rentUser.rentId}
                                setOpen={setOpenItemCostsPopup}
                                handleReloadData={reloadData}/> :
                <></>}
        </div>
    )
}

const pageStyle = {
    row: {
        display: 'flex',
        position: 'relative',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    containerSelectionButton: {
        position: 'absolute',
        right: 5,
        top: 5
    },
    containerInfo: {
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    separator: {
        height: 2,
        marginTop: 5,
        marginBottom: 5
    },
    containerSingleInfo: {}
}

export default ItemPremiumDataRow
