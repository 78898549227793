import React, {useState} from 'react';
import MyNormalText from './myNormalText';
import {GLOBAL_BORDER_RADIUS} from '../../costants';

function MyToolTip({children, text, premiumPalette, isActive}) {

    const [isVisible, setIsVisible] = useState(false)

    const tooltipContainerStyle = {
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer'
    }

    const tooltipStyle = {
        position: 'absolute',
        top: '80%',
        left: '50%',
        transform: 'translateX(-50%)',
        marginTop: 5,
        padding: '8px 12px',
        background: premiumPalette.primaryColorFourthAlternative,
        borderRadius: GLOBAL_BORDER_RADIUS,
        whiteSpace: 'nowrap',
        zIndex: 10,
        visibility: isVisible ? 'visible' : 'hidden',
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.2s, visibility 0.2s'
    }

    const arrowStyle = {
        position: 'absolute',
        top: -6,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '0',
        height: '0',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: '6px solid ' + premiumPalette.primaryColorFourthAlternative
    }

    function handleMouseEnter() {
        if (!isActive) {
            return
        }
        setIsVisible(true)
    }

    function handleMouseLeave() {
        if (!isActive) {
            return
        }
        setIsVisible(false)
    }

    return (
        <div style={tooltipContainerStyle}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}>
            {children}
            <div style={tooltipStyle}>
                <div style={arrowStyle}/>
                <MyNormalText text={text}/>
            </div>
        </div>
    )
}

export default MyToolTip
