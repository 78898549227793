import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {websitePages} from "../../costants";

function WindEffectBackground() {

    const colors = [
        [130, 224, 170],
        [125, 31, 85],
        [31, 97, 141],
        [224, 158, 114]
    ]

    const location = useLocation()
    const [color, setColor] = useState(colors[0])

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY
            const viewportHeight = window.innerHeight
            const maxIndex = colors.length - 1

            // Determina in quale "sezione" di 100vh ci troviamo
            const sectionIndex = Math.floor(scrollTop / viewportHeight)
            const nextSectionIndex = Math.min(sectionIndex + 1, maxIndex)

            // Calcola la percentuale di completamento della sezione corrente
            const sectionScroll = scrollTop % viewportHeight
            const progress = sectionScroll / viewportHeight

            // Colori iniziale e finale per l'interpolazione
            const [r1, g1, b1] = colors[sectionIndex]
            const [r2, g2, b2] = colors[nextSectionIndex]

            // Calcolo interpolato per ogni componente RGB
            const r = Math.round(r1 + (r2 - r1) * progress)
            const g = Math.round(g1 + (g2 - g1) * progress)
            const b = Math.round(b1 + (b2 - b1) * progress)

            setColor([r, g, b])
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    if (location.pathname.includes(websitePages.DASHBOARD)) {
        return null
    }

    const styles = {
        windBackground: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            zIndex: -1
        },
        windLayer: {
            position: 'absolute',
            width: '200%',
            height: '100%',
            top: 0,
            transition: 'fill 0.2s ease-out'
        },
        layer1: {
            animation: 'windAnimation 25s linear infinite'
        },
        layer2: {
            animation: 'windAnimation 30s linear infinite reverse'
        },
        layer3: {
            animation: 'windAnimation 35s linear infinite'
        },
        layer4: {
            animation: 'windAnimation 40s linear infinite reverse'
        }
    }

    const currentColor = `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.4)`;

    return (
        <div style={styles.windBackground}>
            <svg style={{...styles.windLayer, ...styles.layer1}}
                 viewBox={'0 0 800 400'}
                 preserveAspectRatio={'none'}>
                <path d={'M0,100 C150,200 350,0 500,100 C650,200 850,0 1000,100 L1000,400 L0,400 Z'}
                      fill={currentColor}/>
            </svg>
            <svg style={{...styles.windLayer, ...styles.layer2}}
                 viewBox={'0 0 800 400'}
                 preserveAspectRatio={'none'}>
                <path d={'M0,200 C200,300 400,100 600,200 C800,300 1000,100 1200,200 L1200,400 L0,400 Z'}
                      fill={currentColor}/>
            </svg>
            <svg style={{...styles.windLayer, ...styles.layer3}}
                 viewBox={'0 0 800 400'}
                 preserveAspectRatio={'none'}>
                <path d={'M0,300 C150,400 350,200 500,300 C650,400 850,200 1000,300 L1000,400 L0,400 Z'}
                      fill={currentColor}/>
            </svg>
            <svg style={{...styles.windLayer, ...styles.layer4}}
                 viewBox={'0 0 800 400'}
                 preserveAspectRatio={'none'}>
                <path d={'M0,250 C100,350 300,150 500,250 C700,350 900,150 1100,250 L1100,400 L0,400 Z'}
                      fill={currentColor}/>
            </svg>
            <style>{`
                @keyframes windAnimation {
                  from {
                    transform: translateX(-50%);
                  }
                  to {
                    transform: translateX(0);
                  }
                }
            `}</style>
        </div>
    )
}

export default WindEffectBackground
