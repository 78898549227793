import React, {useState} from "react";
import MainButton from "../../commons/mainButton";
import {orderItemsBy} from "../../../global/itemsFunctions";

export const ASC = 'ASC'
export const DESC = 'DESC'
export const NAME = 'NAME'
export const NAME_OBJECT_VALUE = 'name'
export const ASSIGNED_RENT_CODE = 'ASSIGNED RENT CODE'
export const ASSIGNED_RENT_CODE_OBJECT_VALUE = 'assignedRentCode'
export const SIZE = 'SIZE'
export const SIZE_OBJECT_VALUE = 'size'
export const AMOUNT_FROM_ORDERS = 'AMOUNT_FROM_ORDERS'
export const TOTAL_COSTS = 'TOTAL_COSTS'
export const PREMIUM_DATA_AMOUNT_PRODUCED = 'AMOUNT_PRODUCED'
export const PREMIUM_DATA_TOTAL_FROM_ORDERS_OBJECT_VALUE = 'premiumDataTotalFromOrders'
export const PREMIUM_DATA_TOTAL_COSTS_OBJECT_VALUE = 'premiumDataTotalCosts'
export const PREMIUM_DATA_AMOUNT_PRODUCED_OBJECT_VALUE = 'premiumDataAmountProduced'

function ButtonsToOrderItems({items, setItems, premiumPalette, buttonsColor, premiumDataButtons}) {

    const [buttonOrderSelected, setButtonOrderSelected] = useState('')
    const [orderByName, setOrderByName] = useState(ASC)
    const [orderByAssignedRentCode, setOrderByAssignedRentCode] = useState(ASC)
    const [orderByInsertDateDirection, setOrderByInsertDateDirection] = useState(ASC)
    const [orderByPremiumDataAmountFromOrders, setOrderByPremiumDataAmountFromOrders] = useState(ASC)
    const [orderByPremiumDataTotalCosts, setOrderByPremiumDataTotalCosts] = useState(ASC)
    const [orderByPremiumDataAmount, setOrderByPremiumDataAmount] = useState(ASC)

    function handleOrderBy(order, typeToOrder, objectValueToOrder, setOrderBy) {
        setButtonOrderSelected(typeToOrder)
        setOrderBy(order)
        if (order === 'ASC') {
            orderItemsBy(items, objectValueToOrder, ASC)
        } else if (order === 'DESC') {
            orderItemsBy(items, objectValueToOrder, DESC)
        }
        if (setItems) {
            setItems([...items])
        }
    }

    return (
        <div style={pageStyle.container}>
            <div>
                <MainButton text={'Nome articolo'}
                            height={30}
                            backgroundColor={buttonOrderSelected === NAME ? premiumPalette.secondaryColor : buttonsColor}
                            secondaryImage={orderByName === ASC ? require("../../../imgs/actions/arrow-up.png") : require("../../../imgs/actions/arrow-down.png")}
                            action={() => handleOrderBy(orderByName === ASC ? DESC : ASC, NAME, NAME_OBJECT_VALUE, setOrderByName)}/>

            </div>
            <div>
                <MainButton text={'Codice'}
                            height={30}
                            backgroundColor={buttonOrderSelected === ASSIGNED_RENT_CODE ? premiumPalette.secondaryColor : buttonsColor}
                            secondaryImage={orderByAssignedRentCode === ASC ? require("../../../imgs/actions/arrow-up.png") : require("../../../imgs/actions/arrow-down.png")}
                            action={() => handleOrderBy(orderByAssignedRentCode === ASC ? DESC : ASC, ASSIGNED_RENT_CODE, ASSIGNED_RENT_CODE_OBJECT_VALUE, setOrderByAssignedRentCode)}/>
            </div>
            <div>
                <MainButton text={'Taglia'}
                            height={30}
                            backgroundColor={buttonOrderSelected === SIZE ? premiumPalette.secondaryColor : buttonsColor}
                            secondaryImage={orderByInsertDateDirection === ASC ? require("../../../imgs/actions/arrow-up.png") : require("../../../imgs/actions/arrow-down.png")}
                            action={() => handleOrderBy(orderByInsertDateDirection === ASC ? DESC : ASC, SIZE, SIZE_OBJECT_VALUE, setOrderByInsertDateDirection)}/>
            </div>
            {premiumDataButtons ?
                <>
                    <div>
                        <MainButton text={'Totale ordini'}
                                    height={30}
                                    backgroundColor={buttonOrderSelected === AMOUNT_FROM_ORDERS ? premiumPalette.secondaryColor : buttonsColor}
                                    secondaryImage={orderByPremiumDataAmountFromOrders === ASC ? require("../../../imgs/actions/arrow-up.png") : require("../../../imgs/actions/arrow-down.png")}
                                    action={() => handleOrderBy(orderByPremiumDataAmountFromOrders === ASC ? DESC : ASC, AMOUNT_FROM_ORDERS, PREMIUM_DATA_TOTAL_FROM_ORDERS_OBJECT_VALUE, setOrderByPremiumDataAmountFromOrders)}/>
                    </div>
                    <div>
                        <MainButton text={'Costi'}
                                    height={30}
                                    backgroundColor={buttonOrderSelected === TOTAL_COSTS ? premiumPalette.secondaryColor : buttonsColor}
                                    secondaryImage={orderByPremiumDataTotalCosts === ASC ? require("../../../imgs/actions/arrow-up.png") : require("../../../imgs/actions/arrow-down.png")}
                                    action={() => handleOrderBy(orderByPremiumDataTotalCosts === ASC ? DESC : ASC, TOTAL_COSTS, PREMIUM_DATA_TOTAL_COSTS_OBJECT_VALUE, setOrderByPremiumDataTotalCosts)}/>
                    </div>
                    <div>
                        <MainButton text={'Totale prodotto'}
                                    height={30}
                                    backgroundColor={buttonOrderSelected === PREMIUM_DATA_AMOUNT_PRODUCED ? premiumPalette.secondaryColor : buttonsColor}
                                    secondaryImage={orderByPremiumDataAmount === ASC ? require("../../../imgs/actions/arrow-up.png") : require("../../../imgs/actions/arrow-down.png")}
                                    action={() => handleOrderBy(orderByPremiumDataAmount === ASC ? DESC : ASC, PREMIUM_DATA_AMOUNT_PRODUCED, PREMIUM_DATA_AMOUNT_PRODUCED_OBJECT_VALUE, setOrderByPremiumDataAmount)}/>
                    </div>
                </> :
                <></>}
        </div>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        gap: 10
    }
}

export default ButtonsToOrderItems
