import React from "react";
import {globalPageStyle} from "../../global/style/globalPageStyle";
import MyNormalText from "../commons/myNormalText";

function NotFound() {

    return (
        <div style={globalPageStyle.pageBackground}>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true}
                                  text={'404'}
                                  fontSize={32}/>
                </div>
            </main>
        </div>
    )
}

export default NotFound
