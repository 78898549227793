import React, {useEffect, useRef, useState} from "react";
import {
    ARCHIVED_COLOR,
    DISABLED,
    EURO_VALUE,
    GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
    GLOBAL_BORDER_RADIUS,
    operationOnRentItemType,
    SELLABLE_COLOR
} from "../../../costants";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../firebase";
import {getUrlImg} from "../../../global/firebaseStorageRepo";
import Loader from "../../commons/loader";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {fromMomentDate, toMomentDate} from "../../../global/dates";
import MainButton from "../../commons/mainButton";
import ItemImage from "./itemImage";
import MyNormalText from "../../commons/myNormalText";
import {globalElementInputStyle} from "../../../global/style/globalElementInputStyle";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";
import useMyClickOutListener from "../../../global/listeners/useMyClickOutListener";
import UpdateItem from "./updateItem";

moment.locale('it');
const localizer = momentLocalizer(moment);
const BLOCKED_DATE = 'Bloccato'
const BOOKED_DATE = 'Prenotato'
const NEW_DATE_TO_BLOCK = 'Blocca'
const FONT_SIZE_TITLE = 18

function ItemDetail({rentUser, itemId, premiumPalette, setIsOpen, openOperations, refreshItems}) {

    const primaryColorSecondAlternative = premiumPalette.primaryColorSecondAlternative

    const [item, setItem] = useState({})
    const [urlImages, setUrlImages] = useState([])
    const selectedRangeInitialValue = null
    const [selectedRange, setSelectedRange] = useState(selectedRangeInitialValue)
    const [calendarEvents, setCalendarEvents] = useState([])
    const [loadingItem, setLoadingItem] = useState(true)
    const [loadingUpdatingItem, setLoadingUpdatingItem] = useState(false)
    const [loadingUpdatingSellableInfos, setLoadingUpdatingSellableInfos] = useState(false)

    const [isSellable, setIsSellable] = useState(item.isSellable)
    const [oldSellPrice, setOldSellPrice] = useState()
    const [newSellPrice, setNewSellPrice] = useState()
    const descriptionInitialValue = ''
    const [italianDescription, setItalianDescription] = useState(descriptionInitialValue)
    const [englishDescription, setEnglishDescription] = useState(descriptionInitialValue)
    const [germanDescription, setGermanDescription] = useState(descriptionInitialValue)

    const dateBlockedToRemoveInitialState = null
    const [dateBlockedToRemove, setDateBlockedToRemove] = useState(dateBlockedToRemoveInitialState)
    const [loadingDeletingItemDate, setLoadingDeletingItemDate] = useState(false)
    const [loadingArchivingItem, setLoadingArchivingItem] = useState(false)
    const [loadingDuplicateItem, setLoadingDuplicateItem] = useState(false)

    const rentSlotConfiguration = rentUser.rentSlotConfiguration

    const itemDetailRef = useRef()

    const textAreaStyle = {
        ...globalElementInputStyle.customInput,
        resize: 'none',
        height: 100
    }

    useEffect(() => {
        getItem()
    }, [])

    function handleOnEscape() {
        setIsOpen(false)
    }

    useMyEscapeListener(() => handleOnEscape())
    useMyClickOutListener(itemDetailRef, () => handleOnEscape())

    function getItem() {
        httpsCallable(functions, 'getItemRentDetail')({rentId: rentUser.rentId, itemId: itemId})
            .then((response) => {
                const item = response.data.item;

                getDetailImages(item)
                setAllTheRentedDates(item.rentedDays)

                setItem(item)
                setUrlImages(item.images)
                setDateBlockedToRemove(dateBlockedToRemoveInitialState)
                setSelectedRange(selectedRangeInitialValue)
                setIsSellable(item.isSellable)
                const sellableInfos = item.sellableInfos
                setOldSellPrice(sellableInfos?.oldPrice)
                setNewSellPrice(sellableInfos?.newPrice)
                const description = sellableInfos?.description
                setItalianDescription(description?.italian)
                setEnglishDescription(description?.english)
                setGermanDescription(description?.german)
                setLoadingItem(false)
            })
            .catch(() => setLoadingItem(false))
    }


    function getDetailImages(item) {
        let imagesUrl = []
        item?.images?.map(async image => {
            await getUrlImg(rentUser.rentId, item.name, 'detail', image)
                .then(urlDetailImage => {
                    imagesUrl.push(urlDetailImage)
                    setUrlImages([...imagesUrl])
                })
        })
    }

    function createIdForBlockDates(momentStartDate, momentEndDate) {
        return momentStartDate.toISOString() + '/' + momentEndDate.toISOString()
    }

    function setAllTheRentedDates(rentedDates) {
        let parsedDates = []

        function getTitle(date) {
            if (date.type === operationOnRentItemType.BLOCKED) {
                return BLOCKED_DATE
            }
            return BOOKED_DATE
        }

        if (rentedDates) {
            rentedDates.forEach(date => {
                let momentStartDate = toMomentDate(date.from)
                let momentEndDate = date.to ? toMomentDate(date.to).add(1, 'day') : momentStartDate
                parsedDates.push({
                    id: createIdForBlockDates(momentStartDate, momentEndDate),
                    start: momentStartDate,
                    end: momentEndDate,
                    title: getTitle(date),
                    allDay: true
                })
            })
        }
        setCalendarEvents(parsedDates)
    }

    const handleSelectRange = ({start, end}) => {
        const momentStartDate = toMomentDate(start)
        const momentEndDate = toMomentDate(end)

        const parsedStartDateForBe = fromMomentDate(momentStartDate)
        const parsedEndDateForBe = fromMomentDate(momentEndDate.clone().subtract(1, 'day'))

        setSelectedRange({from: parsedStartDateForBe, to: parsedEndDateForBe})
        const calendarEventsParsed = calendarEvents.filter(event => event.title !== NEW_DATE_TO_BLOCK)
        setCalendarEvents([...calendarEventsParsed, ...[{
            id: createIdForBlockDates(momentStartDate, momentEndDate),
            start: momentStartDate,
            end: momentEndDate,
            title: NEW_DATE_TO_BLOCK,
            allDay: true
        }]])
    }

    const eventPropGetter = (event, start, end, isSelected) => {
        let style = {}
        if (isSelected && event.title === BLOCKED_DATE) {
            style.backgroundColor = 'red'
            let momentStartDate = event.start
            let momentEndDate = event.end.clone()

            if (momentStartDate.isSame(momentEndDate)) {
                momentStartDate = momentEndDate
            } else {
                momentEndDate.subtract(1, 'day')
            }

            if (dateBlockedToRemove === null) {
                setDateBlockedToRemove({from: fromMomentDate(momentStartDate), to: fromMomentDate(momentEndDate)})
            }
        }

        return {style}
    }

    function handleSaveNewRentedDays() {
        setLoadingUpdatingItem(true)
        const rentUpdateItemInfoCommand = httpsCallable(functions, 'rentUpdateItemInfoCommand')
        let request = {
            itemId: itemId,
            rentId: rentUser.rentId,
            rentedDays: {from: selectedRange.from, to: selectedRange.to},
            operationType: operationOnRentItemType.BLOCKED
        }
        rentUpdateItemInfoCommand(request)
            .then((response) => {
                setLoadingUpdatingItem(false)
                if (response.data.response === 'OK') {
                    getItem()
                } else {
                    alert('Ooops! Sembra esserci stato un problema nel bloccare le date.')
                }
            }).catch(() => setLoadingUpdatingItem(false))
    }

    function handleUpdateSellableInfos() {
        setLoadingUpdatingSellableInfos(true)
        const rentUpdateItemInfoCommand = httpsCallable(functions, 'rentUpdateItemInfoCommand')
        const request = {
            itemId: itemId,
            rentId: rentUser.rentId,
            isSellable: isSellable,
            sellableInfos: {
                oldPrice: oldSellPrice,
                newPrice: newSellPrice,
                description: {
                    italian: italianDescription,
                    english: englishDescription,
                    german: germanDescription
                }
            },
            operationType: operationOnRentItemType.UPDATE_SELLABLE_INFOS
        }
        rentUpdateItemInfoCommand(request)
            .then((response) => {
                setLoadingUpdatingSellableInfos(false)
                if (response.data.response === 'OK') {
                    refreshItems()
                    getItem()
                } else {
                    alert('Ooops! Sembra esserci un errore.')
                }
            })
            .catch(() => {
                alert('Ooops! Sembra esserci un errore.')
                setLoadingUpdatingSellableInfos(false)
            })
    }

    function handleRemoveRentedDays() {
        if (dateBlockedToRemove) {
            setLoadingDeletingItemDate(true)
            const deleteRentItemInfo = httpsCallable(functions, 'deleteRentItemInfo')
            const request = {
                rentId: rentUser.rentId,
                itemId: itemId,
                rentedDays: dateBlockedToRemove,
                operationType: operationOnRentItemType.BLOCKED
            };
            deleteRentItemInfo(request)
                .then((response) => {
                    if (response.data.response === 'OK') {
                        getItem()
                        setLoadingDeletingItemDate(false)
                    }
                }).catch(() => setLoadingDeletingItemDate(false))
        }
    }

    function handleArchiveItem() {
        setLoadingArchivingItem(true)
        const rentUpdateItemInfoCommand = httpsCallable(functions, 'rentUpdateItemInfoCommand')
        const request = {
            rentId: rentUser.rentId,
            itemId: itemId,
            archived: !item.archived,
            operationType: operationOnRentItemType.ARCHIVE
        }
        rentUpdateItemInfoCommand(request)
            .then((response) => {
                if (response.data.response === 'OK') {
                    getItem()
                    refreshItems()
                    setLoadingArchivingItem(false)
                }
            }).catch(() => setLoadingArchivingItem(false))
    }

    function handleDuplicateItem() {
        setLoadingDuplicateItem(true)
        const request = {
            rentId: rentUser.rentId,
            item: item
        }
        const rentDuplicateItemCommand = httpsCallable(functions, 'rentDuplicateItemCommand')
        rentDuplicateItemCommand(request)
            .then((response) => {
                const item = response.data.item
                alert('Nuovo articolo creato con codice ' + item.id)
                refreshItems()
                setLoadingDuplicateItem(false)
            })
            .catch(() => setLoadingDuplicateItem(false))
    }

    function handleChangeOldSellPrice(value) {
        setOldSellPrice(parseInt(value))
    }

    function handleChangeNewSellPrice(value) {
        setNewSellPrice(parseInt(value))
    }

    const handleChangeDescription = (event, setDescription) => {
        setDescription(event.target.value)
    }

    function handleCheckSellable(value) {
        setIsSellable(value)
    }

    const archivedContainerStyle = {
        ...pageStyle.containerDisplayInfo,
        borderLeft: '2px solid ' + ARCHIVED_COLOR,
        borderBottom: '2px solid ' + ARCHIVED_COLOR,
        paddingRight: 5,
        justifySelf: 'flex-start'
    }

    const sellableContainerStyle = {
        ...pageStyle.containerDisplayInfo,
        borderLeft: '2px solid ' + SELLABLE_COLOR,
        borderBottom: '2px solid ' + SELLABLE_COLOR,
        paddingRight: 5,
        justifySelf: 'flex-start'
    }

    return (
        <div style={pageStyle.containerFullPageOpacity}>
            {loadingItem || !item.id ?
                <Loader absoluteFullPage={true}/> :
                <div ref={itemDetailRef}
                     style={pageStyle.container}>
                    <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                        <div style={pageStyle.containerImages}>
                            {urlImages?.map((url, index) => {
                                return (
                                    <div key={index.toString()}
                                         style={{marginRight: 5, marginBottom: 5}}>
                                        <ItemImage imageUrl={url}/>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <MyNormalText text={item.name}
                                          bold={true}
                                          fontSize={FONT_SIZE_TITLE}/>
                            <MyNormalText text={item.id}/>
                        </div>
                        <div style={pageStyle.subSection}>
                            <div style={pageStyle.flexColumn}>
                                <MyNormalText text={'Codice magazzino'}
                                              bold={true}/>
                                <MyNormalText text={item?.assignedRentCode ? item?.assignedRentCode : '-'}/>
                            </div>
                        </div>
                        {item.archived ?
                            <div style={pageStyle.subSection}>
                                <div style={archivedContainerStyle}>
                                    <MyNormalText text={'Archiviato'}
                                                  bold={true}/>
                                </div>
                            </div> :
                            <></>}
                        {item.isSellable ?
                            <div style={pageStyle.subSection}>
                                <div style={sellableContainerStyle}>
                                    <MyNormalText text={'In vendita'}
                                                  bold={true}/>
                                </div>
                            </div> :
                            <></>}
                        <div style={pageStyle.subSection}>
                            <div style={pageStyle.containerDisplayInfo}>
                                <MyNormalText text={item?.category + ', ' + item?.size}
                                              bold={true}/>
                            </div>
                        </div>
                        <div style={pageStyle.subSection}>
                            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                <div style={pageStyle.containerDisplayInfo}>
                                    <MyNormalText text={'Prezzi giornalieri'}
                                                  bold={true}/>
                                    {rentSlotConfiguration.map((slot, index) => {
                                        const slotFound = item.singleDayPrices.find(price => price.type === slot.type)
                                        return (
                                            <div key={index.toString()}>
                                                <MyNormalText
                                                    text={slot.description + ': ' + EURO_VALUE + slotFound.price}
                                                    fontSize={14}/>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div style={pageStyle.containerDisplayInfo}>
                                    <MyNormalText text={'Prezzi su più giorni'}
                                                  bold={true}/>
                                    {item?.prices.map((price, index) => {
                                        return (
                                            <div key={index.toString()}>
                                                <MyNormalText
                                                    text={'Giorno ' + price.day + ': ' + EURO_VALUE + price.cost}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div style={pageStyle.containerDisplayInfo}>
                            {item?.infos?.map((info, index) => {
                                return (
                                    <div key={index.toString()}
                                         style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap'}}>
                                        <MyNormalText text={'Descrizione'}
                                                      bold={true}/>
                                        <MyNormalText text={info.description}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {openOperations ?
                        <>
                            <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                                <MyNormalText text={'Archiviazione'}
                                              bold={true}
                                              fontSize={FONT_SIZE_TITLE}/>
                                <MainButton backgroundColor={premiumPalette.secondaryColor}
                                            loading={loadingArchivingItem}
                                            disabled={loadingArchivingItem}
                                            text={item.archived ? 'Rimuovi dall\'archivio' : 'Archivia'}
                                            action={handleArchiveItem}/>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                                <MyNormalText text={'Aggiorna informazioni'}
                                              bold={true}
                                              fontSize={FONT_SIZE_TITLE}/>
                                <UpdateItem rentUser={rentUser}
                                            item={item}
                                            premiumPalette={premiumPalette}
                                            refreshItems={refreshItems}
                                            refreshItem={getItem}/>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                                <MyNormalText text={'Vendita'}
                                              bold={true}
                                              fontSize={FONT_SIZE_TITLE}/>
                                <div>
                                    {item.isSellable ?
                                        <MyNormalText text={'Ora in vendita'}
                                                      bold={true}
                                                      color={'green'}/> :
                                        <MyNormalText text={'Ora non in vendita'}
                                                      bold={true}
                                                      color={'red'}/>}
                                </div>
                                <div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <MyNormalText text={'In vendita'}/>
                                        <input type="checkbox"
                                               style={{width: 20, height: 20}}
                                               checked={isSellable}
                                               onChange={() => handleCheckSellable(!isSellable)}/>
                                    </div>
                                </div>
                                <div style={{display: 'flex'}}>
                                    <div style={{marginRight: 10}}>
                                        <input style={globalElementInputStyle.customInput}
                                               type={'number'}
                                               min={'0'}
                                               value={oldSellPrice}
                                               placeholder={'Prezzo di listino'}
                                               onChange={(e) => handleChangeOldSellPrice(e.target.value)}/>
                                    </div>
                                    <div>
                                        <input style={globalElementInputStyle.customInput}
                                               type={'number'}
                                               min={'0'}
                                               value={newSellPrice}
                                               placeholder={'Prezzo di vendita'}
                                               onChange={(e) => handleChangeNewSellPrice(e.target.value)}/>
                                    </div>
                                </div>
                                <div>
                                    <textarea style={textAreaStyle}
                                              value={italianDescription}
                                              placeholder={'Descrizione in italiano'}
                                              onChange={(event) => handleChangeDescription(event, setItalianDescription)}
                                              rows={italianDescription ? italianDescription.split('\n').length : 1}/>
                                </div>
                                <div>
                                    <textarea style={textAreaStyle}
                                              value={englishDescription}
                                              placeholder={'Descrizione in inglese'}
                                              onChange={(event) => handleChangeDescription(event, setEnglishDescription)}
                                              rows={englishDescription ? englishDescription.split('\n').length : 1}/>
                                </div>
                                <div>
                                    <textarea style={textAreaStyle}
                                              value={germanDescription}
                                              placeholder={'Descrizione in tedesco'}
                                              onChange={(event) => handleChangeDescription(event, setGermanDescription)}
                                              rows={germanDescription ? germanDescription.split('\n').length : 1}/>
                                </div>
                                <div>
                                    <MainButton text={'Aggiorna'}
                                                action={handleUpdateSellableInfos}
                                                loading={loadingUpdatingSellableInfos}
                                                disabled={loadingUpdatingSellableInfos}
                                                backgroundColor={premiumPalette.secondaryColor}/>
                                </div>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                                <div style={pageStyle.flexColumn}>
                                    <MyNormalText text={'Duplica'}
                                                  bold={true}
                                                  fontSize={FONT_SIZE_TITLE}/>
                                    <MyNormalText text={'Crea un nuovo articolo identico a questo'}/>
                                </div>
                                <MainButton backgroundColor={premiumPalette.secondaryColor}
                                            loading={loadingDuplicateItem}
                                            disabled={loadingDuplicateItem}
                                            text={'Duplica'}
                                            action={handleDuplicateItem}/>
                            </div>
                            <div style={{...pageStyle.section, backgroundColor: primaryColorSecondAlternative}}>
                                <div>
                                    <MyNormalText text={'Prenotazioni'}
                                                  bold={true}
                                                  fontSize={FONT_SIZE_TITLE}/>
                                </div>
                                <Calendar selectable={true}
                                          localizer={localizer}
                                          events={calendarEvents}
                                          views={['month']}
                                          style={{height: 700}}
                                          onSelectSlot={handleSelectRange}
                                          eventPropGetter={eventPropGetter}/>
                                <div>
                                    <MainButton
                                        backgroundColor={selectedRange ? premiumPalette.secondaryColor : DISABLED}
                                        disabled={selectedRange === null}
                                        loading={loadingUpdatingItem}
                                        text={'Conferma Blocco'}
                                        action={handleSaveNewRentedDays}/>
                                </div>
                                <div>
                                    <MainButton backgroundColor={dateBlockedToRemove ? 'red' : DISABLED}
                                                disabled={dateBlockedToRemove === null}
                                                loading={loadingDeletingItemDate}
                                                text={'Rimuovi Bloccato'}
                                                action={handleRemoveRentedDays}/>
                                </div>
                            </div>
                        </>
                        :
                        <></>}
                </div>
            }
        </div>
    )
}

const pageStyle = {
    containerFullPageOpacity: {
        zIndex: 10,
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: GLOBAL_BACKGROUND_POPUP_ABSOLUTE,
        overflowY: 'scroll'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
        paddingBottom: 20,
        position: 'absolute',
        left: '50%',
        top: '5%',
        transform: 'translate(-50%, 0)'
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        padding: 20,
        borderRadius: GLOBAL_BORDER_RADIUS
    },
    subSection: {},
    containerImages: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'flex-start'
    },
    margin10: {
        marginTop: 10
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerDisplayInfo: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        marginRight: 40,
        borderLeft: '1px solid black',
        alignSelf: 'flex-start'
    }
}

export default ItemDetail
