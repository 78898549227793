import React from "react";
import {GLOBAL_BORDER_RADIUS, PRIMARY_COLOR_FIRST_ALTERNATIVE} from "../../../costants";
import {globalPageStyle} from "../../../global/style/globalPageStyle";
import MyNormalText from "../../commons/myNormalText";

function LocemWebsiteCookiesPolicy() {

    return (
        <div style={globalPageStyle.pageBackground}>
            <main style={globalPageStyle.main}>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'INFORMATIVA COOKIE E ALTRI STRUMENTI DI TRACCIAMENTO'}/>
                    <br/>
                    <MyNormalText
                        text={'LoceM Solution utilizza i cookies e altri strumenti di tracciamento per offrirti un servizio web migliore e personalizzato. Con la presente Informativa vogliamo informarti in modo trasparente in ordine a come (e per quali finalità) utilizziamo i cookies e altri strumenti di tracciamento in questo sito web.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'PREFERENZE COOKIES'}/>
                    <br/>
                    <MyNormalText bold={true}
                                  text={'Cosa sono i cookie e gli altri strumenti di tracciamento, a cosa servono e chi li installa?'}/>
                    <br/>
                    <MyNormalText
                        text={'I cookies sono stringhe di testo di piccole dimensioni che i siti internet che visiti inviano al tuo dispositivo (es: pc, tablet, smartphone…) dove vengono memorizzati e/o letti dal browser di navigazione e che permettono al browser di inviare informazioni a chi ha installato i cookie allo scopo di offrirti una navigazione ottimale, produrre statistiche sulle visite, proporti contenuti personalizzati e pubblicità mirata. I cookie sono contenuti in vari elementi ospitati sulla pagina del sito stesso (come ad esempio banner pubblicitari, immagini, video, ecc).'}/>
                    <br/>
                    <MyNormalText
                        text={'Nel corso della navigazione su questo sito, puoi ricevere sul suo dispositivo i seguenti cookie:'}/>
                    <br/>
                    <MyNormalText
                        text={'cookie di prima parte: sono cookie inviati dal sito del Titolare, che stai visitando,'}/>
                    <br/>
                    <MyNormalText
                        text={'cookie di terza parte: sono cookie inviati da un sito diverso da quello che stai visitando, che installa i propri cookie per il tramite del sito del Titolare.'}/>
                    <br/>
                    <MyNormalText
                        text={'La gestione e la responsabilità dei cookie di prima parte è del Titolare, mentre sui cookie di terza parte il Titolare non ha alcun controllo né sull’attività della terza parte, né sulla possibilità che la stessa li modifichi nel tempo.'}/>
                    <br/>
                    <MyNormalText
                        text={'In relazione ai cookie di terza parte il Titolare agisce quale intermediario e, in tale veste, rimanda alle informative della terza parte, raggiungibile tramite apposito link accessibile dal banner (Link presente in calce a questa informativa)'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true}
                                  text={'Qual è lo scopo della presente Informativa sui Cookie e altri strumenti di tracciamento?'}/>
                    <br/>
                    <MyNormalText
                        text={'In osservanza di quanto previsto dalla vigente normativa in materia di protezione dei dati personali (in particolare il Regolamento (UE) 2016/679 sulla protezione dei dati personali “GDPR” e la normativa europea ed italiana in tema di cookie ed altri strumenti di tracciamento) desideriamo informarti in merito al trattamento dei tuoi dati personali raccolti tramite i cookie e altri strumenti di tracciamento ad essi assimilabili anche di terze parti.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'4. MODALITÀ DEL TRATTAMENTO DEI DATI.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il trattamento avverrà con strumenti elettronici, informatici o automatizzati. Il trattamento è svolto dal Titolare e dai collaboratori e/o dipendenti del Titolare in qualità di soggetti autorizzati al trattamento, nonché dai responsabili del trattamento specificamente individuati per iscritto,  nell’ambito delle rispettive funzioni ed in conformità alle istruzioni impartite dal Titolare, assicurando  l’impiego di misure idonee per la sicurezza dei dati trattati e garantendone la riservatezza. Secondo le norme del Regolamento, i trattamenti effettuati dal titolare saranno improntati ai principi di  liceità, correttezza, trasparenza, limitazione delle finalità e della conservazione, minimizzazione dei dati,  esattezza, integrità e riservatezza.  \n' +
                            'I dati saranno sempre trattati nel massimo rispetto del principio della riservatezza anche nel caso di gestione  degli stessi da parte di terzi soggetti espressamente incaricati dal Titolare.  \n' +
                            'I Suoi dati non sono soggetti ad alcun processo decisionale automatizzato.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il trattamento verrà improntato ai principi di correttezza, liceità e trasparenza, nonché di tutela della tua riservatezza e di tutela dei tuoi diritti.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Chi è il Titolare del trattamento?'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Titolare del trattamento dei tuoi dati personali è la società LOCEM SOLUTIONS S.R.L.S. (di seguito il Titolare) con  sede legale in Via Roma, 56 – 37063 Isola della Scala (VR), C.F. e P. IVA n. 04967430234, R.E.A.  VR-460035, cap. soc. i.v. € 3.200,00, PEC locemsolutions@pro-pec.it.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true}
                                  text={'Quali cookies e altri strumenti di tracciamento utilizza questo sito? Per quali fini, su che base giuridica? È obbligatorio fornire i dati? Cosa succede se non li fornisco?'}/>
                    <br/>
                    <MyNormalText
                        text={'I cookie e gli altri strumenti di tracciamento usati da questo sito sono classificabili secondo le seguenti macro categorie:'}/>
                    <br/>
                    <MyNormalText text={'Cookie tecnici, che servono a far funzionare il sito;'}/>
                    <br/>
                    <MyNormalText
                        text={'Cookie di profilazione, che servono a tracciare il comportamento dell\'utente per analizzarlo in modo da offrirgli servizi basati sulle sue preferenze.'}/>
                    <br/>
                    <MyNormalText text={'Questa App utilizza solo cookie tecnici, come di seguito individuato:'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true}
                                  text={'Cookie Tecnici (inclusi altri identificatori tecnici), che non richiedono il suo consenso'}/>
                    <br/>
                    <MyNormalText
                        text={'I cookie tecnici ed altri indicatori tecnici sono utilizzati al fine di effettuare la trasmissione di una comunicazione su una rete di comunicazione elettronica o di fornire un servizio informatico da te esplicitamente richiesto. In questa categoria rientrano i cookie, che perseguono le finalità rispettivamente indicate:'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Base Giuridica.'}/>
                    <br/>
                    <MyNormalText
                        text={'Per i cookie tecnici la base giuridica risiede nell’esecuzione di un contratto o di misure precontrattuali (art. 6.b del GDPR) poiché necessari per rendere utilizzabile il sito e il servizio richiesto da parte degli utenti. L’utilizzo di tali cookie non richiede il tuo consenso.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Necessità del conferimento dei dati e conseguenze.'}/>
                    <br/>
                    <MyNormalText
                        text={'Il conferimento dei dati raccolti attraverso i cookie sopra descritti non è obbligatorio, tuttavia è necessario per il collegamento al sito e la fornitura del servizio. In assenza parziale o totale di tale conferimento, non si potrà utilizzare il sito o potranno esserci problemi nell’utilizzo.'}/>
                    <br/>
                    <MyNormalText
                        text={'L’elenco dei cookie Tecnici utilizzati da questo sito con le relative caratteristiche (es: caratteristiche, tempi di conservazione dei dati etc.) è disponibile in calce a questa informativa.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true}
                                  text={'Come gestire il consenso dei cookie e altri strumenti di tracciamento?'}/>
                    <br/>
                    <MyNormalText
                        text={'Potrai gestire il tuo consenso relativo alle singole categorie di cookies e altri strumenti di tracciamento operando direttamente nell\'apposita area cookie raggiungibile dal banner o presente in calce.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Come disattivare tutti i cookie col browser?'}/>
                    <br/>
                    <MyNormalText
                        text={'LoceM utilizza solo cookie tecnici, in riferimento ai quali non serve il consenso dell’utente e in assenza dei quali il servizio non può essere garantito.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true}
                                  text={'I dati personali sono trasferiti al di fuori dell’Unione Europea (UE)?'}/>
                    <br/>
                    <MyNormalText
                        text={'Alcuni dati raccolti dai cookie possono essere trasferiti all’estero in ubicazioni poste al di fuori dell’Unione Europea. Tale trasferimento verrà comunque effettuato nel rispetto delle garanzie prescritte dal GDPR per questo tipo di attività (artt. da 45 a 49). Quali ad esempio: trasferimento verso imprese ubicate in Paesi per i quali risulta riconosciuta l’esistenza di garanzie di protezione dei dati personali equiparabili a quelle del GDPR (Paesi in White List); oppure verso imprese con le quali sono state sottoscritte specifiche clausole contrattuali di tutela dei dati personali approvate dalla Commissione Europea o dall’Autorità Garante, o che adottano norme vincolanti di impresa approvate dall’Autorità Garante; oppure quando il trasferimento è necessario per l’esecuzione del contratto concluso con l’interessato o per eseguire una tua richiesta; oppure avviene in base a specifiche deroghe previste dal GDPR. È possibile avere maggiori informazioni, su richiesta, presso il Titolare ai contatti sopraindicati.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Per quanto tempo vengono conservati i dati?'}/>
                    <br/>
                    <MyNormalText
                        text={'Il periodo di conservazione dei dati raccolti attraverso l’utilizzo dei cookie e degli altri strumenti di tracciamento è pari a 1 anno.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Chi può conoscere i tuoi dati? A chi li comunichiamo?'}/>
                    <br/>
                    <MyNormalText
                        text={'I dati raccolti mediante i cookie potranno essere utilizzati dai collaboratori autorizzati dal Titolare, quali ad esempio gli addetti alla manutenzione e gestione del sito e all’amministrazione. Potranno essere utilizzati anche da eventuali terze parti (fornitori di servizi, di infrastrutture informatiche coinvolti nella gestione e supervisione del sito e simili) che operano per conto del Titolare e sono stati debitamente nominati responsabili del trattamento secondo quanto previsto dall’art 28 del GDPR. Con il tuo consenso, potranno essere utilizzati anche dalle terze parti che installano i loro cookie sul sito del Titolare.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Quali sono i tuoi diritti in qualità di interessato?'}/>
                    <br/>
                    <MyNormalText
                        text={'Il GDPR le riconosce i seguenti diritti in relazione ai tuoi dati personali che potrai esercitare nei limiti e in conformità a quanto previsto dalla normativa:'}/>
                    <br/>
                    <MyNormalText text={'Diritto di accesso ai tuoi dati personali (art. 15);'}/>
                    <br/>
                    <MyNormalText text={'Diritto di rettifica (art. 16);'}/>
                    <br/>
                    <MyNormalText text={'Diritto di cancellazione (diritto all’oblio) (art. 17);'}/>
                    <br/>
                    <MyNormalText text={'Diritto di limitazione di trattamento (art. 18);'}/>
                    <br/>
                    <MyNormalText text={'Diritto alla portabilità dei dati (art. 20);'}/>
                    <br/>
                    <MyNormalText
                        text={'Diritto di opposizione (art. 21): l’interessato ha il diritto di opporsi in qualsiasi momento, per motivi connessi alla situazione particolare, al trattamento dei dati personali che lo riguardano basato sul legittimo interesse, compresa la profilazione sulla base di esso. Il Titolare si astiene dal trattamento salvo che dimostri l\'esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgono sugli interessi, sui diritti e sulle libertà dell\'interessato oppure per l\'accertamento, l\'esercizio o la difesa di un diritto in sede giudiziaria;'}/>
                    <br/>
                    <MyNormalText
                        text={'Diritto di opporsi a una decisione basata unicamente sul trattamento automatizzato (art. 22);'}/>
                    <br/>
                    <MyNormalText
                        text={'Diritto di revocare, in qualsiasi momento, il consenso rilasciato, senza pregiudicare la liceità del trattamento basata sul consenso prestato prima della revoca.'}/>
                    <br/>
                    <MyNormalText
                        text={'Potrai ottenere ciò inviando una richiesta scritta indirizzata al Titolare del trattamento all’indirizzo postale o tramite e-mail, come indicato in precedenza.'}/>
                    <br/>
                    <MyNormalText text={'Inoltre:'}/>
                    <br/>
                    <MyNormalText
                        text={'Diritto di proporre reclamo all’Autorità Garante della protezione dei dati (www.garanteprivacy.it) qualora ritenessi che il trattamento dei tuoi dati sia contrario alla normativa in vigore (art 77) o agire in sede giudiziale (art.79)'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Come vengono protetti i dati personali?'}/>
                    <br/>
                    <MyNormalText
                        text={'I dati personali saranno trattati utilizzando misure di sicurezza tecniche e organizzative adeguate alla natura dei dati per assicurarne l’integrità e la riservatezza e proteggerli contro i rischi di intrusione illecita, perdita, alterazione, o divulgazione a terzi non autorizzati a trattarli.'}/>
                </div>
                <div style={globalPageStyle.mainSection}>
                    <MyNormalText bold={true} text={'Aggiornamenti'}/>
                    <br/>
                    <MyNormalText
                        text={'Il Titolare si riserva il diritto di modificare e aggiornare in qualsiasi momento la presente informativa. Le variazioni si applicheranno a partire dalla loro comunicazione o pubblicazione. È pertanto necessario che verifichi con regolarità l’Informativa in vigore.'}/>
                    <br/>
                    <MyNormalText text={'Dettaglio cookie utilizzati:'}/>
                </div>
                <div style={{...globalPageStyle.mainSection, ...{alignSelf: 'center'}}}>
                    <MyNormalText bold={true} text={'Cookie tecnici o di funzionalità'}/>
                    <br/>
                    <table>
                        <tr>
                            <th><MyNormalText text={'Tipo'}/></th>
                            <th><MyNormalText text={'Finalità'}/></th>
                            <th><MyNormalText text={'Identificativo del cookies'}/></th>
                            <th><MyNormalText text={'Durata'}/></th>
                        </tr>
                        <tr style={{background: PRIMARY_COLOR_FIRST_ALTERNATIVE, borderRadius: GLOBAL_BORDER_RADIUS}}>
                            <td><MyNormalText text={'HTML Local Storage'}/></td>
                            <td><MyNormalText text={'Fruizione della dashboard'}/></td>
                            <td><MyNormalText text={'User'}/></td>
                            <td><MyNormalText text={'Persistent'}/></td>
                        </tr>
                    </table>
                </div>
            </main>
        </div>
    );
}

export default LocemWebsiteCookiesPolicy;
