import React, {useRef, useState} from "react";
import {SECONDARY_COLOR, websitePages} from "../../../costants";
import MainButton from "../../commons/mainButton";
import Languages from "../header/languages";
import useMyEscapeListener from "../../../global/listeners/useMyEscapeListener";
import useMyClickOutListener from "../../../global/listeners/useMyClickOutListener";

function MyMenu({languageState, isMobileView, location, navigate}) {

    const pathname = location.pathname

    const [isOpen, setIsOpen] = useState(false)

    const menuRef = useRef()

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    useMyEscapeListener(() => setIsOpen(false))
    useMyClickOutListener(menuRef, () => setIsOpen(false))

    const sidebarStyle = {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        top: 0,
        right: -300,
        width: 250,
        padding: 20,
        paddingTop: 40,
        height: '100vh',
        background: SECONDARY_COLOR,
        transition: 'right 0.3s ease',
        zIndex: 1
    }

    const sidebarOpenStyle = {
        right: '0'
    }

    function handleNavigateFromMobile(navigateTo) {
        toggleMenu()
        navigate(navigateTo)
    }

    if (isMobileView) {
        return (
            <div>
                <div ref={menuRef}>
                    <MainButton image={require('../../../imgs/home/menu.png')}
                                action={toggleMenu}
                                imageHeight={25}
                                imageWidth={25}/>
                    <div style={{...sidebarStyle, ...(isOpen ? sidebarOpenStyle : {})}}>
                        <MainButton text={'menu.about.us'}
                                    fontSize={18}
                                    action={() => handleNavigateFromMobile(websitePages.HOME)}
                                    language={languageState.language}
                                    isTranslated={true}/>
                        <MainButton text={'menu.values'}
                                    fontSize={18}
                                    action={() => handleNavigateFromMobile(websitePages.VALUES)}
                                    language={languageState.language}
                                    isTranslated={true}/>
                        <MainButton text={'menu.join'}
                                    fontSize={18}
                                    action={() => handleNavigateFromMobile(websitePages.CONTACT_US)}
                                    language={languageState.language}
                                    isTranslated={true}/>
                        <MainButton text={'menu.login'}
                                    fontSize={18}
                                    action={() => handleNavigateFromMobile(websitePages.DASHBOARD)}
                                    language={languageState.language}
                                    isTranslated={true}/>
                    </div>
                </div>
            </div>
        )
    }

    const currentPageStyle = {borderBottom: '2px solid black'}
    const notCurrentPageStyle = {borderBottom: '2px'}

    return (
        <div style={{display: 'flex', gap: 10}}>
            <Languages languageState={languageState}/>
            <div style={pathname === websitePages.HOME ? currentPageStyle : notCurrentPageStyle}>
                <MainButton text={'menu.about.us'}
                            fontSize={18}
                            action={() => navigate(websitePages.HOME)}
                            language={languageState.language}
                            isTranslated={true}/>
            </div>
            <div style={pathname.includes(websitePages.VALUES) ? currentPageStyle : notCurrentPageStyle}>
                <MainButton text={'menu.values'}
                            fontSize={18}
                            action={() => navigate(websitePages.VALUES)}
                            language={languageState.language}
                            isTranslated={true}/>
            </div>
            <div style={pathname.includes(websitePages.CONTACT_US) ? currentPageStyle : notCurrentPageStyle}>
                <MainButton text={'menu.join'}
                            fontSize={18}
                            action={() => navigate(websitePages.CONTACT_US)}
                            language={languageState.language}
                            isTranslated={true}/>
            </div>
            <div
                style={pathname.includes(websitePages.DASHBOARD) || pathname.includes(websitePages.LOGIN) ? currentPageStyle : notCurrentPageStyle}>
                <MainButton text={'menu.login'}
                            fontSize={18}
                            action={() => navigate(websitePages.DASHBOARD)}
                            language={languageState.language}
                            isTranslated={true}/>
            </div>
        </div>
    )
}

export default MyMenu
