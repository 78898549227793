import React, {createContext, useEffect, useState} from "react";
import {onAuthStateChanged} from 'firebase/auth';
import {auth, functions} from "../../firebase";
import "firebase/auth";
import {httpsCallable} from "firebase/functions";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {

    const [rentUser, setRentUser] = useState(null)
    const [loadingUser, setLoadingUser] = useState(false)

    function fetchUser(rentId) {
        setLoadingUser(true)
        const rentUserView = httpsCallable(functions, 'rentUserView')
        rentUserView({rentId: rentId})
            .then(async (result) => {
                const rentUser = result.data.rentUser
                setRentUser(rentUser)
                setLoadingUser(false)
            })
            .catch(() => {
                setLoadingUser(false)
            })
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUser(user.uid)
            } else {
                setRentUser(null)
            }
        })

        return () => unsubscribe()
    }, [])

    const reloadUser = async () => {
        fetchUser(rentUser.rentId)
    }

    return (
        <AuthContext.Provider value={{rentUser, reloadUser, loadingUser}}>
            {children}
        </AuthContext.Provider>
    )
}
