import React, {useEffect, useState} from "react";
import ItemImage from "./item/itemImage";
import MyNormalText from "../commons/myNormalText";
import MyIcon from "../commons/myIcon";
import MainButton from "../commons/mainButton";
import {
    backgroundPalette,
    dashboardHistoryLinks,
    dashboardMyItemsLinks,
    dashboardPremiumLinks,
    dashboardSectionLinks,
    dashboardSectionNames,
    dashboardSuperUserAdminLinks,
    DISABLED,
    GLOBAL_BORDER_RADIUS,
    HIGHLIGHT_COLOR
} from "../../costants";
import {getUrlCoverImg} from "../../global/firebaseStorageRepo";
import {HexColorPicker} from "react-colorful";
import {generatePremiumPaletteCustomColor} from "../../global/premiumPalette";
import {useGlobalState} from "../../global/globalState";

function DashboardLeftSection({
                                  rentUser,
                                  premiumPalette,
                                  changePremiumPalette,
                                  changeGlobalFontSizeCoefficient,
                                  areThereNewBookings,
                                  newBookings,
                                  sectionSelected,
                                  changeSection,
                                  isMobileView,
                                  accountType
                              }) {

    const firstPalette = backgroundPalette.firstPalette
    const secondPalette = backgroundPalette.secondPalette
    const thirdPalette = backgroundPalette.thirdPalette

    const primaryColor = premiumPalette.primaryColor
    const secondaryColor = premiumPalette.secondaryColor
    const primaryColorFirstAlternative = premiumPalette.primaryColorFirstAlternative

    const {globalState, setGlobalState} = useGlobalState()

    const [coverImage, setCoverImage] = useState('')
    const [openPersonalizeSection, setOpenPersonalizeSection] = useState(false)
    const [openCustomPaletteSection, setOpenCustomPaletteSection] = useState(false)

    const defaultSizeButtons = 16

    useEffect(() => {
        getUrlCoverImg(rentUser.rentId, rentUser.cover)
            .then(urlCoverImage => setCoverImage(urlCoverImage))
    }, [rentUser])

    if (isMobileView) {
        return (
            <div
                style={{...pageStyle.containerButtonsMobileView, backgroundColor: primaryColorFirstAlternative}}>
                <div style={{marginBottom: 20}}>
                    <MainButton image={require('../../imgs/menu/new-booking.png')}
                                imageWidth={defaultSizeButtons}
                                imageHeight={defaultSizeButtons}
                                backgroundColor={sectionSelected.includes(dashboardSectionLinks.NEW_BOOKINGS) ? secondaryColor : (areThereNewBookings ? HIGHLIGHT_COLOR : undefined)}
                                action={() => changeSection(dashboardSectionLinks.NEW_BOOKINGS)}/>
                    <MainButton image={require('../../imgs/accounttype/premium.png')}
                                imageWidth={defaultSizeButtons}
                                imageHeight={defaultSizeButtons}
                                backgroundColor={!accountType.isAccountPremium ? DISABLED : (sectionSelected.includes(dashboardSectionLinks.PREMIUM) ? secondaryColor : undefined)}
                                disabled={!accountType.isAccountPremium}
                                action={() => changeSection(dashboardSectionLinks.PREMIUM + '/' + dashboardPremiumLinks.PREMIUM_CALENDAR)}/>
                    <MainButton image={require('../../imgs/menu/archive.png')}
                                imageWidth={defaultSizeButtons}
                                imageHeight={defaultSizeButtons}
                                backgroundColor={sectionSelected.includes(dashboardSectionLinks.HISTORY) ? secondaryColor : undefined}
                                action={() => changeSection(dashboardSectionLinks.HISTORY + '/' + dashboardHistoryLinks.ARCHIVE)}/>
                    <MainButton image={require('../../imgs/menu/items.png')}
                                imageWidth={defaultSizeButtons}
                                imageHeight={defaultSizeButtons}
                                backgroundColor={sectionSelected.includes(dashboardSectionLinks.MY_ITEMS) ? secondaryColor : undefined}
                                action={() => changeSection(dashboardSectionLinks.MY_ITEMS + '/' + dashboardMyItemsLinks.VETRINA)}/>
                    <MainButton image={require('../../imgs/menu/profile.png')}
                                imageWidth={defaultSizeButtons}
                                imageHeight={defaultSizeButtons}
                                backgroundColor={sectionSelected.includes(dashboardSectionLinks.PROFILE) ? secondaryColor : undefined}
                                action={() => changeSection(dashboardSectionLinks.PROFILE)}/>
                    {accountType.isAccountSuperAdmin ?
                        <MainButton image={require('../../imgs/accounttype/super-user-admin.png')}
                                    imageWidth={defaultSizeButtons}
                                    imageHeight={defaultSizeButtons}
                                    backgroundColor={sectionSelected.includes(dashboardSectionLinks.SUPER_USER_ADMIN) ? secondaryColor : undefined}
                                    action={() => changeSection(dashboardSectionLinks.SUPER_USER_ADMIN + '/' + dashboardSuperUserAdminLinks.CREATE_COMMUNICATION)}/> :
                        <></>}
                </div>
            </div>
        )
    }

    function calculateAccountBannerType() {
        if (accountType.isAccountSuperAdmin) {
            return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: 5}}>
                        <MyNormalText text={'Super User Admin'}/>
                    </div>
                    <MyIcon icon={require('../../imgs/accounttype/super-user-admin.png')}
                            name={'Premium logo'}
                            width={20}
                            height={20}/>
                </div>
            )
        } else if (accountType.isAccountPremium) {
            return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: 5}}>
                        <MyNormalText text={'Account Premium'}/>
                    </div>
                    <MyIcon icon={require('../../imgs/accounttype/premium.png')}
                            name={'Premium logo'}
                            width={20}
                            height={20}/>
                </div>
            )
        }
        return <MyNormalText text={'Account Business'}/>
    }

    return (
        <div style={{...pageStyle.containerButtons, backgroundColor: primaryColorFirstAlternative}}>
            <ItemImage imageUrl={coverImage}/>
            <div style={pageStyle.title}>
                <MyNormalText fontSize={18}
                              bold={true}
                              text={rentUser.name}/>
                {calculateAccountBannerType()}
            </div>
            <div style={{flex: 1, flexDirection: 'column'}}>
                <MainButton
                    text={dashboardSectionNames.NEW_BOOKINGS + (areThereNewBookings ? (' (' + (newBookings.length) + ')') : '')}
                    textAlignStart={true}
                    textUnderlain={sectionSelected.includes(dashboardSectionLinks.NEW_BOOKINGS)}
                    firstImage={require('../../imgs/menu/new-booking.png')}
                    firstImageWidth={defaultSizeButtons}
                    firstImageHeight={defaultSizeButtons}
                    backgroundColor={areThereNewBookings ? HIGHLIGHT_COLOR : undefined}
                    action={() => changeSection(dashboardSectionLinks.NEW_BOOKINGS)}/>
                <MainButton text={dashboardSectionNames.PREMIUM}
                            textAlignStart={true}
                            textUnderlain={sectionSelected.includes(dashboardSectionLinks.PREMIUM)}
                            firstImage={require('../../imgs/accounttype/premium.png')}
                            firstImageWidth={defaultSizeButtons}
                            firstImageHeight={defaultSizeButtons}
                            backgroundColor={!accountType.isAccountPremium ? DISABLED : undefined}
                            disabled={!accountType.isAccountPremium}
                            action={() => changeSection(dashboardSectionLinks.PREMIUM + '/' + dashboardPremiumLinks.PREMIUM_CALENDAR)}/>
                <MainButton text={dashboardSectionNames.HISTORY}
                            textAlignStart={true}
                            textUnderlain={sectionSelected.includes(dashboardSectionLinks.HISTORY)}
                            firstImage={require('../../imgs/menu/archive.png')}
                            firstImageWidth={defaultSizeButtons}
                            firstImageHeight={defaultSizeButtons}
                            action={() => changeSection(dashboardSectionLinks.HISTORY + '/' + dashboardHistoryLinks.ARCHIVE)}/>
                <MainButton text={dashboardSectionNames.MY_ITEMS}
                            textAlignStart={true}
                            textUnderlain={sectionSelected.includes(dashboardSectionLinks.MY_ITEMS)}
                            firstImage={require('../../imgs/menu/items.png')}
                            firstImageWidth={defaultSizeButtons}
                            firstImageHeight={defaultSizeButtons}
                            action={() => changeSection(dashboardSectionLinks.MY_ITEMS + '/' + dashboardMyItemsLinks.VETRINA)}/>
                <MainButton text={dashboardSectionNames.PROFILE}
                            textAlignStart={true}
                            textUnderlain={sectionSelected.includes(dashboardSectionLinks.PROFILE)}
                            firstImage={require('../../imgs/menu/profile.png')}
                            firstImageWidth={defaultSizeButtons}
                            firstImageHeight={defaultSizeButtons}
                            action={() => changeSection(dashboardSectionLinks.PROFILE)}/>
                {accountType.isAccountSuperAdmin ?
                    <MainButton text={dashboardSectionNames.SUPER_USER_ADMIN}
                                textAlignStart={true}
                                textUnderlain={sectionSelected.includes(dashboardSectionLinks.SUPER_USER_ADMIN)}
                                firstImage={require('../../imgs/accounttype/super-user-admin.png')}
                                firstImageWidth={defaultSizeButtons}
                                firstImageHeight={defaultSizeButtons}
                                action={() => changeSection(dashboardSectionLinks.SUPER_USER_ADMIN + '/' + dashboardSuperUserAdminLinks.CREATE_COMMUNICATION)}/> :
                    <></>}
                {accountType.isAccountPremium ?
                    <div style={pageStyle.containerPalette}>
                        <div style={{...pageStyle.divisor, backgroundColor: primaryColor}}/>
                        <MainButton text={'Personalizza'}
                                    textAlignStart={true}
                                    firstImage={require('../../imgs/menu/custom-palette-style.png')}
                                    firstImageWidth={defaultSizeButtons}
                                    firstImageHeight={defaultSizeButtons}
                                    action={() => setOpenPersonalizeSection(!openPersonalizeSection)}
                                    secondaryImage={openPersonalizeSection ? require('../../imgs/actions/arrow-up.png') : require('../../imgs/actions/arrow-down.png')}
                                    secondaryImageHeight={20}
                                    secondaryImageWidth={20}/>
                        {openPersonalizeSection ?
                            <div style={{marginTop: 10}}>
                                <div style={{...pageStyle.paletteContainer, backgroundColor: primaryColor}}>
                                    <MyNormalText text={'Tema'}/>
                                    <MainButton text={'Default'}
                                                action={() => changePremiumPalette(firstPalette)}
                                                firstImage={require('../../imgs/menu/custom-palette-day.png')}
                                                textAlignStart={true}
                                                firstImageWidth={defaultSizeButtons}
                                                firstImageHeight={defaultSizeButtons}/>
                                    <MainButton text={'Alternativo'}
                                                action={() => changePremiumPalette(secondPalette)}
                                                firstImage={require('../../imgs/menu/custom-palette-alternative.png')}
                                                textAlignStart={true}
                                                firstImageWidth={defaultSizeButtons}
                                                firstImageHeight={defaultSizeButtons}/>
                                    <MainButton text={'Luci soffuse'}
                                                action={() => changePremiumPalette(thirdPalette)}
                                                firstImage={require('../../imgs/menu/custom-palette-night.png')}
                                                textAlignStart={true}
                                                firstImageWidth={defaultSizeButtons}
                                                firstImageHeight={defaultSizeButtons}/>
                                    <MainButton text={'Custom'}
                                                action={() => setOpenCustomPaletteSection(!openCustomPaletteSection)}
                                                firstImage={openCustomPaletteSection ? require('../../imgs/actions/arrow-up.png') : require('../../imgs/actions/arrow-down.png')}
                                                textAlignStart={true}
                                                firstImageWidth={defaultSizeButtons}
                                                firstImageHeight={defaultSizeButtons}/>
                                    {openCustomPaletteSection ?
                                        <div style={{marginTop: 10}}>
                                            <HexColorPicker color={premiumPalette.primaryColor}
                                                            style={{width: '100%'}}
                                                            onChange={(newColor) => changePremiumPalette(generatePremiumPaletteCustomColor(newColor))}/>
                                        </div> :
                                        <></>}
                                </div>
                                <div style={{...pageStyle.paletteContainer, backgroundColor: primaryColor}}>
                                    <MyNormalText text={'Testo'}/>
                                    <MainButton text={'ABCDE'}
                                                action={() => changeGlobalFontSizeCoefficient(-2)}
                                                textUnderlain={globalState.premiumFontSizeCoefficient === -2}
                                                fontSize={14}/>
                                    <MainButton text={'ABCDE'}
                                                action={() => changeGlobalFontSizeCoefficient(0)}
                                                textUnderlain={globalState.premiumFontSizeCoefficient === 0}
                                                fontSize={16}/>
                                    <MainButton text={'ABCDE'}
                                                action={() => changeGlobalFontSizeCoefficient(2)}
                                                textUnderlain={globalState.premiumFontSizeCoefficient === 2}
                                                fontSize={18}/>
                                    <MainButton text={'ABCDE'}
                                                action={() => changeGlobalFontSizeCoefficient(4)}
                                                textUnderlain={globalState.premiumFontSizeCoefficient === 4}
                                                fontSize={20}/>
                                </div>
                            </div> :
                            <></>}
                    </div> :
                    <></>}
            </div>
            <div style={pageStyle.containerVersion}>
                <MyNormalText text={process.env.REACT_APP_MODE + ' mode'}
                              fontSize={14}/>
                <MyNormalText text={'v' + process.env.REACT_APP_VERSION}
                              fontSize={14}/>
            </div>
        </div>
    )
}

const pageStyle = {
    containerButtons: {
        display: 'flex',
        left: 0,
        top: 0,
        bottom: 0,
        position: 'fixed',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        padding: 20,
        height: '100vh',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        width: 200
    },
    containerButtonsMobileView: {
        display: 'flex',
        left: 0,
        top: 0,
        bottom: 0,
        position: 'fixed',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        padding: 20,
        height: '100vh',
        width: 60
    },
    divisor: {
        width: '100%',
        height: 2,
        marginTop: 10,
        marginBottom: 10
    },
    title: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'column',
        marginTop: 10,
        marginBottom: 20
    },
    paletteContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        borderRadius: GLOBAL_BORDER_RADIUS,
        marginBottom: 10
    },
    containerPalette: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10
    },
    containerVersion: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 30
    }
}

export default DashboardLeftSection
