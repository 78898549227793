import {GLOBAL_BORDER_RADIUS} from "../../costants";

export const globalElementInputStyle = {
    customInput: {
        border: '3px solid ' + 'white',
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: 'white',
        padding: 5,
        fontSize: 16,
        outline: 'none',
        minWidth: '100%',
        boxSizing: 'border-box',
        fontFamily: 'Poppins'
    },
    customInputTextArea: {
        border: '3px solid ' + 'white',
        borderRadius: GLOBAL_BORDER_RADIUS,
        backgroundColor: 'white',
        padding: 5,
        fontSize: 16,
        outline: 'none',
        minWidth: '100%',
        boxSizing: 'border-box',
        fontFamily: 'Poppins',
        width: '100%',
        height: '120px',
        resize: 'none'
    }
}
