export function executeAt6AM(fetchCommunications) {
    const now = new Date()
    const nextSixAM = new Date()
    nextSixAM.setHours(6, 0, 0, 0)
    if (now > nextSixAM) {
        nextSixAM.setDate(nextSixAM.getDate() + 1)
    }

    const timeUntilNextSixAM = nextSixAM.getTime() - now.getTime()

    const timer = setTimeout(() => {
        fetchCommunications()

        const interval = setInterval(() => {
            fetchCommunications()

        }, 24 * 60 * 60 * 1000)
        return () => clearInterval(interval)

    }, timeUntilNextSixAM)
    return () => clearTimeout(timer)
}
