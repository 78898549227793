import React, {useEffect, useState} from "react";
import {getUrlImg} from "../../../global/firebaseStorageRepo";
import MyNormalText from "../../commons/myNormalText";
import {ARCHIVED_COLOR, EURO_VALUE, GLOBAL_BORDER_RADIUS, SELLABLE_COLOR} from "../../../costants";
import ItemDetail from "./itemDetail";
import {getHoverStyle} from "../../../global/style/globalButtonHoverShadowStyle";

const DEFAULT_WIDTH = 180
const BORDERS_RADIUS = GLOBAL_BORDER_RADIUS

function ItemBox({
                     rentUser,
                     item,
                     fontSizeTitle,
                     itemNameOverflow,
                     fontSizeInfo,
                     premiumPalette,
                     openOperations,
                     refreshItems,
                     hidePrices,
                     blockOpenDetail,
                     width,
                     backgroundColor,
                     imageHeight,
                     injectFirstElement
                 }) {

    const rentId = rentUser.rentId
    const rentSlotConfiguration = rentUser.rentSlotConfiguration
    const itemId = item?.id
    const imageName = item?.cover
    const itemName = item?.name
    const category = item?.category
    const assignedRentCode = item?.assignedRentCode ? item.assignedRentCode : '-'
    const prices = item?.prices
    const size = item?.size
    const isSellable = item?.isSellable
    const itemFontSizeTitle = fontSizeTitle ? fontSizeTitle : 16
    const itemFontSizeInfo = fontSizeInfo ? fontSizeInfo : 14

    const [openItemDetail, setOpenItemDetail] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    const [urlCover, setUrlCover] = useState(null)
    const [loadingImage, setLoadingImage] = useState(true)

    const itemNotAvailable = item.isAvailable === false

    useEffect(() => {
        if (!localStorage.getItem(itemName)) {
            getUrlImg(rentId, itemName, 'cover', imageName)
                .then(url => {
                    localStorage.setItem(itemName, url)
                    setUrlCover(url)
                })
        } else {
            const imageUrl = localStorage.getItem(itemName)
            setUrlCover(imageUrl)
        }
    }, [])

    function handleOpenItemDetail() {
        setOpenItemDetail(true)
    }

    const imageStyle = {
        ...pageStyle.image,
        opacity: loadingImage ? 0 : (itemNotAvailable ? 0.3 : 1)
    }

    const containerItemImageStyle = {
        ...pageStyle.containerItemImage,
        height: imageHeight ? imageHeight : 150
    }

    const containerInfoItemStyle = {
        ...pageStyle.containerInfoItem,
        backgroundColor: backgroundColor ? backgroundColor : premiumPalette.primaryColor
    }

    const containerStyle = {
        ...pageStyle.container,
        cursor: blockOpenDetail ? undefined : 'pointer',
        width: width ? width : DEFAULT_WIDTH,
        transform: (isHovered && !blockOpenDetail) ? 'scale(1.02)' : undefined
    }

    return (
        <>
            <div key={itemId}
                 onClick={handleOpenItemDetail}
                 onMouseEnter={() => setIsHovered(true)}
                 onMouseLeave={() => setIsHovered(false)}
                 style={containerStyle}>
                {blockOpenDetail ?
                    <></> :
                    <div style={getHoverStyle(isHovered, BORDERS_RADIUS)}/>}
                <div style={containerItemImageStyle}>
                    <img style={imageStyle}
                         onLoad={() => setLoadingImage(false)}
                         src={urlCover}
                         alt={'JPG Image Cover'}/>
                    <div style={pageStyle.absoluteImageContainer}>
                        {itemNotAvailable ?
                            <div style={pageStyle.alreadyOrderedContainer}>
                                <MyNormalText text={'Già prenotato'}
                                              fontSize={14}
                                              color={'white'}/>
                            </div> :
                            <></>}
                        {isSellable ?
                            <div style={pageStyle.sellableContainer}>
                                <MyNormalText text={'In vendita'}
                                              fontSize={14}/>
                            </div> :
                            <></>}
                        {item.archived ?
                            <div style={pageStyle.containerHighlighted}>
                                <MyNormalText text={'Archiviato'}
                                              fontSize={14}/>
                            </div> :
                            <></>}
                    </div>
                </div>
                <div style={containerInfoItemStyle}>
                    <div style={pageStyle.containerFlexColumn}>
                        <MyNormalText text={itemName}
                                      fontSize={itemFontSizeTitle}
                                      textOverflow={itemNameOverflow}
                                      bold={true}/>
                        <MyNormalText text={itemId}
                                      fontSize={itemFontSizeInfo}/>
                    </div>
                    <div style={pageStyle.containerFlexColumn}>
                        <MyNormalText text={assignedRentCode}
                                      fontSize={itemFontSizeInfo}/>
                        <MyNormalText text={category + ', ' + size}
                                      fontSize={itemFontSizeInfo}/>
                    </div>
                    {hidePrices ?
                        <></> :
                        <div>
                            {rentSlotConfiguration.map((slot, index) => {
                                const slotFound = item.singleDayPrices.find(price => price.type === slot.type)
                                return (
                                    <div key={index.toString()}>
                                        <MyNormalText text={slot.description + ': ' + EURO_VALUE + slotFound.price}
                                                      fontSize={itemFontSizeInfo}/>
                                    </div>
                                )
                            })}
                        </div>}
                    {hidePrices ?
                        <></> :
                        <div>
                            {prices.map((price, index) => {
                                return (
                                    <div key={index.toString()}>
                                        <MyNormalText text={'Giorno ' + (index + 1) + ': ' + EURO_VALUE + price.cost}
                                                      fontSize={itemFontSizeInfo}/>
                                    </div>
                                )
                            })}
                        </div>}
                </div>
                {injectFirstElement}
            </div>
            {!blockOpenDetail && openItemDetail ?
                <ItemDetail rentUser={rentUser}
                            openOperations={openOperations}
                            premiumPalette={premiumPalette}
                            itemId={itemId}
                            refreshItems={refreshItems}
                            setIsOpen={setOpenItemDetail}/> :
                <></>}
        </>
    )
}

const pageStyle = {
    container: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        transition: 'transform 0.5s ease',
        maxWidth: '220px'
    },
    image: {
        borderTopLeftRadius: BORDERS_RADIUS,
        borderTopRightRadius: BORDERS_RADIUS,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'opacity 0.5s ease-in-out'
    },
    containerInfoItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        borderEndEndRadius: BORDERS_RADIUS,
        borderEndStartRadius: BORDERS_RADIUS,
        padding: 5,
        paddingTop: 10
    },
    containerFlexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    containerItemImage: {
        width: '100%'
    },
    containerHighlighted: {
        paddingRight: 3,
        paddingLeft: 3,
        backgroundColor: ARCHIVED_COLOR,
        borderRadius: BORDERS_RADIUS
    },
    sellableContainer: {
        paddingRight: 3,
        paddingLeft: 3,
        backgroundColor: SELLABLE_COLOR,
        borderRadius: BORDERS_RADIUS
    },
    alreadyOrderedContainer: {
        paddingRight: 3,
        paddingLeft: 3,
        backgroundColor: 'black',
        borderRadius: BORDERS_RADIUS
    },
    absoluteImageContainer: {
        position: 'absolute',
        top: 0
    }
}

export default ItemBox
