import React from "react";
import ChatDetail from "./chatDetail";
import MainButton from "../../commons/mainButton";

function ChatFooter({
                        chatFooterRef,
                        premiumPalette,
                        rentUser,
                        activeChats,
                        openChat,
                        handleToggleChat,
                        handleDeleteChat
                    }) {

    return (
        <div ref={chatFooterRef}
             style={pageStyle.container}>
            <div style={{display: 'flex', flexDirection: 'row', overflowX: 'auto'}}>
                {activeChats.map(chat => {
                    const openThisChat = chat.chatId === openChat.chatId;
                    return (
                        <div key={chat.chatId}
                             style={{margin: 10}}>
                            <ChatDetail rentUser={rentUser}
                                        premiumPalette={premiumPalette}
                                        chat={chat}
                                        handleCloseChat={handleToggleChat}
                                        isActive={chat.chatId === openChat.chatId}/>
                            <div style={{position: 'relative', width: 160}}>
                                <div style={{position: 'absolute', right: 0, zIndex: 1}}>
                                    <MainButton image={require("../../../imgs/actions/remove.png")}
                                                action={() => handleDeleteChat(chat)}
                                                imageWidth={15}
                                                imageHeight={15}/>
                                </div>
                                <MainButton text={chat.body.client.name}
                                            action={() => handleToggleChat(chat)}
                                            showShadow={true}
                                            backgroundColor={openThisChat ? premiumPalette.primaryColorFourthAlternative : premiumPalette.primaryColor}/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const pageStyle = {
    container: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row',
        bottom: 0,
        left: 0
    },
    chatFooterContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer'
    },
    actionOnChatStyle: {
        width: 25,
        height: 25,
        cursor: 'pointer'
    }
}

export default ChatFooter
